/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { db, functions } from 'config/firebase'
import {
  doc,
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
  updateDoc,
  addDoc,
  getDocs,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
export default function useStaff(id, branchId, isSchedule = false) {
  const createAccount = httpsCallable(functions, 'createAccount')
  const updateAccount = httpsCallable(functions, 'updateAccount')
  const deleteAccount = httpsCallable(functions, 'deleteAccount')
  const disableAccount = httpsCallable(functions, 'disableAccount')

  const [admins, setAdmin] = useState([])
  const [staffs, setStaff] = useState([])
  const [staff, setStaffData] = useState(null)

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const queryStaff = query(
      collection(db, 'Profiles'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryStaff, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        if (
          doc.data().role === 'Owner' ||
          doc.data().role === 'Manager' ||
          doc.data().role === 'CoAdmin'
        ) {
          data.push({ ...doc.data(), id: doc.id })
        }
      })
      setAdmin(data)

      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    let queryStaff
    if (branchId) {
      queryStaff = query(
        collection(db, 'Profiles'),
        where('role', '==', 'Staff'),
        where('branchId', '==', branchId),
        orderBy('createdAt', 'desc')
      )
    } else {
      queryStaff = query(
        collection(db, 'Profiles'),
        where('role', '==', 'Staff'),
        orderBy('createdAt', 'desc')
      )
    }

    const unsubscribe = onSnapshot(queryStaff, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      if (isSchedule) {
        const ot = data
          .filter((s) => s.position === 'Occupational therapy (OT)')
          .sort(
            (a, b) =>
              new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate())
          )
          .sort((a, b) => a.type.localeCompare(b.type))
        const st = data
          .filter((s) => s.position === 'Speech therapy (ST)')
          .sort(
            (a, b) =>
              new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate())
          )
          .sort((a, b) => a.type.localeCompare(b.type))
        const se = data
          .filter((s) => s.position === 'Special education (SE)')
          .sort(
            (a, b) =>
              new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate())
          )
          .sort((a, b) => a.type.localeCompare(b.type))

        setStaff([...ot, ...se, ...st].filter((s) => !s.disabled))
      } else {
        setStaff(data)
      }

      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [branchId])

  useEffect(() => {
    if (id) {
      setLoading(true)

      const queryStaff = query(doc(db, 'Profiles', id))
      const unsubscribe = onSnapshot(queryStaff, (snapShot) => {
        setStaffData({
          ...snapShot.data(),
          id: snapShot.id,
        })
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  const addStaff = (data) => {
    return addDoc(collection(db, 'Profiles'), data)
  }

  const updateStaff = (id, data) => {
    return updateDoc(doc(db, 'Profiles', id), data)
  }

  const deleteStaff = (id) => {
    return deleteAccount({ uid: id })
    // return deleteDoc(doc(db, 'Profiles', id))
  }

  const getStaffByType = async (type, day) => {
    if (type === 'Evaluation' || type === 'Camp' || type === 'Extra program') {
      if (day) {
        return staffs.filter(
          (s) => s.workingDay.includes(day) || s.workingDayWeekend.includes(day)
        )
      } else {
        return staffs
      }
    } else {
      const queryDoc = query(
        collection(db, 'Profiles'),
        where('role', '==', 'Staff'),
        where('position', '==', type),
        orderBy('createdAt', 'desc')
      )
      const docSnap = await getDocs(queryDoc)
      const newstaffs = docSnap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })

      if (day) {
        return newstaffs.filter(
          (s) => s.workingDay.includes(day) || s.workingDayWeekend.includes(day)
        )
      } else {
        return newstaffs
      }
    }
  }

  return {
    admins,
    staffs,
    isLoading,
    setLoading,
    updateStaff,
    staff,
    createAccount,
    updateAccount,
    getStaffByType,
    deleteStaff,
    addStaff,
    disableAccount,
  }
}
