import { Box, Flex, IconButton } from '@chakra-ui/react'

import { FaRegFilePowerpoint } from 'react-icons/fa'
import { BiUser } from 'react-icons/bi'
import {
  AiOutlineSchedule,
  AiOutlineCreditCard,
  AiFillSetting,
  AiOutlineClose,
  AiOutlineMenu,
} from 'react-icons/ai'

import NavLink from './NavLink'
export default function Sidebar({ onClose, user, ...rest }) {
  const role = user?.role
  const LinkItems = [
    { label: 'Kids', icon: BiUser, href: '/kids' },
    { label: 'Schedule', icon: AiOutlineSchedule, href: '/schedule' },
    {
      label: 'Report',
      icon: FaRegFilePowerpoint,
      href: '/report',
      isHidden: role === 'Staff' || role === 'CoAdmin',
    },
    {
      label: 'Payment',
      icon: AiOutlineCreditCard,
      href: '/payments',
      isHidden: role === 'Staff' || role === 'CoAdmin',
    },
    {
      label: 'Settings',
      icon: AiFillSetting,
      href: '/settings',
      isHidden: role === 'Staff' || role === 'CoAdmin',
    },
    {
      label: 'Event Logs',
      icon: AiOutlineMenu,
      href: '/eventlogs',
      isHidden: role === 'Staff' || role === 'CoAdmin' || role === 'Manager',
    },
  ]
  return (
    <Box position='fixed' minH='100%'>
      <Box
        bg='brand'
        boxShadow='4px 4px 31px rgba(0, 0, 0, 0.1)'
        w={{ base: 'full', md: '100px' }}
        pos='fixed'
        h='full'
        pt={{ base: 0, md: '100px' }}
        {...rest}
      >
        <Flex justifyContent='flex-end' overflowY='auto'>
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onClose}
            colorScheme='brand'
            size='lg'
            aria-label='open menu'
            icon={<AiOutlineClose />}
          />
        </Flex>
        {LinkItems.map(
          (link, i) =>
            role &&
            !link?.isHidden && (
              <NavLink key={i} link={link} onClose={onClose} role={role} />
            )
        )}
      </Box>
    </Box>
  )
}
