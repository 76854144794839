import { Text, SimpleGrid, Flex, Icon } from '@chakra-ui/react'
import { NavLink as ReactNavLink } from 'react-router-dom'

import { FaBookReader } from 'react-icons/fa'
import { BiStore } from 'react-icons/bi'
import { RiUserSettingsFill } from 'react-icons/ri'
import { AiFillFileText } from 'react-icons/ai'
import { MdNotifications } from 'react-icons/md'

const LinkItems = [
  {
    label: 'Staff Setting',
    icon: FaBookReader,
    href: '/settings/staff',
    allowed: ['Manager', 'Owner'],
  },
  {
    label: 'Manager Setting',
    icon: RiUserSettingsFill,
    href: '/settings/manager',
    allowed: ['Owner'],
  },
  {
    label: 'Course Setting',
    icon: AiFillFileText,
    href: '/settings/course',
    allowed: ['Owner'],
  },
  {
    label: 'Branch Setting',
    icon: BiStore,
    href: '/settings/branch',
    allowed: ['Owner'],
  },
  {
    label: 'Notification Setting',
    icon: MdNotifications,
    href: '/settings/notification',
    allowed: ['Owner'],
  },
]

export default function Settings({ user }) {
  return (
    <SimpleGrid columns={[1, 1, 1, 2]} spacing='20px' p={{ base: 5, lg: 20 }}>
      {LinkItems.map(
        (link, i) =>
          link.allowed.includes(user?.role) && (
            <ReactNavLink to={link?.href} key={i}>
              <Flex
                flexDirection='column'
                align='center'
                justifyContent='center'
                p='2'
                m='1'
                h='200px'
                borderRadius='15px'
                role='group'
                cursor='pointer'
                _hover={{
                  bg: 'rgba(255, 255, 255, 0.5)',
                }}
                color='white'
                borderWidth='2px'
                borderColor='brand'
              >
                {link?.icon && (
                  <Icon fontSize='60' color='brand' as={link?.icon} />
                )}
                <Text fontSize='24px' color='#767262' textAlign={'center'}>
                  {link?.label}
                </Text>
              </Flex>
            </ReactNavLink>
          )
      )}
    </SimpleGrid>
  )
}
