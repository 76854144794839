/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table as ChakraTable,
  Tbody,
  Td,
  Text,
  Tr,
  Thead,
  Th,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Heading,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { search } from 'ss-search'
import moment from 'moment/moment'
import Table from 'components/Horizons/table'
import useCourse from 'hooks/useCourse'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'config/firebase'
import { FaFileExport } from 'react-icons/fa'
import axios from 'axios'
import { useQuery } from 'react-query'
import { DatePicker } from 'antd'

const createFileExcelReportClears = httpsCallable(
  functions,
  'createReportClears'
)

const getReportClear = httpsCallable(functions, 'getReportClear')

function ModalItems({ onClose, isOpen, data }) {
  const { kid, programs } = data
  return (
    <Modal
      size='4xl'
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent w='full' mx={4}>
        <ModalHeader fontWeight='normal'></ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={{ base: '5px', sm: '5px', md: '15px' }}
          maxH='80vh'
          overflowY='auto'
        >
          <Heading size='lg' textAlign='center'>{`${kid?.name} ${
            kid?.nickname ? `(${kid?.nickname})` : ''
          }`}</Heading>
          <Heading size='lg' textAlign='center'>{`${kid?.code}`}</Heading>
          <Heading px={5} size='md' my={5}>
            รายการใช้งาน
          </Heading>
          <Center
            p='0.5rem'
            w='full'
            border='1px solid #E2E8F0'
            overflowX='auto'
            my={5}
          >
            <ChakraTable pt='0px' mt='0px'>
              <Thead bg='#E0E1E2'>
                <Tr>
                  <Th textAlign='start' color='black'>
                    <Text>โปรแกรม</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>ใช้แล้ว</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>จำนวนที่เคลียร์ (ครั้ง)</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>เคลียร์โดย</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>วันที่เคลียร์</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>เหตุผล</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {programs.map((p, i) => {
                  return (
                    <Tr key={i}>
                      <Td textAlign='start'>
                        <Text>{`${p.courseName} ${p.choice} (${p.amount} ครั้ง)`}</Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>{`${p?.amount - p?.leftAmount} / ${
                          p?.amount
                        }`}</Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>
                          {p?.clearAmount ? p?.clearAmount : p?.leftAmount}
                        </Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>{p?.createdBy?.nickname ?? 'System'}</Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>{moment(p?.clearAt).format('DD/MM/YYYY')}</Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>{p?.note}</Text>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </ChakraTable>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function kidStatus(status) {
  if (status === 'member') {
    return (
      <Box fontWeight='semibold' color='brand'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'evaluation') {
    return (
      <Box fontWeight='semibold' color='#FFC211'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'off') {
    return (
      <Box fontWeight='semibold' color='#767262'>
        {status.toUpperCase()}
      </Box>
    )
  }
}

export function Clear({ branches, managerBranchId, role }) {
  const { courses } = useCourse()
  const columnsData = [
    {
      Header: 'KID CODE',
      accessor: 'kid.code',
    },
    {
      Header: 'Status',
      accessor: 'kid.status',
      extra: (data, row) => (
        <HStack>
          <Box>{kidStatus(data)}</Box>
        </HStack>
      ),
    },
    {
      Header: 'ชื่อ-นามสกุล',
      accessor: 'kid.name',
    },
    {
      Header: 'ชื่อเล่น',
      accessor: 'kid.nickname',
    },
    {
      Header: 'จำนวนโปรแกรม',
      accessor: 'programs',
      extra: (data) => data?.length,
    },

    {
      Header: 'Action',
      accessor: 'kid',
      width: '10%',
      extra: (data, row) => (
        <Button
          variant='link'
          textDecoration='underline'
          onClick={() => {
            setModalData(row)
            onOpen()
          }}
        >
          รายละเอียด
        </Button>
      ),
    },
  ]
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || '',
    type: '',
    month: moment().format('YYYY-MM'),
  })
  const [modalData, setModalData] = useState()
  const [downloadIsLoading, setDownloadIsLoading] = useState(false)
  const { onOpen, isOpen, onClose } = useDisclosure()

  const [dataQuery, setDataQuery] = useState([])

  const { data: reportClear, isFetching } = useQuery(
    ['clearReport', searchQuery?.branch, searchQuery?.month],
    async () => {
      const response = await getReportClear({
        branchId: searchQuery?.branch,
        month: searchQuery?.month,
      })
      if (!response.data) {
        throw new Error('Network response was not ok')
      }
      return response.data
    },
    { refetchOnWindowFocus: false }
  )

  async function handleDownloadFile(dataDownload) {
    setDownloadIsLoading(true)
    await createFileExcelReportClears({
      data: JSON.stringify(dataDownload),
    })
      .then(async (i) => {
        const url = i.data.fileUrl
        const fileName = i.data.fileName
        await axios({
          url,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}`)
            document.body.appendChild(link)
            link.click()
          })
          .catch((err) => {
            console.error(err)
            setDownloadIsLoading(false)
          })
        setDownloadIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setDownloadIsLoading(false)
      })
  }

  useEffect(() => {
    if (reportClear) {
      setDataQuery(reportClear)
    }
  }, [reportClear])

  function handleSearch(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, text: e.target.value })
    let textSearch = e.target.value
    const searchQueryArr = reportClear
    const searchKeys = ['kid.name', 'kid.nickname', 'kid.code']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      searchQuery.type !== ''
        ? searchResults.filter((s) => {
            return s.programs.some((p) => p.courseId === searchQuery.type)
          })
        : searchResults
    setDataQuery(filteredResults)
  }

  function handleSelectType(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, type: e.target.value })
    let textSearch = searchQuery.text
    const searchQueryArr = reportClear
    const searchKeys = ['kid.name', 'kid.nickname', 'kid.code']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      e.target.value !== ''
        ? searchResults.filter((s) => {
            return s.programs.some((p) => p.courseId === e.target.value)
          })
        : searchResults
    setDataQuery(filteredResults)
  }

  return (
    <Box>
      {isOpen && (
        <ModalItems isOpen={isOpen} onClose={onClose} data={modalData} />
      )}
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={5}
          p={5}
        >
          <HStack
            // columns={{ base: 1, md: 4 }}
            spacing={{ base: 0, md: '20px' }}
            flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          >
            <FormControl>
              <FormLabel>Search Name</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) => handleSearch(e)}
                value={searchQuery.text}
                placeholder='Search Name'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Pleas Select branch'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {/* {role === 'Owner' && <option value='all'>All Branches</option>} */}
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Course</FormLabel>

              <Select
                id='course'
                placeholder='ทั้งหมด'
                onChange={(e) => handleSelectType(e)}
                value={searchQuery.type}
              >
                {courses.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Month</FormLabel>
              <DatePicker
                allowClear={false}
                picker='month'
                format={'MMMM YYYY'}
                defaultValue={moment(searchQuery.month)}
                onChange={(date) =>
                  setSearch({
                    ...searchQuery,
                    type: '',
                    month: moment(date).format('YYYY-MM'),
                  })
                }
              />
            </FormControl>
            <HStack alignSelf={'end'}>
              <IconButton
                variant='outline'
                fontSize={'23px'}
                icon={<FaFileExport color='#8CC63E' />}
                onClick={() => {
                  handleDownloadFile(dataQuery)
                }}
                disabled={downloadIsLoading || !dataQuery.length > 0}
                isLoading={downloadIsLoading}
              />
            </HStack>
          </HStack>
        </Box>
      </form>
      {searchQuery.branch !== '' ? (
        <Table
          columnsData={columnsData}
          tableData={dataQuery}
          noSearch
          loading={isFetching}
        />
      ) : (
        <Center>
          <Heading>Please Select Branch</Heading>
        </Center>
      )}
    </Box>
  )
}
