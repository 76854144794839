/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from '@chakra-ui/icons'
import {
  Button,
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SkeletonText,
  useColorModeValue,
} from '@chakra-ui/react'
import { Fragment, useEffect, useMemo } from 'react'
import { MdChevronRight, MdChevronLeft } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props
  // Chakra Color Mode
  const searchIconColor = useColorModeValue('gray.700', 'white')
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900')
  const inputText = useColorModeValue('gray.700', 'gray.100')
  return (
    <InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
      <InputLeftElement
        children={
          <IconButton
            bg='inherit'
            borderRadius='inherit'
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' />}
          ></IconButton>
        }
      />
      <Input
        variant='search'
        fontSize='sm'
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: 'gray.400', fontSize: '14px' }}
        borderRadius={borderRadius ? borderRadius : '30px'}
        placeholder={placeholder ? placeholder : 'Search...'}
      />
    </InputGroup>
  )
}

function HorizonTable({
  columnsData,
  tableData,
  loading,
  noSearch,
  onRowClick,
  useCurrentPage,
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
    setPageSize,
  } = tableInstance

  useEffect(() => {
    if (searchParams.get('page')) {
      const currentPage = Number(searchParams.get('page')) - 1
      useCurrentPage && gotoPage(currentPage)
    }
  }, [searchParams, state])

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages)
  }

  const createPages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, 5, total]
      }
    }
  }

  const { pageIndex, pageSize } = state
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')
  const brandColor = useColorModeValue('brand', 'brand')
  const searchQuery = useMemo(() => {
    const search = {}
    for (const [key, value] of searchParams.entries()) {
      Object.assign(search, { [key]: value })
    }

    return search
  }, [searchParams])

  return (
    <Flex
      direction='column'
      w='100%'
      overflowX={{ sm: 'scroll', xl: 'hidden' }}
    >
      {!noSearch && (
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w='100%'
          mb='36px'
        >
          <SearchBar
            onChange={(e) => setGlobalFilter(e.target.value)}
            h='44px'
            w={{ base: 'full', lg: '390px' }}
            borderRadius='16px'
            mr={'10px'}
          />
        </Flex>
      )}
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead bg='#fafafa'>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth,
                  }}
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {loading ? (
            <Tr>
              {columns.map((cell, i) => (
                <Td
                  key={i}
                  fontSize={{ sm: '12px' }}
                  minW={{ sm: '75px', md: '200px', lg: 'auto' }}
                  borderColor={borderColor}
                >
                  <SkeletonText noOfLines={10} spacing={6} />
                </Td>
              ))}
            </Tr>
          ) : (
            page.map((row, index) => {
              prepareRow(row)
              return (
                <Tr
                  {...row.getRowProps()}
                  onClick={() =>
                    onRowClick
                      ? onRowClick(
                          tableData[row.getRowProps().key.split('_')[1]]?.id
                        )
                      : null
                  }
                  cursor={onRowClick ? 'pointer' : 'default'}
                >
                  {row.cells.map((cell, i) => {
                    let data = ''
                    if (cell.column?.extra) {
                      data = (
                        <Box fontSize='sm' textColor={'black'}>
                          {cell.column?.extra(
                            cell.value,
                            tableData[row.getRowProps().key.split('_')[1]]
                          )}
                        </Box>
                      )
                    } else {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='500'>
                          {cell.value}
                        </Text>
                      )
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={i}
                        fontSize={{ sm: '12px' }}
                        minW={{ sm: '75px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}
                      >
                        {data}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })
          )}
        </Tbody>
      </Table>
      <Flex
        direction={{ sm: 'row', md: 'row' }}
        justify='space-between'
        align={{ sm: 'start', md: 'center' }}
        w='100%'
        px={{ md: '22px' }}
      >
        <Flex align='center'>
          <Text
            me='10px'
            minW='max-content'
            fontSize='sm'
            color='gray.500'
            fontWeight='normal'
          >
            Show rows per page
          </Text>
          <Select
            textColor={'gray.500'}
            fontSize='sm'
            variant='main'
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </Select>
        </Flex>
        <Stack
          direction='row'
          alignSelf={{ base: 'unset', md: 'flex-end' }}
          spacing='4px'
          ms={{ base: '0px', md: 'auto' }}
        >
          <Button
            variant='no-effects'
            onClick={() => {
              previousPage()
              useCurrentPage &&
                setSearchParams(
                  Object.assign(searchQuery, { page: state.pageIndex })
                )
            }}
            transition='all .5s ease'
            w='40px'
            h='40px'
            borderRadius='50%'
            bg='transparent'
            border='1px solid'
            borderColor={useColorModeValue('gray.200', 'white')}
            display='flex'
            _hover={{
              _disabled: {
                cursor: 'pointer',
              },
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
            isDisabled={!canPreviousPage}
          >
            <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
          </Button>
          {pageSize === 0 ? (
            <NumberInput
              max={pageCount - 1}
              min={1}
              w='75px'
              mx='6px'
              defaultValue='1'
              onChange={(e) => {
                gotoPage(e)
                useCurrentPage &&
                  setSearchParams(
                    Object.assign(searchQuery, {
                      page: e + 1,
                    })
                  )
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper
                  onClick={() => {
                    nextPage()
                    useCurrentPage &&
                      setSearchParams(
                        Object.assign(searchQuery, {
                          page: state.pageIndex + 2,
                        })
                      )
                  }}
                />
                <NumberDecrementStepper
                  onClick={() => {
                    previousPage()
                    useCurrentPage &&
                      setSearchParams(
                        Object.assign(searchQuery, {
                          page: state.pageIndex,
                        })
                      )
                  }}
                />
              </NumberInputStepper>
            </NumberInput>
          ) : (
            createPages(pageIndex + 1, pageCount).map(
              (pageNumber, index, array) => {
                return (
                  <Fragment key={index}>
                    {array[index - 1] + 2 < pageNumber && (
                      <Button
                        variant='no-effects'
                        transition='all .5s ease'
                        onClick={() => {
                          gotoPage(pageNumber - 1)
                          useCurrentPage &&
                            setSearchParams(
                              Object.assign(searchQuery, {
                                page: pageNumber,
                              })
                            )
                        }}
                        w='40px'
                        h='40px'
                        borderRadius='50%'
                        bg={
                          pageNumber === pageIndex + 1
                            ? brandColor
                            : 'transparent'
                        }
                        border={
                          pageNumber === pageIndex + 1
                            ? 'none'
                            : '1px solid lightgray'
                        }
                        _hover={
                          pageNumber === pageIndex + 1
                            ? {
                                opacity: '0.7',
                              }
                            : {
                                bg: 'whiteAlpha.100',
                              }
                        }
                        key={pageNumber + '...'}
                      >
                        <Text
                          fontSize='sm'
                          color={
                            pageNumber === pageIndex + 1 ? '#fff' : textColor
                          }
                        >
                          ...
                        </Text>
                      </Button>
                    )}
                    <Button
                      variant='no-effects'
                      transition='all .5s ease'
                      onClick={() => {
                        gotoPage(pageNumber - 1)
                        useCurrentPage &&
                          setSearchParams(
                            Object.assign(searchQuery, {
                              page: pageNumber,
                            })
                          )
                      }}
                      w='40px'
                      h='40px'
                      borderRadius='50%'
                      bg={
                        pageNumber === pageIndex + 1
                          ? brandColor
                          : 'transparent'
                      }
                      border={
                        pageNumber === pageIndex + 1
                          ? 'none'
                          : '1px solid lightgray'
                      }
                      _hover={
                        pageNumber === pageIndex + 1
                          ? {
                              opacity: '0.7',
                            }
                          : {
                              bg: 'whiteAlpha.100',
                            }
                      }
                      key={index}
                    >
                      <Text
                        fontSize='sm'
                        color={
                          pageNumber === pageIndex + 1 ? '#fff' : textColor
                        }
                      >
                        {pageNumber}
                      </Text>
                    </Button>
                  </Fragment>
                )
              }
            )
          )}
          <Button
            variant='no-effects'
            onClick={() => {
              nextPage()
              useCurrentPage &&
                setSearchParams(
                  Object.assign(searchQuery, {
                    page: state.pageIndex + 2,
                  })
                )
            }}
            transition='all .5s ease'
            w='40px'
            h='40px'
            borderRadius='50%'
            bg='transparent'
            border='1px solid'
            borderColor={useColorModeValue('gray.200', 'white')}
            _hover={{
              _disabled: {
                cursor: 'pointer',
              },
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
            isDisabled={!canNextPage}
          >
            <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
          </Button>
        </Stack>
      </Flex>
    </Flex>
  )
}

export default HorizonTable
