/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { db } from 'config/firebase'
import moment from 'moment'

const CheckComplete = ({
  programId,
  amount,
  refresh,
  date,
  startTime,
  isTaked,
}) => {
  const [sum, setSum] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const startTimeStr = `${moment(startTime).format('HH')}.${moment(
    startTime
  ).format('mm')}`

  const fetchCompleted = async () => {
    setIsLoading(true)
    const completedQuery = query(
      collection(db, 'Schedules'),
      where('programId', '==', programId)
      // where('isTaked', '==', true)
    )
    const count = await (
      await getDocs(completedQuery)
    ).docs.map((doc) => doc.data())
    const index = count
      ?.filter((p) => !p.cancelLog)
      .filter((p) => p.isTaked)
      .sort((a, b) => a.timeStart.localeCompare(b.timeStart))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .findIndex((d) => {
        return d.date === date && d.timeStart === startTimeStr
      })

    if (index !== -1) {
      setSum(index + 1)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (programId && !refresh) {
      !isLoading && fetchCompleted()
    }
  }, [programId, amount, refresh, isTaked])

  return (
    <>
      {!isLoading && isTaked && sum !== 0 ? `(${sum} / ${amount})` : ''}{' '}
      {sum / amount >= 0.7 && amount > 1 ? (
        <span style={{ color: 'red', marginLeft: 2 }}>●</span>
      ) : (
        ``
      )}
    </>
  )
}

export default CheckComplete
