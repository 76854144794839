/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table as ChakraTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Heading,
  HStack,
  IconButton,
  Checkbox,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import { search } from 'ss-search'
import moment from 'moment/moment'
import axios from 'axios'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'config/firebase'
import Table from 'components/Horizons/table'
import { FaFileExport } from 'react-icons/fa'
import { useQuery } from 'react-query'

const createFileExcelReportCamp = httpsCallable(functions, 'createReportCamp')
const getStaffCampPerformance = httpsCallable(
  functions,
  'getStaffCampPerformance'
)

function ModalItems({ onClose, isOpen, data }) {
  const { staff, reportByDate } = data
  return (
    <Modal
      size='auto'
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent w='auto'>
        <ModalHeader fontWeight='normal'>{`${staff?.nickname} / ${staff?.branch} / ${staff?.position}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          w={{ base: '21rem', sm: '21rem', md: '50rem' }}
          p={{ base: '5px', sm: '5px', md: '15px' }}
          maxH='80vh'
          overflowY='auto'
        >
          <Center
            p='0.5rem'
            w='full'
            border='1px solid #E2E8F0'
            overflowX='auto'
          >
            <ChakraTable pt='0px' mt='0px'>
              <Thead bg='#E0E1E2'>
                <Tr>
                  <Th textAlign='start' color='black'>
                    <Text>วันที่</Text>
                  </Th>
                  <Th textAlign='center' color='black'>
                    <Text>Camp</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {reportByDate
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((data) => {
                    return (
                      <Tr key={data.date}>
                        <Td textAlign='start'>
                          <Text>{moment(data.date).format('DD/MM/YYYY')}</Text>
                        </Td>
                        <Td textAlign='center'>
                          <Text>{!isNaN(data.camp) && data.camp}</Text>
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </ChakraTable>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export function CampData({ branches, managerBranchId }) {
  const [searchQuery, setSearch] = useState({
    text: '',
    position: '',
    branch: managerBranchId || '',
    month: moment().format('YYYY-MM'),
  })
  const [modalData, setModalData] = useState()
  const [downloadIsLoading, setDownloadIsLoading] = useState(false)
  const { onOpen, isOpen, onClose } = useDisclosure()
  const { data: report, isLoading } = useQuery(
    ['campReport', searchQuery?.month, searchQuery?.branch],
    async () => {
      const response = await getStaffCampPerformance({
        branchId: searchQuery?.branch,
        month: searchQuery?.month,
      })
      if (!response.data) {
        throw new Error('Network response was not ok')
      }
      return response.data
    },
    { refetchOnWindowFocus: false }
  )
  const [dataQuery, setDataQuery] = useState([])
  const [isChecked, setIsChecked] = useState(false)

  const multiplier = useMemo(() => {
    const findBranch = branches.find((b) => b.id === searchQuery?.branch)
    if (findBranch?.province === 'bk') {
      return 200
    } else if (findBranch?.province === 'cm') {
      return 150
    }
  }, [branches, searchQuery?.branch])

  function sortResultsByPosition(data) {
    const ot = data
      .filter((r) => r.staff.position === 'Occupational therapy (OT)')
      .sort((a, b) => a.staff.type.localeCompare(b.staff.type))

    const se = data
      .filter((r) => r.staff.position === 'Special education (SE)')
      .sort((a, b) => a.staff.type.localeCompare(b.staff.type))

    const st = data
      .filter((r) => r.staff.position === 'Speech therapy (ST)')
      .sort((a, b) => a.staff.type.localeCompare(b.staff.type))

    return [...ot, ...se, ...st]
  }

  useEffect(() => {
    if (report) {
      const parseData = JSON.parse(report)
      setDataQuery(sortResultsByPosition(parseData))
    }
  }, [report])

  useEffect(() => {
    setSearch({
      ...searchQuery,
      month: isChecked
        ? [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        : moment().format('YYYY-MM'),
    })
  }, [isChecked])

  async function handleDownloadFile(dataDownload) {
    setDownloadIsLoading(true)
    createFileExcelReportCamp({
      multiplier,
      data: JSON.stringify(dataDownload),
    }).then(async (i) => {
      const url = i.data.fileUrl
      const fileName = i.data.fileName
      await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${fileName}`)
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          console.error(err)
          setDownloadIsLoading(false)
        })
      setDownloadIsLoading(false)
    })
  }

  function handleSearch(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, text: e.target.value })
    let textSearch = e.target.value
    const searchQueryArr = JSON.parse(report)

    const searchKeys = ['staff.name', 'staff.nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      searchQuery?.position && searchQuery?.position !== ''
        ? searchResults.filter((s) => {
            return s.staff.position === searchQuery.position
          })
        : searchResults
    setDataQuery(sortResultsByPosition(filteredResults))
  }

  function handleSelectPosition(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, position: e.target.value })
    let textSearch = searchQuery.text
    const searchQueryArr = JSON.parse(report)
    const searchKeys = ['staff.name', 'staff.nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      e.target.value !== ''
        ? searchResults.filter((s) => {
            return s.staff.position === e.target.value
          })
        : searchResults
    setDataQuery(sortResultsByPosition(filteredResults))
  }

  const columnsData = [
    {
      Header: 'ชื่อ',
      accessor: 'staff.nickname',
    },
    {
      Header: 'สาขา',
      accessor: 'staff.branch',
      width: '20%',
    },
    {
      Header: 'วิชาชีพ',
      accessor: 'staff.position',
      extra: (data) => {
        if (data === 'Occupational therapy (OT)') return '(OT)'
        else if (data === 'Special education (SE)') return '(SE)'
        else if (data === 'Speech therapy (ST)') return '(ST)'
      },
    },
    {
      Header: 'Type',
      accessor: 'staff.type',
    },
    {
      Header: 'Camp',
      accessor: 'report',

      extra: (data, row) => (
        <Button
          variant='link'
          onClick={() => {
            setModalData(row)
            onOpen()
          }}
        >{`${data.camp}`}</Button>
      ),
    },
    {
      Header: `Over time * ${multiplier}`,
      accessor: 'report.camp',

      extra: (data, row) => data * multiplier,
    },
  ]

  return (
    <Box>
      {isOpen && (
        <ModalItems isOpen={isOpen} onClose={onClose} data={modalData} />
      )}
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={5}
          p={5}
        >
          <HStack
            spacing={{ base: 0, md: '20px' }}
            flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          >
            <FormControl>
              <FormLabel>Search Name</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) => handleSearch(e)}
                value={searchQuery.text}
                placeholder='Search Name'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Position</FormLabel>
              <Select
                id='branch'
                placeholder='All'
                onChange={(e) => handleSelectPosition(e)}
                value={searchQuery.position}
                isDisabled={managerBranchId}
              >
                {[
                  'Occupational therapy (OT)',
                  'Special education (SE)',
                  'Speech therapy (ST)',
                ].map((position) => (
                  <option key={position} value={position}>
                    {position}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Pleas Select branch'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <HStack w='full' justifyContent='space-between'>
                <FormLabel>{isChecked ? 'Date' : 'Month'}</FormLabel>
                <Checkbox
                  colorScheme='lime'
                  onChange={(e) => {
                    setIsChecked(e.target.checked)
                    if (!e.target.checked) {
                      setSearch({
                        ...searchQuery,
                        date: [
                          moment().format('YYYY-MM-DD'),
                          moment().format('YYYY-MM-DD'),
                        ],
                      })
                    }
                  }}
                >
                  เลือกช่วงเวลา
                </Checkbox>
              </HStack>
              {isChecked ? (
                <DatePicker.RangePicker
                  allowClear={false}
                  format={'DD MMMM YYYY'}
                  value={[
                    moment(searchQuery?.month[0]),
                    moment(searchQuery?.month[1]),
                  ]}
                  onChange={(date) => {
                    setSearch({
                      ...searchQuery,
                      month: [
                        moment(date[0]).format('YYYY-MM-DD'),
                        moment(date[1]).format('YYYY-MM-DD'),
                      ],
                      text: '',
                    })
                  }}
                />
              ) : (
                <DatePicker
                  allowClear={false}
                  picker='month'
                  format={'MMMM YYYY'}
                  value={moment(searchQuery.month)}
                  onChange={(date) =>
                    setSearch({
                      ...searchQuery,
                      month: moment(date).format('YYYY-MM'),
                      text: '',
                    })
                  }
                />
              )}
            </FormControl>
            <HStack alignSelf={'end'}>
              <IconButton
                variant='outline'
                fontSize={'23px'}
                icon={<FaFileExport color='#8CC63E' />}
                onClick={() => {
                  handleDownloadFile(dataQuery)
                }}
                disabled={downloadIsLoading || !dataQuery.length > 0}
                isLoading={downloadIsLoading}
              />
            </HStack>
          </HStack>
        </Box>
      </form>
      {searchQuery.branch !== '' ? (
        <Table
          columnsData={columnsData}
          tableData={dataQuery}
          noSearch
          loading={isLoading}
        />
      ) : (
        <Center>
          <Heading>Please Select Branch</Heading>
        </Center>
      )}
    </Box>
  )
}
