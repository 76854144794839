/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { db } from 'config/firebase'
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  deleteDoc,
  doc,
} from 'firebase/firestore'

export default function useCourse(id) {
  const [courses, setCourse] = useState([])
  const [course, setCourseData] = useState([])

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const queryCourses = query(
      collection(db, 'Courses'),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(queryCourses, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id })
      })
      const datafilterActive = data.map((c) => {
        const filterPrograms = c.programs.filter((program) => program.isActive)
        return {
          ...c,
          programs: filterPrograms,
        }
      })
      setCourse(datafilterActive)
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (id) {
      setLoading(true)

      const queryCourse = query(doc(db, 'Courses', id))
      const unsubscribe = onSnapshot(queryCourse, (snapShot) => {
        setCourseData({
          ...snapShot.data(),
          id: snapShot.id,
        })

        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  const deleteCourse = async (id) => {
    return deleteDoc(doc(db, 'Courses', id))
  }

  return {
    course,
    courses,
    isLoading,
    setLoading,
    deleteCourse,
  }
}
