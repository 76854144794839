/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Checkbox,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { db } from 'config/firebase'
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore'
import moment from 'moment'
import useBranch from 'hooks/useBranch'

import FormInputs from 'components/DynamicForm'

const activeForm = [
  {
    label: `Member Active Date`,
    name: 'activeDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `Member Expire Date`,
    name: 'expireDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
    readonly: true,
  },
  {
    label: `Member Fee`,
    name: 'price',
    type: 'number-format',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
]

export default function ActiveModal({
  isOpen,
  onClose,
  data,
  kidId,
  branchId,
  user,
}) {
  const { branches } = useBranch()
  const [isWaive, setIsWaive] = useState(false)

  const [isLoading, setLoading] = useState(false)
  const [status] = useState(data?.status || 'member')
  const toast = useToast()
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    setValue,
    clearErrors,
  } = useForm()

  const activeDate = watch('activeDate')

  useEffect(() => {
    if (branchId && branchId !== '' && branches) {
      const selectBranch = branchId
        ? branches.find((branches) => branches?.id === branchId)
        : branches.find((branches) => branches?.id === data?.branchId)
      setValue('price', selectBranch?.memberPrice && selectBranch?.memberPrice)
    } else {
    }
  }, [branchId, branches])

  useEffect(() => {
    if (activeDate) {
      clearErrors('expireDate')
      setValue(
        'expireDate',
        moment(activeDate).add(1, 'years').format('YYYY-MM-DD')
      )
    }
  }, [activeDate])

  useEffect(() => {
    if (isWaive) {
      setValue('price', '0')
    } else {
      const selectBranch = branchId
        ? branches.find((branches) => branches?.id === branchId)
        : branches.find((branches) => branches?.id === data?.branchId)
      setValue('price', selectBranch?.memberPrice && selectBranch?.memberPrice)
    }
  }, [isWaive, branchId, branches])

  function submit(values) {
    if (data?.id) {
      update(values)
    } else {
      create(values)
    }
  }
  function create(values) {
    setLoading(true)
    addDoc(collection(db, `Kids/${kidId}/Status`), {
      ...values,
      activeDate: new Date(moment(values.activeDate, 'YYYY-MM-DD')),
      expireDate: new Date(moment(values.expireDate, 'YYYY-MM-DD')),
      createdAt: new Date(),
      status: status,
      isWaive,
    })
      .then((doc) =>
        addDoc(collection(db, `Kids/${kidId}/Status/${doc.id}/Logs`), {
          ...values,
          action: 'addNewActiveMember',
          kidId,
          createdAt: new Date(),
          createdBy: user?.uid,
          activeDate: new Date(moment(values.activeDate, 'YYYY-MM-DD')),
          expireDate: new Date(moment(values.expireDate, 'YYYY-MM-DD')),
          status: status,
          isWaive,
        })
      )
      .then(() =>
        updateDoc(doc(db, `Kids`, kidId), {
          status,
          memberDetails: {
            ...values,
            activeDate: new Date(moment(values.activeDate, 'YYYY-MM-DD')),
            expireDate: new Date(moment(values.expireDate, 'YYYY-MM-DD')),
            status,
            isWaive,
          },
        })
      )

      .then(() => {
        toast({
          position: 'top',
          Header: 'Add Kid Status',
          description: 'Kid Status has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          Header: 'Add Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function update(values) {
    setLoading(true)
    updateDoc(doc(db, `Kids/${kidId}/Status`, data.id), {
      ...values,
      status: status,
      updatedAt: new Date(),
    })
      .then(() => updateDoc(doc(db, `Kids`, kidId), { status: status }))
      .then(() => {
        toast({
          position: 'top',
          Header: 'Update Kid Status',
          description: 'Kid Status has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Kid's Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={activeForm || []}
            data={data}
          />
          <FormControl as='fieldset'>
            <FormLabel as='legend'>Member Waive</FormLabel>
            <Checkbox
              checked={isWaive}
              colorScheme='lime'
              onChange={(e) => setIsWaive(e.target.checked)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
