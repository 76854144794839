import { Center } from '@chakra-ui/react'
import { CircleSpinnerOverlay } from 'react-spinner-overlay'

const Spinner = ({ loading }) => (
  <Center h='100vh' position='absolute'>
    <CircleSpinnerOverlay
      size={60}
      outerBorderOpacity='0.3'
      outerBorderWidth='6'
      color='#8CC63E'
      loading={loading}
      overlayColor='rgb(0 0 0 / 50%)'
      zIndex={100000}
    />
  </Center>
)

export default Spinner
