/* eslint-disable react-hooks/exhaustive-deps */
import { db } from 'config/firebase'

import { useCollection } from '@lemasc/swr-firestore'
import { orderBy } from '@lemasc/swr-firestore/constraints'
import { deleteDoc, doc, getDoc } from 'firebase/firestore'

export default function useBranch() {
  const { data: BranchesList, isLoading } = useCollection(
    'Branches',
    {
      constraints: [orderBy('createdAt', 'desc')],
    },
    {
      listen: true,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: 0,
    }
  )

  const deleteBranch = async (id) => {
    return deleteDoc(doc(db, 'Branches', id))
  }

  const getBranch = async (id) => {
    const docSnap = await getDoc(doc(db, 'Branches', id))

    return { id: docSnap.id, ...docSnap.data() }
  }

  return {
    branches: BranchesList || [],
    isLoading,
    deleteBranch,
    getBranch,
  }
}
