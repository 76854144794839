/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  IconButton,
  useToast,
  Text,
  Switch,
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { Popconfirm } from 'antd'

import { BiArrowBack } from 'react-icons/bi'
import Table from 'components/Horizons/table'
import SpinnerOverlay from 'components/SpinnerOverlay'

import useStaff from 'hooks/useStaff'
import useBranch from 'hooks/useBranch'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import moment from 'moment'
export default function Staff({ user, role }) {
  const branchId = role === 'Manager' ? user?.branchId : null
  const { branches } = useBranch()
  const { staffs, isLoading, deleteStaff, disableAccount } = useStaff(
    null,
    branchId
  )
  const [submitting, setSubmitting] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()

  function handleDelete(id) {
    setSubmitting(true)
    deleteStaff(id)
      .then(() => {
        toast({
          position: 'top',
          Header: 'Delete Staff',
          description: 'Staff has been Deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
      .finally(() => setSubmitting(false))
  }

  function handleDisable(data) {
    setSubmitting(true)
    disableAccount(data)
      .then(() => {
        toast({
          position: 'top',
          Header: 'Delete Staff',
          description: 'Staff has been Disabled.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
      .finally(() => setSubmitting(false))
  }

  const columnsDataOrders = [
    {
      Header: 'Created Date',
      accessor: 'createdAt',
      key: 'createdAt',
      width: '15%',
      extra: (date) => moment(date.toDate()).format('DD/MM/YYYY'),
    },
    {
      Header: 'Email Address',
      accessor: 'email',
      key: 'name',
    },
    {
      Header: 'Full Name',
      accessor: 'fullname',
      key: 'name',
      width: '15%',
    },
    {
      Header: 'Nick Name',
      accessor: 'nickname',
      key: 'nickname',
      width: '10%',
    },
    {
      Header: 'Position',
      accessor: 'position',
      key: 'position',
      width: '20%',
    },
    {
      Header: 'Type',
      accessor: 'type',
      key: 'type',
      width: '15%',
    },
    {
      Header: 'Office Branch',
      accessor: 'branch',
      key: 'data',
      width: '15%',
      extra: (data, row) => {
        const branch = branches.find((b) => b.id === row.branchId)
        return (
          <Text color={'black'} fontSize='sm'>
            {`${branch ? branch?.name : data}`}
          </Text>
        )
      },
    },
    {
      Header: 'Active',
      accessor: 'disabled',
      width: '10%',
      key: 'Action',
      extra: (data, row) => {
        return (
          <HStack justifyContent='center'>
            <HStack spacing={2}>
              <Switch
                colorScheme='lime'
                size='lg'
                isChecked={!data}
                onChange={() => handleDisable(row)}
                value={data}
              />
            </HStack>
          </HStack>
        )
      },
    },
    {
      Header: 'Action',
      accessor: 'Action',
      width: '10%',
      key: 'Action',
      extra: (data, row) => {
        return (
          <HStack justifyContent='space-between'>
            <HStack spacing={2}>
              <Link to={`/settings/staff/edit/${row?.id}`}>
                <IconButton
                  variant='ghost'
                  aria-label='edit'
                  icon={<AiFillEdit color='#8CC63E' fontSize='25px' />}
                />
              </Link>
              <Popconfirm
                title='Sure to delete this Staff'
                onConfirm={() => {
                  handleDelete(row?.id)
                }}
              >
                <IconButton
                  variant='ghost'
                  aria-label='delete'
                  icon={<AiFillDelete color='red' fontSize='25px' />}
                />
              </Popconfirm>
            </HStack>
          </HStack>
        )
      },
    },
  ]

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <SpinnerOverlay loading={submitting} />
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings')}
        />
      </Flex>
      <VStack>
        <Heading>STAFF</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Link to={'/settings/staff/create'}>
            <Button variant='solid' colorScheme='orange' bg='#F6872B'>
              Create Staff
            </Button>
          </Link>
        </Flex>
      </VStack>
      <Table
        columnsData={columnsDataOrders}
        tableData={staffs}
        loading={isLoading}
      />
    </Box>
  )
}
