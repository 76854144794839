import { useState, useRef, useEffect, useMemo } from 'react'
import {
  Box,
  SimpleGrid,
  Text,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Center,
  useDisclosure,
  Button,
  HStack,
  Select,
  Flex,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { CgNotes } from 'react-icons/cg'
import moment from 'moment'
import Timetable from 'react-timetable-events'
import _ from 'underscore'
import { MdCancel } from 'react-icons/md'
import useSchedule from 'hooks/useSchedule'
import useStaff from 'hooks/useStaff'
import useCourse from 'hooks/useCourse'
import useBranch from 'hooks/useBranch'

import TakeCourseModal from './Modal/TakeCourseModal'
import NewKidModal from './Modal/NewKidModal'
import StaffModal from './Modal/StaffModal'

import CompleteBox from './components/CompleteBox'

export const TooltipWithTouch = ({ children, ...restToolTipProps }) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false)
  return (
    <Tooltip isOpen={isLabelOpen} {...restToolTipProps}>
      <Box
        p={0}
        m={0}
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(false)}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

export default function Schedule({ user, role }) {
  const [fullscreen, setFullScreen] = useState(false)
  const cancelRef = useRef()
  const scrollRef = useRef()

  const managerBranchId =
    role === 'Manager' || role === 'Staff' || role === 'CoAdmin'
      ? user?.branchId
      : null

  const { branches } = useBranch()
  const { courses } = useCourse()
  const { staffs } = useStaff(undefined, undefined, true)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenNewKid,
    onClose: onCloseNewKid,
    onOpen: onOpenNewKid,
  } = useDisclosure()
  const {
    isOpen: isOpenStaff,
    onClose: onCloseStaff,
    onOpen: onOpenStaff,
  } = useDisclosure()

  const [date, setDate] = useState(moment(new Date()).format('yyyy-MM-DD'))
  const isWeekend = moment(date).day() % 6 === 0
  const [isCamp, setIscamp] = useState(false)
  const [event, setEvent] = useState(false)
  const [showDayoff, setShowDayoff] = useState(false)
  const [sticky, setSticky] = useState(true)

  const [staffCoures, setStaffCoures] = useState([])

  const [branch, setBranch] = useState(managerBranchId)
  const { schedulesByDate, isLoading } = useSchedule({
    date,
    branchId: branch,
  })
  const [allSchedule, setAllSchedule] = useState(schedulesByDate)

  const staffCount = useMemo(() => {
    const count = allSchedule && Object.entries(allSchedule).length
    return count || 0
  }, [allSchedule])

  const branchProvince = useMemo(() => {
    const findBranch = branches.find((b) => b.id === branch)
    return findBranch ? findBranch?.province : 'all'
  }, [branches, branch])

  useEffect(() => {
    setSticky(false)
    if (!managerBranchId && branches.length > 0) {
      setBranch(branches[0].id || '')
    }
  }, [branches, managerBranchId])

  useEffect(() => {
    setSticky(false)

    if (showDayoff) {
      const filter = Object.entries(schedulesByDate)
        .filter(([_, obj]) => obj.every((e) => e?.event !== 'offday'))
        .reduce((r, a) => {
          const key = a[0]
          r[key] = r[key] || []
          r[key].push(...a[1])
          return r
        }, {})

      setAllSchedule(filter)
    } else {
      setAllSchedule(schedulesByDate)
    }
  }, [showDayoff, schedulesByDate])

  const handleClickSchedule = async (ev) => {
    const { staff } = ev
    if (isCamp) {
      setStaffCoures(
        courses
          .filter(
            (course) =>
              course.province === 'all' || course.province === branchProvince
          )
          .filter((course) => course.type === 'Camp')
          .map((course) => {
            const options = course.programs.map((program) => ({
              value: {
                courseId: course.id,
                courseName: course.name,
                coursDescription: course.description,
                hours: course.hours,
                score: course.score,
                type: course.type,
                ...program,
              },
              label: `${course.name} ${program.choice} (${program.amount} ${program.unit})`,
            }))

            return { ...course, options }
          })
      )
    } else {
      setStaffCoures(
        courses
          .filter(
            (course) =>
              course.province === 'all' || course.province === branchProvince
          )
          .filter(
            (course) =>
              course.type === staff?.position ||
              course.type === 'Extra program' ||
              course.type === 'Evaluation'
          )
          .map((course) => {
            const options = course.programs.map((program) => ({
              value: {
                courseId: course.id,
                courseName: course.name,
                coursDescription: course.description,
                hours: course.hours,
                score: course.score,
                type: course.type,
                ...program,
              },
              label: `${course.name} ${program.choice} (${program.amount} ${program.unit})`,
            }))

            return { ...course, options }
          })
      )
    }
    onOpen()
  }

  const StaffHeaderPreview = ({ day, rowHeight, ...otherProperties }) => {
    const staff = staffs.find((data) => data.id === day)
    const isAllDayOff = allSchedule[day].find(
      (schedule) => schedule.event === 'offday'
    )
    const skipDayOff = allSchedule[day].find((schedule) => {
      return (
        schedule.isSkipDayOff &&
        schedule?.staff?.id === day &&
        typeof schedule.id === 'string' &&
        !schedule?.event
      )
    })
    return (
      <Box
        key={day}
        style={{
          height: `${rowHeight}px`,
          zIndex: 3,
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 1,
        }}
        backgroundColor={`${staffPositionColor(staff?.position)} !important`}
        color='blackAlpha.900'
        onClick={() => {
          if (role !== 'Staff' && !isAllDayOff) {
            setEvent({
              staff,
              skipDayOff: skipDayOff ? true : false,
              scheduleId: skipDayOff?.id,
            })
            onOpenStaff()
          }
        }}
        {...otherProperties}
      >
        <Text fontSize={14}>{`${staff?.nickname || ''}`}</Text>
        <Text fontSize={14}>{`${staff?.position?.split(' ')[2] || ''}`} </Text>
        <Text
          fontSize={14}
          color={staff?.type === 'Part-time' ? 'orange.600' : 'lime.600'}
        >{`${staff?.type || ''}`}</Text>
      </Box>
    )
  }

  const HourPreview = ({ hour, ...otherProperties }) => {
    return (
      <Box
        key={hour}
        backgroundColor={'white'}
        borderWidth={0.1}
        color={'black'}
        display={'none'}
        flexDirection={'column'}
        justifyContent='space-evenly'
        {...otherProperties}
      >
        <Text>
          {moment(new Date().setHours(hour.replace(':', '.'), 0)).format(
            'HH:mm'
          )}
        </Text>
        <hr />
        <Text>
          {moment(new Date().setHours(hour.replace(':', '.'), 30)).format(
            'HH:mm'
          )}
        </Text>
      </Box>
    )
  }

  const EventPreview = ({ event, defaultAttributes, classNames }) => {
    return (
      <Box
        {...defaultAttributes}
        style={{
          ...defaultAttributes.style,
          cursor:
            // event?.event === 'offday' ||
            event?.event === 'disable' || event?.event === 'lunch'
              ? 'not-allowed'
              : 'pointer',
          backgroundColor:
            event?.event === 'offday' || event?.event === 'holiday'
              ? 'tomato'
              : event?.event === 'disable'
              ? '#e1e1e1'
              : event?.event === 'lunch'
              ? '#eeeeee'
              : event?.program?.color || 'transparent',
          // borderRadius: '5px',
          fontSize: '12px',
          borderWidth: 0.1,
          justifyContent: 'space-evenly',
          opacity:
            event?.event === 'offday' || event?.event === 'holiday' ? 0.75 : 1,
          zIndex:
            event?.event === 'offday' || event?.event === 'holiday' ? 1 : 0,
        }}
        title={event.name}
        key={event.id}
        onClick={() => {
          if (
            // event?.event !== 'offday'&&
            event?.event !== 'disable' &&
            event?.event !== 'lunch'
          ) {
            setEvent(event)
            handleClickSchedule(event)
          }
        }}
      >
        <TooltipWithTouch
          label={
            event?.kid?.length > 1
              ? event.kid?.map((k, i) => {
                  return (
                    <div key={i}>
                      <Text fontSize={16}>
                        {`${k.nickname} ${k.code ? ` (${k.code}) ` : ''}  `}
                        {event?.kidsCompleted[i] && (
                          <>
                            {' '}
                            <CompleteBox
                              programId={event?.programId[i]}
                              amount={
                                event?.campAmount
                                  ? event?.campAmount[i]
                                  : event?.program?.amount
                              }
                              date={event?.date}
                              startTime={event?.startTime}
                              isTaked={event?.kidsCompleted[i]}
                            />
                          </>
                        )}{' '}
                      </Text>
                    </div>
                  )
                })
              : null
          }
          aria-label='A tooltip'
        >
          <>
            <HStack justify='center' color='blackAlpha.800'>
              {event?.note && event?.note !== '' && (
                <CgNotes color='green' fontSize={18} />
              )}
            </HStack>
            <Text
              className={classNames.event_info}
              color='blackAlpha.800'
              noOfLines={1}
            >
              {event?.program?.type === 'Camp'
                ? 'Camp'
                : event.event === 'holiday'
                ? 'Leave'
                : event.name}
            </Text>
            <Text
              className={classNames.event_info}
              color='blackAlpha.800'
              noOfLines={1}
            >
              {event?.kid?.length > 1
                ? `${event?.kid[0]?.code} ,... `
                : event?.kid?.code}
            </Text>
            <Box
              className={classNames.event_info}
              color='blackAlpha.800'
              noOfLines={3}
              fontSize={15}
              fontWeight={600}
              justifyContent='center'
            >
              {event?.kid?.length > 1 ? (
                <Text mx={1}>
                  {`${event?.kid[0]?.nickname} `}
                  {event?.kidsCompleted[0] && (
                    <CompleteBox
                      programId={event?.programId[0]}
                      amount={event?.program?.amount}
                      date={event?.date}
                      startTime={event?.startTime}
                      isTaked={event?.kidsCompleted[0]}
                    />
                  )}{' '}
                  + {event?.kid.length - 1} Kids
                </Text>
              ) : (
                <Text mx={1}>
                  {event?.kid?.nickname}
                  {event?.program?.amount &&
                  event?.kid?.length > 1 ? null : event?.program?.amount ? (
                    <CompleteBox
                      programId={event?.programId}
                      amount={event?.program?.amount}
                      date={event?.date}
                      startTime={event?.startTime}
                      isTaked={event?.isTaked}
                    />
                  ) : (
                    ''
                  )}
                </Text>
              )}
              <Text
                className={classNames.event_info}
                color='blackAlpha.800'
                noOfLines={1}
              >
                {event?.kidsCompleted &&
                event?.kidsCompleted.reduce((a, e) => {
                  return a + (e ? 1 : 0)
                }, 0) > 0
                  ? event?.kidsCompleted.reduce((a, e) => {
                      return a + (e ? 1 : 0)
                    }, 0) === event?.kidsCompleted.length
                    ? 'All Completed'
                    : `${event?.kidsCompleted.reduce((a, e) => {
                        return a + (e ? 1 : 0)
                      }, 0)} Kids Completed`
                  : ''}
              </Text>
            </Box>
          </>
        </TooltipWithTouch>
      </Box>
    )
  }

  const staffPositionColor = (position) => {
    let color = '#FFF'

    switch (position) {
      case 'Occupational therapy (OT)':
        color = '#EBFEFF'
        break
      case 'Special education (SE)':
        color = '#FEEAEF'
        break
      case 'Speech therapy (ST)':
        color = '#FFFEEB'
        break
      default:
        break
    }

    return color
  }

  const onScroll = () => {
    const scrollTop = scrollRef.current.scrollTop
    if (scrollTop > 100) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  return (
    <Box p={{ base: 4, lg: 10 }}>
      {isOpen && (
        <TakeCourseModal
          isOpen={isOpen}
          onClose={onClose}
          courses={staffCoures}
          event={event}
          date={date}
          allStaff={staffs.filter((staff) => staff?.branchId === branch)}
          isCampSchedule={isCamp}
          managerBranchId={managerBranchId}
          selectedBranch={branch}
          role={role}
          fullscreen
          user={user}
          branchProvince={branchProvince}
        />
      )}
      {isOpenNewKid && (
        <NewKidModal
          isOpen={isOpenNewKid}
          onClose={onCloseNewKid}
          managerBranchId={managerBranchId}
          branches={branches.sort(
            (a, b) =>
              b.accountType.localeCompare(a.accountType) ||
              new Date(b.createdDate) - new Date(a.createdDate)
          )}
          selectedBranch={branch}
          fullscreen
          user={user}
          branchProvince={branchProvince}
        />
      )}
      {isOpenStaff && (
        <StaffModal
          isOpen={isOpenStaff}
          onClose={onCloseStaff}
          event={event}
          date={date}
          selectedBranch={branch}
          user={user}
        />
      )}

      <Text color='brand'>Search</Text>
      <Box
        borderWidth='1px'
        borderColor='brand'
        borderRadius='10px'
        mt={1}
        p={5}
      >
        <Center mb={4}>
          <Heading fontSize={['20px', '20px', '20px', '22px', '24px', '26px']}>
            {`${new Date(date).toLocaleDateString('th-TH', {
              month: 'long',
              day: 'numeric',
              weekday: 'long',
            })}`}{' '}
            {moment(date).format('YYYY')}
          </Heading>
        </Center>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 1 }}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 1 }}>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input
                id='date'
                type='date'
                onChange={(e) => {
                  setDate(e.target.value)
                }}
                value={date}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                onChange={(e) => setBranch(e.target.value)}
                value={branch || ''}
                isDisabled={managerBranchId}
              >
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch, i) => (
                    <option key={i} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </SimpleGrid>
          <Center borderRadius='25px' alignItems='flex-end' py={1}>
            <Button
              w={['6rem', '6rem', '6rem', '6rem', '7rem', '7rem']}
              fontSize={['14px', '14px', '14px', '14px', '16px', '16px']}
              bg={isCamp ? 'none' : 'lime.600'}
              color={isCamp ? 'lime.600' : 'white'}
              transition='all .2s'
              _hover={{
                opacity: '1',
                transition: 'all .2s',
              }}
              borderRadius='25px'
              onClick={() => setIscamp(false)}
              colorScheme='lime'
            >
              Schedule
            </Button>
            <Button
              w={['6rem', '6rem', '6rem', '6rem', '7rem', '7rem']}
              fontSize={['14px', '14px', '14px', '14px', '16px', '16px']}
              bg={isCamp ? 'lime.600' : 'none'}
              color={isCamp ? 'white' : 'lime.600'}
              transition='all .2s'
              border='1px'
              borderColor='white'
              _hover={{ opacity: '1', transition: 'all .2s' }}
              borderRadius='25px'
              onClick={() => setIscamp(true)}
              colorScheme='lime'
            >
              Camp
            </Button>
          </Center>
        </SimpleGrid>
      </Box>

      {isLoading ? (
        <Center
          minH='70vh'
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          my={2}
          pb={4}
        >
          <div className='custom-loader' />
        </Center>
      ) : !_.isEmpty(allSchedule) ? (
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          my={2}
          pb={4}
        >
          <SimpleGrid
            justifyContent='space-between'
            mx={2}
            columns={{ base: 1, md: 2 }}
          >
            <HStack>
              <Button
                m={2}
                ref={cancelRef}
                onClick={() => setShowDayoff(!showDayoff)}
                variant='solid'
                colorScheme={'orange'}
              >
                {`${!showDayoff ? 'Hide' : 'Show'} Day Off `}
              </Button>
              {role !== 'Staff' && (
                <Button
                  m={2}
                  ref={cancelRef}
                  onClick={() => {
                    onOpenNewKid()
                    // onCloseAlert()
                  }}
                  variant='outline'
                  colorScheme={'lime'}
                >
                  Evaluate New Kid
                </Button>
              )}
            </HStack>
            <Flex ml={{ base: '0', md: 'auto' }} justifyContent='center'>
              <Button
                m={2}
                onClick={() => setFullScreen(true)}
                variant='outline'
                colorScheme={'twitter'}
              >
                Enter fullscreen
              </Button>
            </Flex>
          </SimpleGrid>
          <Box className={fullscreen && 'no-bounce fullscreen'}>
            {fullscreen && (
              <Flex
                position='absolute'
                top='10px'
                right={2}
                zIndex='99 !important'
              >
                <IconButton
                  m={2}
                  onClick={() => setFullScreen(false)}
                  variant='solid'
                  colorScheme={'blackAlpha'}
                  isRound
                >
                  <MdCancel fontSize={30} />
                </IconButton>
              </Flex>
            )}

            <Box minH='30vh' px={2} className='full'>
              <div className='grid-container'>
                <div className='grid'>
                  <Box
                    ref={scrollRef}
                    className='grid-col'
                    overflow='auto !important'
                    h={{ base: fullscreen ? '90vh' : '640px', lg: '900px' }}
                    onScroll={onScroll}
                  >
                    {sticky && (
                      <Flex
                        flexDirection='row !important'
                        className={`grid-item--header styles-module_day_title__cc17c my_custom_day_header css-pq22qs`}
                        width={`${staffCount * 180}px`}
                        minWidth={`${staffCount * 180}px`}
                        justifyContent='space-around'
                        alignItems='center'
                      >
                        <Flex minW='80px' bg='white' h='122.22px'></Flex>
                        {Object.entries(allSchedule).map((data, index) => {
                          return (
                            <StaffHeaderPreview
                              key={index}
                              day={data[0]}
                              w='full'
                              rowHeight={122}
                              flexDirection='column'
                              display='flex'
                            />
                          )
                        })}
                      </Flex>
                    )}
                    <HStack
                      spacing={0}
                      style={{
                        width: `${staffCount * 180}px`,
                      }}
                    >
                      <Flex
                        flexDir='column'
                        className={`grid-col--fixed-left`}
                        height='full'
                        justifyContent='space-around'
                        alignItems='center'
                      >
                        <Box
                          height={
                            isCamp
                              ? '120px'
                              : isWeekend
                              ? '108.33px'
                              : '122.22px'
                          }
                        />
                        {_.range(
                          isCamp || isWeekend ? 9 : 12,
                          isCamp ? 13 : isWeekend ? 20 : 20
                        ).map((hour, i) => {
                          return (
                            <HourPreview
                              key={i}
                              className='styles-module_hour__TLyj9 css-1oxfwhg'
                              hour={''.concat(hour, ':00')}
                              height={
                                isCamp
                                  ? '120px'
                                  : isWeekend
                                  ? '108.33px'
                                  : '122.22px'
                              }
                              display={'flex'}
                            />
                          )
                        })}
                      </Flex>
                      <Timetable
                        bodyAttributes={{
                          className: 'my_custom_day_body  ',
                        }}
                        headerAttributes={{
                          className: 'my_custom_day_header',
                        }}
                        hoursInterval={{
                          from: isCamp || isWeekend ? 9 : 12,
                          to: isCamp ? 13 : isWeekend ? 20 : 20,
                        }}
                        events={allSchedule}
                        style={{
                          height: isCamp
                            ? '600px'
                            : isWeekend
                            ? '1300px'
                            : '1100px',
                          backgroundColor: 'white',
                          width: `${staffCount * 180}px`,
                          minWidth: `${staffCount * 180}px`,
                        }}
                        renderHour={HourPreview}
                        renderDayHeader={StaffHeaderPreview}
                        renderEvent={EventPreview}
                        timeLabel={''}
                      />
                    </HStack>
                  </Box>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      ) : (
        _.isEmpty(allSchedule) && (
          <Box
            borderWidth='1px'
            borderColor='brand'
            borderRadius='10px'
            my={2}
            pb={4}
          >
            <SimpleGrid
              justifyContent='space-between'
              mx={2}
              columns={{ base: 1, md: 2 }}
            >
              <HStack>
                <Button
                  m={2}
                  ref={cancelRef}
                  onClick={() => setShowDayoff(!showDayoff)}
                  variant='solid'
                  colorScheme={'orange'}
                >
                  {`${!showDayoff ? 'Hide' : 'Show'} Day Off Staff`}
                </Button>
                <Button
                  m={2}
                  ref={cancelRef}
                  onClick={() => {
                    onOpenNewKid()
                    // onCloseAlert()
                  }}
                  variant='outline'
                  colorScheme={'lime'}
                >
                  Evaluate New Kid
                </Button>
              </HStack>
            </SimpleGrid>
            <Center minH='50vh' w='100%'>
              <Heading size='lg'>No Schedules</Heading>
            </Center>
          </Box>
        )
      )}
    </Box>
  )
}
