import { useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { sendPasswordResetEmail } from 'firebase/auth'

import { auth } from 'config/firebase'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const navigate = useNavigate()
  const toast = useToast()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const [disableBtn, setDisableBtn] = useState(false)

  function onSubmit(values) {
    setDisableBtn(true)
    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        setDisableBtn(false)
        toast({
          title: 'Password reset email was sent',
          position: 'top',
          status: 'success',
          isClosable: true,
          duration: 2000,
        })
      })
      .catch((error) => {
        if (error) {
          let errorMessage = error.message
          switch (error.code) {
            case 'auth/user-not-found':
              toast({
                title: 'User Not Found.',
                position: 'top',
                status: 'error',
                isClosable: true,
                duration: 2000,
              })
              break
            case 'auth/wrong-password':
              toast({
                title: 'Password Incorrect.',
                position: 'top',
                status: 'error',
                isClosable: true,
                duration: 2000,
              })
              break
            case 'auth/user-disabled':
              toast({
                title: 'User Suspended.',
                description: 'Please contact your Agent',
                position: 'top',
                status: 'warning',
                isClosable: true,
                duration: 5000,
              })
              break
            default:
              toast({
                title: errorMessage,
                position: 'top',
                status: 'error',
                isClosable: true,
                duration: 2000,
              })
          }
          setDisableBtn(false)
        }
      })
  }

  return (
    <Container
      maxW='lg'
      py={{ base: '12', md: '24' }}
      px={{ base: '0', sm: '8' }}
      h={{ base: '68vh', md: '72vh' }}
    >
      <Stack spacing='8'>
        <Stack spacing='6'>
          <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
            <Heading
              size={useBreakpointValue({ base: 'lg', md: 'lg' })}
              color='brand'
            >
              Forgot Password
            </Heading>
            <Text color='#FFC211'>Please Input your email</Text>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing='6'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl mb='3' id='email' isInvalid={errors.email}>
                <FormLabel>EMAIL</FormLabel>
                <Input
                  name='email'
                  type='email'
                  {...register(`email`, {
                    required: 'Please input your email',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
                <FormErrorMessage mt='3' mb='3'>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <Button
                mt={4}
                w='100%'
                type='submit'
                isLoading={disableBtn}
                colorScheme='lime'
                bg='brand'
              >
                Reset Password
              </Button>
              <Button
                mt={4}
                w='100%'
                type='button'
                isDisabled={disableBtn}
                colorScheme='lime'
                variant='outline'
                onClick={() => navigate('/login')}
              >
                Log in
              </Button>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}
