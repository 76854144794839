import { Flex, IconButton, Image } from '@chakra-ui/react'
import UserProfile from './UserProfile'
import { FiMenu } from 'react-icons/fi'

export default function Header({ onOpen, user, ...rest }) {
  return (
    <Flex
      px='4'
      position='fixed'
      top='0'
      w='full'
      height='80px'
      zIndex={5}
      alignItems='center'
      bg='brand'
      boxShadow='0px 7px 12px rgba(0, 0, 0, 0.05);'
      justifyContent={{ base: 'space-between' }}
      {...rest}
    >
      <IconButton
        colorScheme='brand'
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        aria-label='open menu'
        icon={<FiMenu />}
        color='white'
        size='md'
      />
      <Image ml={{ base: 0, md: 2 }} height='50px' src='/Logo.png'></Image>
      <UserProfile user={user} />
    </Flex>
  )
}
