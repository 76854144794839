/* eslint-disable react-hooks/exhaustive-deps */
import { useState, forwardRef, useEffect } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

const ColorPickerInput = forwardRef(({ onChange, value, disable }, ref) => {
  const [state, setState] = useState({
    displayColorPicker: false,
    color: '#FFF',
  })

  useEffect(
    () =>
      setState({
        ...state,
        color: value,
      }),
    [value]
  )

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state?.displayColorPicker })
  }

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false })
  }

  const handleChange = (color) => {
    setState({ ...state, color: color?.hex ? color?.hex : '#FFF' })
    onChange(color.hex)
  }

  const styles = reactCSS({
    default: {
      color: {
        width: '100%',
        height: '20px',
        borderRadius: '0.375rem',
        background: state?.color,
        borderColor: 'gray',
        borderWidth: '0.5px',
      },
      swatch: {
        padding: '10px',
        background: '#fafafa',
        borderRadius: '0.375rem',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'block',
        cursor: !disable ? 'pointer' : '',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })

  return (
    <div ref={ref}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {!disable && state?.displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={state?.color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  )
})

export default ColorPickerInput
