import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { db, functions } from 'config/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import FormInputs from 'components/DynamicForm'
import { httpsCallable } from 'firebase/functions'

const handleStatusOff = httpsCallable(functions, 'handleStatusOff')

const activeForm = [
  {
    label: `Off/Discharge Date`,
    name: 'offDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `Reason`,
    name: 'reason',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
]
export default function OffModal({ isOpen, onClose, data, kidId }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function submit(values) {
    update(values)
  }

  function update(values) {
    setLoading(true)

    updateDoc(doc(db, `Kids/${kidId}/Status`, data.id), {
      ...values,
      activeDate: data?.activeDate,
      expireDate: data?.expireDate,
      offDate: new Date(moment(values.offDate, 'YYYY-MM-DD')),
      returnDate: '',
      status: 'off',
    })
      .then(() =>
        updateDoc(
          doc(db, `Kids`, kidId),
          {
            status: 'off',
            memberDetails: {
              ...values,
              activeDate: data?.activeDate,
              expireDate: data?.expireDate,
              offDate: new Date(moment(values.offDate, 'YYYY-MM-DD')),
              status: 'off',
              returnDate: '',
            },
          },
          { merge: true }
        )
      )
      .then(() => {
        handleStatusOff(kidId)
        toast({
          position: 'top',
          Header: 'Update Kid Active',
          description: 'Kid Active has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Off Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={activeForm || []}
            data={data}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
