/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { db } from 'config/firebase'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'

import useSchedule from 'hooks/useSchedule'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <Box {...groupStyles}>
    <Text fontSize='lg'>{data.name}</Text>
    <span style={groupBadgeStyles}>{data.programs.length}</span>
  </Box>
)

export default function ClearCourseModal({
  isOpen,
  onClose,
  kidId,
  kidStatus,
  selectedCourse,
  leftAmount,
  clearLeft,
  completedAmount,
  programId,
  branchProvince,
}) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const [courses, setCourseList] = useState([])
  const [amount, setAmount] = useState(clearLeft)
  const { clearCourse } = useSchedule({})

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm()

  const selectedDate = watch('date')

  useEffect(() => {
    if (branchProvince) {
      const querySnap = query(collection(db, 'Courses'))
      const unsubscribe = onSnapshot(querySnap, (snapShot) => {
        let data = []
        snapShot.forEach((doc) => {
          const options = doc
            .data()
            .programs.filter((p) => p.isActive)
            .map((program) => ({
              value: {
                courseId: doc.id,
                courseName: doc.data().name,
                coursDescription: doc.data().description,
                hours: doc.data().hours,
                score: doc.data().score,
                type: doc.data().type,
                ...program,
              },
              label: `${doc.data().name} ${program.choice} (${program.amount} ${
                program.unit
              })`,
            }))

          data.push({ ...doc.data(), id: doc.id, options })
        })

        if (kidStatus === 'evaluation') {
          data = data.filter((item) => item.type === 'Evaluation')
        }

        setCourseList(data)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [branchProvince])

  useEffect(() => {
    const currentCourse = courses?.find(
      (c) => c.id === selectedCourse?.value?.courseId
    )

    const isProgramActive = currentCourse?.programs.find(
      (p) =>
        (p?.choice === selectedCourse?.value?.choice && p.isActive) ||
        (p?.choiceId === selectedCourse?.value?.choiceId && p.isActive)
    )

    if (isProgramActive) {
      setValue('course', selectedCourse)
    }
  }, [selectedCourse, selectedDate, courses])

  const create = async (data) => {
    setLoading(true)
    try {
      const { note } = data
      await clearCourse({
        note,
        programId,
        kidId,
        amount,
        leftAmount,
        completedAmount,
      })

      toast({
        position: 'top',
        title: 'Clear Course',
        description: 'Clear Course has been success.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      onClose()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Clear Course Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Clear Course</ModalHeader>
        <ModalCloseButton />

        <ModalBody p={10}>
          <FormControl
            my={3}
            id='course'
            name='course'
            isInvalid={true}
            w={'100%'}
          >
            <FormLabel>Program</FormLabel>
            <Controller
              name='course'
              control={control}
              rules={{
                required: 'Please select a course',
              }}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Select
                  id='course'
                  name='course'
                  placeholder='Select course'
                  options={courses}
                  formatGroupLabel={formatGroupLabel}
                  onChange={onChange}
                  value={value}
                  isDisabled
                />
              )}
            />
            <FormErrorMessage>
              {errors.course && errors.course.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl my={3} id='course' name='course' w={'100%'}>
            <FormLabel>จำนวนครั้งต้องการเคลียร์</FormLabel>
            <NumberInput
              value={amount}
              min={1}
              max={leftAmount}
              onChange={setAmount}
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <FormControl
            my={3}
            id='course'
            name='course'
            w={'100%'}
            isInvalid={!!errors.note}
          >
            <FormLabel>เหตุผล</FormLabel>
            <Controller
              name='note'
              control={control}
              rules={{
                required: 'Please enter note',
              }}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  id='note'
                  name='note'
                  placeholder='Note'
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <FormErrorMessage>
              {errors.note && errors.note.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(create)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
