import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Text,
  VStack,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Center,
} from '@chakra-ui/react'
import FormInputs from 'components/DynamicForm'
import { db } from 'config/firebase'
import { doc, getDoc, onSnapshot, query, writeBatch } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate, Link } from 'react-router-dom'

const fieldForm = [
  {
    label: 'Notify Every',
    name: 'days',
    type: 'checkbox',
    options: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    placeholder: '',
    defaultValue: [],
    required: false,
  },
  {
    label: 'Notify Before Expiration',
    name: 'action',
    type: 'dropdown',
    options: [
      '1 Week',
      '2 Week',
      '3 Week',
      '4 Week',
      '1 Month',
      '2 Month',
      '3 Month',
    ],
    placeholder: '',
    defaultValue: '',
    required: false,
  },
]

function NotificationSetting({ user }) {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    reValidateMode: 'onChange',
  })

  async function submitForm(value) {
    setIsLoadingSave(true)
    const batch = writeBatch(db)
    const notiRef = doc(db, 'Settings', 'Notification')
    const notiData = await (await getDoc(notiRef)).data()
    if (!notiData) {
      batch.set(notiRef, {
        ...value,
        createdAt: new Date(),
      })
      batch.commit().then(() => {
        toast({
          position: 'top',
          Header: 'Notification',
          description: 'Notification has been updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        return setIsLoadingSave(false)
      })
    } else {
      batch.update(
        notiRef,
        {
          ...value,
          updatedAt: new Date(),
        },
        { merge: true }
      )
      batch.commit().then(() => {
        toast({
          position: 'top',
          Header: 'Notification',
          description: 'Notification has been updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        return setIsLoadingSave(false)
      })
    }
  }

  useEffect(() => {
    const queryNotiRef = query(doc(db, 'Settings', 'Notification'))
    const unsubscribe = onSnapshot(queryNotiRef, (snapData) => {
      setData({ ...snapData.data() })
      setIsLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/settings'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {!isLoading ? (
        <>
          <Box p={{ base: 4, lg: 10 }}>
            {/* <SpinnerOverlay loading={submitting} /> */}
            <Flex justifyContent='flex-start'>
              <IconButton
                size={20}
                variant='ghost'
                color='brand'
                icon={<BiArrowBack size={30} />}
                onClick={() => navigate('/settings')}
              />
            </Flex>
            <VStack>
              <Heading>Notification</Heading>
              <Flex justifyContent='flex-end' w={'100%'}></Flex>
            </VStack>
            <Text color={'brand'}>Close to Expiring</Text>
            <Box
              p={5}
              borderRadius={10}
              mb={10}
              borderWidth='1px'
              borderColor='brand'
            >
              <VStack>
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={fieldForm || []}
                  data={data}
                />
              </VStack>
            </Box>

            <HStack justifyContent='center' my={10}>
              <Button mr={3} variant='outline' onClick={onOpen}>
                Cancel
              </Button>
              <Button
                colorScheme='lime'
                bg='brand'
                onClick={handleSubmit(submitForm)}
                isLoading={isLoadingSave}
              >
                Submit
              </Button>
            </HStack>
          </Box>
        </>
      ) : (
        <Box p={{ base: 4, lg: 10 }}>
          <Center minH='40vh'>
            <div className='custom-loader' />
          </Center>
        </Box>
      )}
    </>
  )
}

export default NotificationSetting
