/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table as ChakraTable,
  Tbody,
  Td,
  Text,
  Tr,
  Thead,
  Th,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Heading,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { search } from 'ss-search'
import moment from 'moment/moment'
import Table from 'components/Horizons/table'
import useCourse from 'hooks/useCourse'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'config/firebase'
import { FaFileExport } from 'react-icons/fa'
import axios from 'axios'
import { useQuery } from 'react-query'

const createFileExcelReportUsages = httpsCallable(
  functions,
  'createReportUsages'
)

const getReportAp = httpsCallable(functions, 'getReportAp')

function ModalItems({ onClose, isOpen, data }) {
  const { kid, reducUsageByPrograms } = data
  return (
    <Modal
      size='5xl'
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent w='full' mx={4}>
        <ModalHeader fontWeight='normal'></ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={{ base: '5px', sm: '5px', md: '15px' }}
          maxH='80vh'
          overflowY='auto'
        >
          <Heading size='lg' textAlign='center'>{`${kid?.name} ${
            kid?.nickname ? `(${kid?.nickname})` : ''
          }`}</Heading>
          <Heading size='lg' textAlign='center'>{`${kid?.code}`}</Heading>
          <Heading px={5} size='md' my={5}>
            รายการใช้งาน
          </Heading>
          <Center
            p='0.5rem'
            w='full'
            border='1px solid #E2E8F0'
            overflowX='auto'
            my={5}
          >
            <ChakraTable pt='0px' mt='0px'>
              <Thead bg='#E0E1E2'>
                <Tr>
                  <Th textAlign='start' color='black'>
                    <Text>โปรแกรม</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>ปัจจุบันใช้แล้ว</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>ราคา</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>ราคา/ครั้ง</Text>
                  </Th>
                  <Th textAlign='end' color='black'>
                    <Text>ยอดใช้งานคงเหลือ</Text>
                  </Th>

                  <Th textAlign='end' color='black'>
                    <Text>ยอดค้างชำระ</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {reducUsageByPrograms.map((p, i) => {
                  const totalpaid = p?.payment?.transactions?.reduce(
                    (acc, item) => {
                      return acc + Number(item?.paid || 0)
                    },
                    0
                  )
                  return (
                    <Tr key={i}>
                      <Td textAlign='start'>
                        <Text>{`${p.courseName} ${p.choice} (${p.amount} ครั้ง)`}</Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>{`${p.isTaked} / ${p?.amount}`}</Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>
                          {p?.price
                            ? Number(p?.price).toLocaleString('th-TH', {
                                maximumFractionDigits: 2,
                              })
                            : ''}
                        </Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>
                          {p?.price
                            ? Number(p?.price / p.amount).toLocaleString(
                                'th-TH',
                                {
                                  maximumFractionDigits: 2,
                                }
                              )
                            : ''}
                        </Text>
                      </Td>
                      <Td textAlign='end'>
                        <Text>
                          {p?.price
                            ? (
                                Number(p?.price) -
                                Number(p.isTaked * (p?.price / p.amount))
                              ).toLocaleString('th-TH', {
                                maximumFractionDigits: 2,
                              })
                            : ''}
                        </Text>
                      </Td>

                      <Td textAlign='end'>
                        <Text>
                          {(p?.price - totalpaid < 0
                            ? 0
                            : p?.price - totalpaid
                          ).toLocaleString('th-TH', {
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </ChakraTable>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function kidStatus(status) {
  if (status === 'member') {
    return (
      <Box fontWeight='semibold' color='brand'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'evaluation') {
    return (
      <Box fontWeight='semibold' color='#FFC211'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'off') {
    return (
      <Box fontWeight='semibold' color='#767262'>
        {status.toUpperCase()}
      </Box>
    )
  }
}

export function Usages({ branches, managerBranchId, role }) {
  const { courses } = useCourse()
  const columnsData = [
    {
      Header: 'KID CODE',
      accessor: 'kid.code',
    },
    {
      Header: 'Status',
      accessor: 'kid.status',
      extra: (data, row) => (
        <HStack>
          <Box>{kidStatus(data)}</Box>
        </HStack>
      ),
    },
    {
      Header: 'ชื่อ-นามสกุล',
      accessor: 'kid.name',
    },
    {
      Header: 'ชื่อเล่น',
      accessor: 'kid.nickname',
    },
    {
      Header: 'จำนวนโปรแกรม',
      accessor: 'reducUsageByPrograms',
      extra: (data) => data?.length,
    },

    {
      Header: 'Action',
      accessor: 'kid',
      width: '10%',
      extra: (data, row) => (
        <Button
          variant='link'
          textDecoration='underline'
          onClick={() => {
            setModalData(row)
            onOpen()
          }}
        >
          รายละเอียด
        </Button>
      ),
    },
  ]
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || '',
    type: '',
    date: moment().format('YYYY-MM'),
  })
  const [modalData, setModalData] = useState()
  const [downloadIsLoading, setDownloadIsLoading] = useState(false)
  const { onOpen, isOpen, onClose } = useDisclosure()

  const [dataQuery, setDataQuery] = useState([])

  const { data: reportUsage, isLoading } = useQuery(
    ['apReport', searchQuery?.branch],
    async () => {
      const response = await getReportAp({
        branchId: searchQuery?.branch,
      })
      if (!response.data) {
        throw new Error('Network response was not ok')
      }
      return response.data
    },
    { refetchOnWindowFocus: false }
  )

  async function handleDownloadFile(dataDownload) {
    const findBranch = branches.find((b) => b.id === searchQuery.branch)
    setDownloadIsLoading(true)
    await createFileExcelReportUsages({
      branch: findBranch,
      data: JSON.stringify(dataDownload),
    })
      .then(async (i) => {
        const url = i.data.fileUrl
        const fileName = i.data.fileName
        await axios({
          url,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}`)
            document.body.appendChild(link)
            link.click()
          })
          .catch((err) => {
            console.error(err)
            setDownloadIsLoading(false)
          })
        setDownloadIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setDownloadIsLoading(false)
      })
  }

  useEffect(() => {
    if (reportUsage) {
      setDataQuery(reportUsage)
    }
  }, [reportUsage])

  function handleSearch(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, text: e.target.value })
    let textSearch = e.target.value
    const searchQueryArr = reportUsage
    const searchKeys = ['kid.name', 'kid.nickname', 'kid.code']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      searchQuery.type !== ''
        ? searchResults.filter((s) => {
            return s.reducUsageByPrograms.some(
              (p) => p.courseId === searchQuery.type
            )
          })
        : searchResults
    setDataQuery(filteredResults)
  }

  function handleSelectType(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, type: e.target.value })
    let textSearch = searchQuery.text
    const searchQueryArr = reportUsage
    const searchKeys = ['kid.name', 'kid.nickname', 'kid.code']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      e.target.value !== ''
        ? searchResults.filter((s) => {
            return s.reducUsageByPrograms.some(
              (p) => p.courseId === e.target.value
            )
          })
        : searchResults
    setDataQuery(filteredResults)
  }

  return (
    <Box>
      {isOpen && (
        <ModalItems isOpen={isOpen} onClose={onClose} data={modalData} />
      )}
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={5}
          p={5}
        >
          <HStack
            spacing={{ base: 0, md: '20px' }}
            flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          >
            <FormControl>
              <FormLabel>Search Name</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) => handleSearch(e)}
                value={searchQuery.text}
                placeholder='Search Name'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Pleas Select branch'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {/* {role === 'Owner' && <option value='all'>All Branches</option>} */}
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Course</FormLabel>

              <Select
                id='course'
                placeholder='ทั้งหมด'
                onChange={(e) => handleSelectType(e)}
                value={searchQuery.type}
              >
                {courses.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl>
              <HStack w='full' justifyContent='space-between'>
                <FormLabel>{isChecked ? 'Date' : 'Month'}</FormLabel>
                <Checkbox
                  colorScheme='lime'
                  onChange={(e) => {
                    setIsChecked(e.target.checked)
                  }}
                >
                  เลือกแบบวันที่
                </Checkbox>
              </HStack>
              {isChecked ? (
                <DatePicker
                  allowClear={false}
                  format={'DD MMMM YYYY'}
                  value={moment(searchQuery.date)}
                  onChange={(date) => {
                    setSearch({
                      ...searchQuery,
                      date: moment(date).format('YYYY-MM-DD'),
                    })
                  }}
                />
              ) : (
                <DatePicker
                  allowClear={false}
                  picker='month'
                  format={'MMMM YYYY'}
                  value={moment(searchQuery.date)}
                  onChange={(date) =>
                    setSearch({
                      ...searchQuery,
                      date: moment(date).format('YYYY-MM'),
                    })
                  }
                />
              )}
            </FormControl> */}
            <HStack alignSelf={'end'}>
              <IconButton
                variant='outline'
                fontSize={'23px'}
                icon={<FaFileExport color='#8CC63E' />}
                onClick={() => {
                  handleDownloadFile(dataQuery)
                }}
                disabled={downloadIsLoading || !dataQuery.length > 0}
                isLoading={downloadIsLoading}
              />
            </HStack>
          </HStack>
        </Box>
      </form>
      {searchQuery.branch !== '' ? (
        <Table
          columnsData={columnsData}
          tableData={dataQuery}
          noSearch
          loading={isLoading}
        />
      ) : (
        <Center>
          <Heading>Please Select Branch</Heading>
        </Center>
      )}
    </Box>
  )
}
