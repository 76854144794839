import {
  Button,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react'
import { httpsCallable } from 'firebase/functions'
import { useState, Fragment } from 'react'
import { useForm } from 'react-hook-form'

import { functions } from 'config/firebase'

const createRecieptFromBill = httpsCallable(functions, 'createRecieptFromBill')

function CreateRecieptButton({ receiptRef, id }) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isSubmitting, setSubmitting] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const handleCreateReciept = async (values) => {
    setSubmitting(true)
    try {
      createRecieptFromBill({ paymentId: id, month: values.month })
        .then(() => setSubmitting(false))
        .then(() => onClose())
        .then(() =>
          toast({
            position: 'top',
            Header: 'Create Reciept',
            description: 'Reciept has been Created.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        )
    } catch (error) {
      setSubmitting(false)
      toast({
        position: 'top',
        Header: 'Create Fail.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <form onSubmit={handleSubmit(handleCreateReciept)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create Reciept</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl isRequired isInvalid={errors.month}>
                <FormLabel>Select Month & Year</FormLabel>
                <Input
                  name='month'
                  type='month'
                  {...register(`month`, {
                    required: 'Please select',
                  })}
                />
              </FormControl>
              <FormErrorMessage>Month & Year is required.</FormErrorMessage>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='gray' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='solid'
                colorScheme='orange'
                bg='#F6872B'
                isLoading={isSubmitting}
                type='submit'
              >
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <Button
        variant='solid'
        colorScheme='orange'
        bg='#F6872B'
        size='sm'
        disabled={receiptRef || receiptRef === '' || isSubmitting}
        onClick={onOpen}
      >
        Create Reciept
      </Button>
    </Fragment>
  )
}

export default CreateRecieptButton
