import { Box, Image, Flex } from '@chakra-ui/react'

const Header = ({ bg }) => {
  return (
    <Flex
      height={{ base: 150, md: 200 }}
      bg={bg}
      justifyContent='center'
      alignItems='center'
    >
      <Image
        ml={{ base: 0, md: 2 }}
        // height={{ base: 100, md: 100 }}
        src='/Logo.webp'
      />
    </Flex>
  )
}

export default function AuthLayout({ children, bg }) {
  return (
    <Box minH='100vh' bg='white'>
      <Header bg={bg} />
      <Box p={2}>{children}</Box>
      {/* <Footer /> */}
    </Box>
  )
}
