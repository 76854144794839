/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useMemo, useState, useEffect, useRef } from 'react'
import {
  Center,
  Button,
  Box,
  HStack,
  Heading,
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  Flex,
  Switch,
  NumberInput,
  NumberInputField,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Select,
} from '@chakra-ui/react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiFillDelete } from 'react-icons/ai'
import { db } from 'config/firebase'
import {
  onSnapshot,
  query,
  doc,
  updateDoc,
  addDoc,
  collection,
} from 'firebase/firestore'
import { BiArrowBack } from 'react-icons/bi'
import randomId from 'random-id'
import FormInputs from 'components/DynamicForm'
import ColorPickerInput from 'components/ColorPickerInput'
import SpinnerOverlay from 'components/SpinnerOverlay'

import { courseForm, programForm } from './fields'

const FormatNumber = ({ control, data, i }) => {
  const format = (val) => Number(val).toLocaleString('th-TH')
  const parse = (val) => Number(val.replace(/^\$/, ''))

  return (
    <Controller
      control={control}
      name={`programs.${i}.${data.name}`}
      render={({ field: { onChange, value } }) => (
        <NumberInput
          name={`programs.${i}.${data.name}`}
          type={data.type}
          placeholder={data.placeholder}
          defaultValue={format(data.defaultValue)}
          onChange={(valueString) => {
            onChange(parse(valueString))
          }}
          min={0}
          value={format(value)}
        >
          <NumberInputField />
        </NumberInput>
      )}
    />
  )
}

export default function CourseForm({ user }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const [data, setdata] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    register,
    getValues,
    watch,
    reset,
  } = useForm()

  const watchPrograms = watch(`programs`)

  const programs = useMemo(() => getValues('programs'), [watchPrograms])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'programs',
  })

  useEffect(() => {
    if (id) {
      setLoadingData(true)
      const queryCourse = query(doc(db, 'Courses', id))
      const unsubscribe = onSnapshot(queryCourse, (snapShot) => {
        setdata({
          ...snapShot.data(),
          id: snapShot.id,
        })
        if (snapShot.data()?.programs.length > 0) {
          reset()
          snapShot.data().programs.map((program) =>
            append({
              isActive: program.isActive,
              limit: program.limit,
              choice: program.choice,
              amount: program.amount,
              unit: program.unit,
              color: program.color,
              price: program.price,
              choiceId: program?.choiceId || randomId(),
            })
          )
        }
        setLoadingData(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  function addInput() {
    append({
      amount: 1,
      price: 1,
      unit: 'ครั้ง',
      color: '#FFF',
      choiceId: randomId(),
    })
  }

  function submit(values) {
    setLoading(true)
    if (data?.id) {
      updateCourse(values)
    } else {
      createCourse(values)
    }
  }

  function createCourse(values) {
    addDoc(collection(db, 'Courses'), {
      ...values,
      hours: Number(values.hours),
      createdAt: new Date(),
      createdBy: user?.uid,
    })
      .then(() => {
        toast({
          position: 'top',
          Header: 'Create Course',
          description: 'Course has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        navigate('/settings/course')
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          Header: 'Create Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  function updateCourse(values) {
    updateDoc(doc(db, 'Courses', data.id), {
      ...values,
      hours: Number(values.hours),
      updatedAt: new Date(),
      updatedBy: user?.uid,
    })
      .then(() => {
        toast({
          position: 'top',
          Header: 'Update Course',
          description: 'Course has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        navigate('/settings/course')
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }
  if (isLoadingData || (id && !data)) {
    return <SpinnerOverlay loading={true} />
  }

  const header = ['Program', 'Amount', 'Unit', 'Price', 'Color']

  return (
    <Box p={{ base: 2, lg: 5 }}>
      <SpinnerOverlay loading={isLoading} />
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/settings/course'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Box p={{ base: 2, lg: 5 }}>
        <Flex justifyContent='flex-start'>
          <IconButton
            size={30}
            variant='ghost'
            color='brand'
            icon={<BiArrowBack size={30} />}
            onClick={() => navigate('/settings/course')}
          />
        </Flex>
        <Center>
          <Heading>{id ? 'EDIT COURSE' : 'CREATE COURSE'}</Heading>
        </Center>
        <Box>
          <Heading as='h5' size='sm' my={2} color='brand'>
            Course Information
          </Heading>
          <Box
            p={5}
            borderRadius={10}
            mb={5}
            borderWidth='1px'
            borderColor='brand'
          >
            <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
              <FormInputs
                errors={errors}
                control={control}
                forms={courseForm || []}
                data={data}
              />
            </Box>
          </Box>
          <Heading as='h5' size='sm' my={2} color='brand'>
            Course Program
          </Heading>
          <Box
            p={5}
            border='1px solid brand'
            borderRadius={10}
            borderWidth='1px'
            borderColor='brand'
          >
            <Box>
              <SimpleGrid
                columns={[1, 1, 1, 4, 8]}
                spacing={[1, 1, 1, '15px']}
                justifyContent='center'
                alignItems='center'
              >
                {fields.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <Flex
                        justifyContent={{ base: 'center', lg: 'center' }}
                        alignItems={{ base: 'end', lg: 'center' }}
                        mb={3}
                        ml={3}
                        w={'100%'}
                      >
                        <FormControl mb={3}>
                          <FormLabel>Active</FormLabel>
                          <Controller
                            name={`programs.${i}.isActive`}
                            control={control}
                            defaultValue={
                              data?.programs?.[i]?.['isActive'] || true
                            }
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Switch
                                  colorScheme='lime'
                                  size='lg'
                                  isChecked={value}
                                  onChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </FormControl>
                      </Flex>
                      {programForm.map((data) => (
                        <FormControl
                          key={`programs.${i}.${data.name}`}
                          mb='3'
                          isInvalid={errors.programs?.[i]?.[data.name]}
                          id={`programs[${i}]name`}
                        >
                          <FormLabel>{`${data.label}`}</FormLabel>

                          {data.type === 'color' ? (
                            <Controller
                              control={control}
                              name={`programs.${i}.${data.name}`}
                              render={({ field: { onChange, value } }) => (
                                <ColorPickerInput
                                  onChange={onChange}
                                  value={value || '#FFF'}
                                />
                              )}
                            />
                          ) : data.type === 'text' ? (
                            <Input
                              {...register(`programs.${i}.${data.name}`, {
                                required: data.required,
                              })}
                              name={`programs.${i}.${data.name}`}
                              type={data.type}
                              placeholder={data.placeholder}
                              defaultValue={data.defaultValue}
                            />
                          ) : (
                            <FormatNumber
                              control={control}
                              register={register}
                              data={data}
                              i={i}
                            />
                          )}
                          <FormErrorMessage>
                            {errors.programs?.[i]?.[data.name] &&
                              `${data?.label} is Require Field `}
                          </FormErrorMessage>
                        </FormControl>
                      ))}
                      <FormControl
                        key={`programs.${i}.courseTag`}
                        mb='3'
                        isInvalid={errors.programs?.[i]?.[data.name]}
                        id={`programs[${i}]courseTag`}
                      >
                        <FormLabel>คอร์ส เต็ม/รายครั้ง</FormLabel>
                        <Controller
                          name={`programs.${i}.courseTag`}
                          control={control}
                          defaultValue={
                            data?.programs?.[i]?.['courseTag'] || 'full'
                          }
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                name={`programs.${i}.courseTag`}
                                onChange={onChange}
                                value={value}
                              >
                                <option value={'full'}>คอร์สเต็ม</option>
                                <option value={'one'}>รายครั้ง</option>
                              </Select>
                            )
                          }}
                        />
                      </FormControl>
                      <FormControl
                        key={`programs.${i}.limit`}
                        mb='3'
                        isInvalid={errors.programs?.[i]?.[data.name]}
                        id={`programs[${i}]name`}
                      >
                        <FormLabel>Limit</FormLabel>
                        <Controller
                          name={`programs.${i}.limit`}
                          control={control}
                          defaultValue={data?.programs?.[i]?.['limit'] || 0}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                name={`programs.${i}.limit`}
                                onChange={onChange}
                                value={value}
                              >
                                <option value={0}>No limit</option>
                                <option value={1}>1 Month</option>
                                <option value={2}>2 Month</option>
                                <option value={3}>3 Month</option>
                                <option value={4}>4 Month</option>
                                <option value={6}>6 Month</option>
                                <option value={8}>8 Month</option>
                                <option value={12}>12 Month</option>
                              </Select>
                            )
                          }}
                        />
                      </FormControl>

                      <Flex
                        justifyContent={{ base: 'center', lg: 'flex-start' }}
                        alignItems={{ base: 'end', lg: 'center' }}
                        mb={3}
                      >
                        <FormControl w={'100%'} mb={3}>
                          <FormLabel>Is Multiple</FormLabel>
                          <Controller
                            name={`programs.${i}.isMulti`}
                            control={control}
                            defaultValue={
                              data?.programs?.[i]?.['isMulti'] || false
                            }
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Switch
                                  colorScheme='lime'
                                  size='lg'
                                  isChecked={value}
                                  onChange={onChange}
                                  value={value}
                                />
                              )
                            }}
                          />
                        </FormControl>

                        <IconButton
                          p={0}
                          // variant='ghost'
                          onClick={async () => {
                            remove(i)
                          }}
                          colorScheme='red'
                          icon={<AiFillDelete />}
                        >
                          Delete
                        </IconButton>
                      </Flex>
                    </Fragment>
                  )
                })}
              </SimpleGrid>

              <Center>
                <Button
                  colorScheme='lime'
                  bg='brand'
                  mt='5'
                  mb='5'
                  width={['100%', '100%', '30%', '20%']}
                  type='button'
                  onClick={() => addInput()}
                >
                  + Add Program
                </Button>
              </Center>
            </Box>

            {programs?.length > 0 && (
              <TableContainer
                borderWidth={1}
                w={'100%'}
                borderRadius={10}
                mt={10}
              >
                <Table>
                  <Thead>
                    <Tr>
                      {header?.map((header) => (
                        <Th w={'20%'} key={header}>
                          {header}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {programs?.map((data, index) => (
                      <Tr key={index}>
                        <Td>{data.choice}</Td>
                        <Td>
                          {data.amount
                            ? `${Number(data.amount).toLocaleString('th-TH')}`
                            : ''}
                        </Td>
                        <Td>{data.unit}</Td>
                        <Td>
                          {`${Number(data?.price || 0).toLocaleString(
                            'th-TH'
                          )} บาท`}
                        </Td>
                        <Td>
                          <ColorPickerInput value={data.color} disable />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>

          <HStack justifyContent='center' my={10}>
            <Button mr={3} variant='outline' onClick={onOpen}>
              Cancel
            </Button>
            <Button
              colorScheme='lime'
              bg='brand'
              onClick={handleSubmit(submit)}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}
