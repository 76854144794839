/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Table as ChakraTable,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import axios from 'axios'
import Table from 'components/Horizons/table'
import { functions } from 'config/firebase'
import { httpsCallable } from 'firebase/functions'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { FaFileExport } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { search } from 'ss-search'

const createFileExcelReportActiveCases = httpsCallable(
  functions,
  'createReportOffCase'
)
const getOffCaseReport = httpsCallable(functions, 'getOffCaseReport')

function kidStatus(status, expireDate) {
  if (status === 'member') {
    return (
      <Box fontWeight='semibold' color='brand'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (
    status === 'member' &&
    moment(expireDate).diff(moment(), 'days') <= 0
  ) {
    return (
      <Box fontWeight='semibold' color='red'>
        EXPIRED
      </Box>
    )
  } else if (status === 'evaluation') {
    return (
      <Box fontWeight='semibold' color='#FFC211'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'off') {
    return (
      <Box fontWeight='semibold' color='#767262'>
        {status.toUpperCase()}
      </Box>
    )
  }
}

function ModalItems({ onClose, isOpen, data }) {
  const { kid, activeCases } = data
  return (
    <Modal
      size='2xl'
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent w='full' mx={4}>
        <ModalHeader fontWeight='normal'></ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={{ base: '5px', sm: '5px', md: '15px' }}
          maxH='80vh'
          overflowY='auto'
        >
          <Heading size='lg' textAlign='center'>{`${
            kid?.name ? kid?.name : ''
          } ${kid?.nickname ? `(${kid.nickname})` : ''}`}</Heading>
          <Center
            p='0.5rem'
            w='full'
            border='1px solid #E2E8F0'
            overflowX='auto'
            my={5}
          >
            <ChakraTable variant='simple' size='md'>
              <Tbody>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    วันเดือนปีเกิด
                  </Td>
                  <Td fontSize='lg'>
                    {kid?.birthday && kid?.birthday !== ''
                      ? moment(kid?.birthday).format('DD/MM/YYYY')
                      : ''}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    โรงเรียน
                  </Td>
                  <Td fontSize='lg'>{kid.school}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    ระดับชั้นเรียน
                  </Td>
                  <Td fontSize='lg'>{kid.level}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    แพทย์ที่ดูแล
                  </Td>
                  <Td fontSize='lg'>{kid?.development?.doctor}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    รู้จัก Kids Plus จาก
                  </Td>
                  <Td fontSize='lg'>{kid?.from}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    1st Treatment Date
                  </Td>
                  <Td fontSize='lg'>
                    {kid?.firstTreatmentDate
                      ? moment(kid?.firstTreatmentDate, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY'
                        )
                      : ''}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg'>
                    Telephone No.
                  </Td>
                  <Td fontSize='lg'>{kid?.telephone}</Td>
                </Tr>
                <Tr>
                  <Td
                    fontWeight={600}
                    fontSize='lg'
                    color='#FF3E3E;
  '
                  >
                    วันที่ Off
                  </Td>
                  <Td fontSize='lg'>
                    {kid?.memberDetails?.offDate
                      ? moment(kid?.memberDetails?.offDate).format('DD/MM/YYYY')
                      : moment(kid?.memberDetails?.expireDate).isBefore(
                          new Date()
                        )
                      ? moment(kid?.memberDetails?.expireDate).format(
                          'DD/MM/YYYY'
                        )
                      : '-'}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight={600} fontSize='lg' color='#FF3E3E'>
                    สาเหตุที่ Off
                  </Td>
                  <Td fontSize='lg'>
                    {kid?.memberDetails?.offDate
                      ? kid?.memberDetails?.reason
                      : moment(kid?.memberDetails?.expireDate).isBefore(
                          new Date()
                        )
                      ? 'Expired'
                      : '-'}
                  </Td>
                </Tr>
              </Tbody>
            </ChakraTable>
          </Center>
          <Heading px={5} size='md'>
            โปรแกรมการเรียนและความถี่ ณ ปัจจุบัน
          </Heading>
          <Center
            p='0.5rem'
            w='full'
            border='1px solid #E2E8F0'
            overflowX='auto'
            my={5}
          >
            <ChakraTable pt='0px' mt='0px'>
              <Thead bg='#E0E1E2'>
                <Tr>
                  <Th textAlign='start' color='black'>
                    <Text>โปรแกรม</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>ความถี่ / เดือน</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>ครูผู้สอน</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {activeCases
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((data, i) => {
                    return (
                      <Tr key={i}>
                        <Td textAlign='start'>
                          <Text>{`${data.program.courseName} ${data.program.choice} (${data.program.amount} ครั้ง)`}</Text>
                        </Td>
                        <Td textAlign='start'>
                          <Text>{data?.count}</Text>
                        </Td>
                        <Td textAlign='start'>
                          <Text>{data.staff.nickname}</Text>
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </ChakraTable>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export function OffCases({ data, branches, managerBranchId }) {
  const columnsData = [
    {
      Header: 'Code',
      accessor: 'kid.code',
      width: '10%',
    },
    {
      Header: 'ชื่อ-นามสกุล',
      accessor: 'kid.name',
      width: '10%',
    },
    {
      Header: 'ชื่อเล่น',
      accessor: 'kid.nickname',
      width: '10%',
    },
    {
      Header: 'ความถี่ของโปรแกรมทั้งหมด',
      accessor: 'activeCases',
      width: '10%',
      extra: (data, row) => {
        return data
          ? data.reduce((a, i) => {
              return a + i?.count || 0
            }, 0)
          : 0
      },
    },

    {
      Header: 'สถานะ',
      accessor: 'kid.status',
      width: '10%',
      extra: (data) => kidStatus(data),
    },
    {
      Header: 'Off Date',
      accessor: 'kid.memberDetails.offDate',
      width: '10%',
      extra: (date) => date && moment(date).format('DD/MM/YYYY'),
    },

    {
      Header: 'Action',
      accessor: 'kid',
      width: '10%',
      extra: (data, row) => (
        <Button
          variant='link'
          textDecoration='underline'
          onClick={() => {
            setModalData(row)
            onOpen()
          }}
        >
          รายละเอียด
        </Button>
      ),
    },
  ]

  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || '',
    type: '',
    month: moment().format('YYYY-MM'),
  })

  const [modalData, setModalData] = useState()
  const [downloadIsLoading, setDownloadIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { data: reportActive, isLoading } = useQuery(
    ['offCase', searchQuery.branch, searchQuery.month],
    async () => {
      const response = await getOffCaseReport({
        branchId: searchQuery?.branch,
        month: searchQuery?.month,
      })

      if (!response.data) {
        throw new Error('Network response was not ok')
      }
      return response.data
    },
    { refetchOnWindowFocus: false }
  )
  const [dataQuery, setDataQuery] = useState([])

  useEffect(() => {
    setSearch({
      ...searchQuery,
      month: isChecked
        ? [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        : moment().format('YYYY-MM'),
    })
  }, [isChecked])

  useEffect(() => {
    if (reportActive) {
      setDataQuery(reportActive)
    }
  }, [reportActive])

  function handleSearch(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, text: e.target.value })
    let textSearch = e.target.value
    const searchQueryArr = reportActive
    const searchKeys = ['kid.name', 'kid.nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      searchQuery.type !== ''
        ? searchResults.filter((s) => {
            return s.kid.status === searchQuery.type
          })
        : searchResults
    setDataQuery(filteredResults)
  }

  async function handleDownloadFile(dataDownload) {
    setDownloadIsLoading(true)
    const mapNumber = await dataDownload?.map((d, index) => {
      return {
        ...d,
        number: index + 1,
        birthday:
          d?.kid?.birthday && d?.kid?.birthday !== ''
            ? moment(d?.kid?.birthday).format('DD/MM/YYYY')
            : '',
        memberExpire:
          d?.kid?.memberDetails?.expireDate &&
          d?.kid?.memberDetails?.expireDate !== ''
            ? moment(d?.kid?.memberDetails?.expireDate).format('DD/MM/YYYY')
            : '',
        offDate:
          d?.kid?.memberDetails?.offDate &&
          d?.kid?.memberDetails?.offDate !== ''
            ? moment(d?.kid?.memberDetails?.offDate).format('DD/MM/YYYY')
            : '',
      }
    })

    // createFileExcelReportActiveCases(
    //   JSON.stringify({
    //     kids: mapNumber,
    //     branch: searchQuery?.branch || '',
    //   })
    // ).then((res) => console.log(res))

    createFileExcelReportActiveCases(
      JSON.stringify({
        kids: mapNumber,
        branch: searchQuery?.branch || '',
      })
    ).then(async (i) => {
      const url = i.data.fileUrl
      const fileName = i.data.fileName
      await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${fileName}`)
          document.body.appendChild(link)
          link.click()
          setDownloadIsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setDownloadIsLoading(false)
        })
      setDownloadIsLoading(false)
    })
  }

  return (
    <Box>
      {isOpen && (
        <ModalItems isOpen={isOpen} onClose={onClose} data={modalData} />
      )}
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={5}
          p={5}
        >
          {/* <HStack columns={{ base: 1, md: 4 }} spacing={{ base: 0, md: 10 }}> */}
          <HStack
            spacing={{ base: 0, md: '20px' }}
            flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          >
            <FormControl>
              <FormLabel>Search Name</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) => handleSearch(e)}
                value={searchQuery.text}
                placeholder='Search Name'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Pleas Select branch'
                onChange={(e) =>
                  setSearch({
                    ...searchQuery,
                    branch: e.target.value,
                    type: '',
                  })
                }
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <HStack w='full' justifyContent='space-between'>
                <FormLabel>Date</FormLabel>
                <Checkbox
                  colorScheme='lime'
                  onChange={(e) => {
                    setIsChecked(e.target.checked)
                    if (!e.target.checked) {
                      setSearch({
                        ...searchQuery,
                        date: moment().format('YYYY-MM-DD'),
                      })
                    }
                  }}
                >
                  เลือกช่วงเวลา
                </Checkbox>
              </HStack>
              {isChecked ? (
                <DatePicker.RangePicker
                  allowClear={false}
                  format={'DD MMMM YYYY'}
                  value={[
                    moment(searchQuery?.month[0]),
                    moment(searchQuery?.month[1]),
                  ]}
                  onChange={(date) => {
                    setSearch({
                      ...searchQuery,
                      month: [
                        moment(date[0]).format('YYYY-MM-DD'),
                        moment(date[1]).format('YYYY-MM-DD'),
                      ],
                      text: '',
                    })
                  }}
                />
              ) : (
                <DatePicker
                  allowClear={false}
                  picker='month'
                  format={'MMMM YYYY'}
                  value={moment(searchQuery.month)}
                  onChange={(date) =>
                    setSearch({
                      ...searchQuery,
                      month: moment(date).format('YYYY-MM'),
                      text: '',
                    })
                  }
                />
              )}
            </FormControl>
            <HStack alignSelf={'end'}>
              <IconButton
                variant='outline'
                fontSize={'23px'}
                icon={<FaFileExport color='#8CC63E' />}
                onClick={() => {
                  handleDownloadFile(dataQuery)
                }}
                disabled={
                  downloadIsLoading || !dataQuery || dataQuery?.length === 0
                }
                isLoading={downloadIsLoading}
              />
            </HStack>
          </HStack>
        </Box>
      </form>
      {searchQuery.branch !== '' ? (
        <>
          <Stack
            my={5}
            p={{ base: 2, md: 5 }}
            borderWidth='1px'
            borderColor='brand'
            borderRadius='10px'
            w={{ base: 'full', lg: '30%' }}
          >
            <HStack justifyContent='space-between'>
              <Heading size={{ base: 'sm', md: 'md' }}>Total</Heading>
              <Heading
                size={{ base: 'sm', md: 'md' }}
              >{`${dataQuery?.length?.toLocaleString()}`}</Heading>
            </HStack>
          </Stack>
          <Table
            columnsData={columnsData}
            tableData={dataQuery || []}
            noSearch
            loading={isLoading}
          />
        </>
      ) : (
        <Center>
          <Heading>Please Select Branch</Heading>
        </Center>
      )}
    </Box>
  )
}
