import { useState, useEffect } from 'react'

import {
  Box,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  HStack,
  Button,
  IconButton,
  Input,
  InputGroup,
  useToast,
  Center,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { Popconfirm } from 'antd'
import { search } from 'ss-search'
import { BiArrowBack } from 'react-icons/bi'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

import ColorPickerInput from 'components/ColorPickerInput'

import useCourse from 'hooks/useCourse'

const header = [
  { label: 'Type', isNumeric: false, width: '20%' },
  { label: 'Course Name', isNumeric: false, width: '15%' },
  { label: 'Province', isNumeric: false, width: '15%' },
  { label: 'Program', isNumeric: false, width: '15%' },
  { label: 'Amount', isNumeric: true, width: '10%' },
  { label: 'Unit', isNumeric: false, width: '10%' },
  { label: 'Price', isNumeric: true, width: '10%' },
  { label: 'Color', isNumeric: false, width: '10%' },
  { label: 'Action', isNumeric: false, width: '10%' },
]

export default function Course() {
  const { courses, isLoading, deleteCourse } = useCourse()
  const toast = useToast()
  const [data, setDataQuery] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    setDataQuery(courses)
  }, [courses])

  async function handleDelete(id) {
    try {
      await deleteCourse(id)
      toast({
        position: 'top',
        Header: 'Delete Course',
        description: 'Course has been Deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      toast({
        position: 'top',
        Header: 'Delete Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings')}
        />
      </Flex>
      <VStack spacing={3}>
        <Heading>COURSE</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Link to={'/settings/course/create'}>
            <Button variant='solid' colorScheme='orange' bg='#F6872B'>
              Create Course
            </Button>
          </Link>
        </Flex>
        <Flex justifyContent='flex-start' w={'100%'}>
          <InputGroup size='lg' maxW={350}>
            <Input
              pr='4.5rem'
              placeholder='Search by Name'
              onChange={(e) => {
                let textSearch = e.currentTarget.value
                const searchKeys = ['name']
                const results = search(courses, searchKeys, textSearch)
                const searchResults = textSearch === '' ? courses : results
                setDataQuery(searchResults)
              }}
            />
          </InputGroup>
        </Flex>
        {isLoading ? (
          <Center h='400px' w='full'>
            <div className='custom-loader' />
          </Center>
        ) : !isLoading && data?.length > 0 ? (
          data?.map((course, index) => (
            <TableContainer
              borderWidth={1}
              w={'100%'}
              borderRadius={10}
              key={`table-${index}`}
            >
              <Table>
                <Thead bg='#eee'>
                  <Tr>
                    {header?.map((header) => (
                      <Th
                        style={{
                          width: header.width,
                        }}
                        key={header.label}
                        isNumeric={header.isNumeric}
                      >
                        {header.label}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {course?.programs?.length === 0 ? (
                    <Tr key={index}>
                      <Td>{course.type}</Td>
                      <Td>{course.name}</Td>
                      <Td>{data.choice}</Td>
                      <Td isNumeric>{data.amount}</Td>
                      <Td>{data.unit}</Td>
                      <Td isNumeric></Td>
                      <Td></Td>
                      <Td>
                        <HStack justifyContent='space-between'>
                          <HStack spacing={2}>
                            <Link to={`/settings/course/edit/${course.id}`}>
                              <IconButton
                                aria-label='edit'
                                icon={
                                  <AiFillEdit color='#8CC63E' fontSize='25px' />
                                }
                              />
                            </Link>
                            <Popconfirm
                              title='Sure to delete this Course?'
                              onConfirm={() => {
                                handleDelete(course.id)
                              }}
                            >
                              <IconButton
                                aria-label='delete'
                                icon={
                                  <AiFillDelete color='red' fontSize='25px' />
                                }
                              />
                            </Popconfirm>
                          </HStack>
                        </HStack>
                      </Td>
                    </Tr>
                  ) : (
                    course?.programs?.map((data, index) => (
                      <Tr key={index}>
                        <Td>{index === 0 && course.type}</Td>
                        <Td>{index === 0 && course.name}</Td>
                        <Td>
                          {index === 0 && course.province
                            ? course.province === 'bk'
                              ? `BK (กรุงเทพ)`
                              : course.province === 'cm'
                              ? `CM (เชียงใหม่)`
                              : 'ทังหมด'
                            : ''}
                        </Td>

                        <Td>{data.choice}</Td>
                        <Td isNumeric>{data.amount}</Td>
                        <Td>{data.unit}</Td>
                        <Td isNumeric>
                          {data.price
                            ? `${Number(data.price).toLocaleString(
                                'th-TH'
                              )} บาท`
                            : ''}
                        </Td>
                        <Td>
                          <ColorPickerInput value={data.color} disable />
                        </Td>

                        <Td>
                          {index === 0 && (
                            <HStack justifyContent='space-between'>
                              <HStack spacing={2}>
                                <Link to={`/settings/course/edit/${course.id}`}>
                                  <IconButton
                                    variant='ghost'
                                    aria-label='edit'
                                    icon={
                                      <AiFillEdit
                                        color='brand'
                                        fontSize='25px'
                                      />
                                    }
                                  />
                                </Link>
                                <Popconfirm
                                  title='Sure to delete this Course?'
                                  onConfirm={() => {
                                    handleDelete(course.id)
                                  }}
                                >
                                  <IconButton
                                    variant='ghost'
                                    aria-label='delete'
                                    icon={
                                      <AiFillDelete
                                        color='red'
                                        fontSize='25px'
                                      />
                                    }
                                  />
                                </Popconfirm>
                              </HStack>
                            </HStack>
                          )}
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          ))
        ) : (
          !isLoading && (
            <Center
              borderWidth='1px'
              borderColor='brand'
              borderRadius='10px'
              h='200px'
              w='full'
              my={10}
              p={5}
            >
              <Heading color={'brand'}>No data available</Heading>
            </Center>
          )
        )}
      </VStack>
    </Box>
  )
}
