import Header from 'components/Header'
import Sidebar from 'components/Sidebar'

import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react'

export default function Layout({ children, user }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box
      minHeight='100vh'
      height='100%'
      overflow='auto'
      position='relative'
      maxHeight='100%'
      transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
      transitionDuration='.2s, .2s, .35s'
      transitionProperty='top, bottom, width'
      transitionTimingFunction='linear, linear, ease'
    >
      <Sidebar
        onClose={() => onClose}
        user={user}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <Sidebar onClose={onClose} user={user} />
        </DrawerContent>
      </Drawer>
      {/*= Header =*/}
      <Header onOpen={onOpen} user={user} />
      <Box
        float='right'
        minHeight='100vh'
        height='100%'
        overflow='auto'
        position='relative'
        maxHeight='100%'
        w={{ base: '100%', md: 'calc( 100% - 90px )' }}
        maxWidth={{ base: '100%', md: 'calc( 100% - 90px )' }}
        transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
        transitionDuration='.2s, .2s, .35s'
        transitionProperty='top, bottom, width'
        transitionTimingFunction='linear, linear, ease'
      >
        <Box mx='auto' minH='94vh' pt='70px'>
          {children}
        </Box>
      </Box>
    </Box>
  )
}
