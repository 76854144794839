import { useState } from 'react'
import {
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Popconfirm } from 'antd'
import { AiFillEdit, AiFillDelete, AiFillCaretDown } from 'react-icons/ai'
import Table from 'components/Horizons/table'
import SpinnerOverlay from 'components/SpinnerOverlay'

import BankModal from './Modal/bank'
import CashModal from './Modal/cash'
import usePayment from 'hooks/usePayment'
import useBranch from 'hooks/useBranch'

export default function BracnhPayment({ user }) {
  const { paymentTypes, isLoading, deletePaymentType } = usePayment()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { branches } = useBranch()
  const {
    isOpen: isOpenCash,
    onOpen: onOpenCash,
    onClose: onCloseCash,
  } = useDisclosure()
  const [cash, setCash] = useState()
  const [bank, setBank] = useState()
  const toast = useToast()
  const [submitting, setSubmitting] = useState(false)

  async function handleDelete(id) {
    setSubmitting(true)
    try {
      await deletePaymentType(id)
      toast({
        position: 'top',
        Header: 'Delete Payment',
        description: 'Payment has been Deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      toast({
        position: 'top',
        Header: 'Delete Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setSubmitting(false)
    }
  }

  const columns = [
    {
      Header: 'สาขา',
      accessor: 'branch',
      key: 'branch',
      width: '20%',
      extra: (data, row) => {
        const branch = branches.find((b) => b.id === row.branchId)
        return (
          <Text color={'black'} fontSize='sm'>
            {`${branch ? branch?.name : data}`}
          </Text>
        )
      },
    },
    {
      Header: 'ช่องทางชำระเงิน',
      accessor: 'type',
      key: 'type',
      width: '20%',
      extra: (data, row) => {
        return (
          <Text color={'black'} fontSize='sm'>
            {`${data === 'bank' ? 'บัญชีธนาคาร' : 'เงินสด'}`}
          </Text>
        )
      },
    },
    {
      Header: 'ชื่อบัญชี',
      accessor: 'name',
      key: 'name',
      width: '20%',
      extra: (data, row) => {
        if (row?.type === 'bank') {
          return (
            <VStack align='start' color={'black'} fontSize='sm'>
              <Text>{`${row?.bankName} ${row?.bankAccountNo}`}</Text>
              <Text>{`${row?.accountName}`}</Text>
            </VStack>
          )
        } else {
          return <Text color={'black'} fontSize='sm'>{`${data}`}</Text>
        }
      },
    },
    {
      Header: 'รายละเอียดเพิ่มเติม',
      accessor: 'description',
      key: 'description',
      width: '20%',
      extra: (data, row) => {
        if (row?.type === 'bank') {
          const branch = branches.find((b) => b.id === row.branchId)
          return (
            <VStack align='start' color={'black'} fontSize='sm'>
              <Text>{`ประเภทบัญชี : ${row?.accountType}`}</Text>
              <Text>{`สาขา : ${branch?.name || row.branch}`}</Text>
            </VStack>
          )
        } else {
          return <Text color={'black'} fontSize='sm'>{`${data}`}</Text>
        }
      },
    },
    {
      Header: 'Action',
      accessor: 'Action',
      key: 'Action',
      width: '10%',
      extra: (data, row) => {
        return (
          <HStack justifyContent='space-between'>
            <HStack spacing={2}>
              <IconButton
                variant='ghost'
                aria-label='edit'
                icon={<AiFillEdit color='#8CC63E' fontSize='25px' />}
                onClick={() => {
                  if (row.type === 'bank') {
                    setBank(row)
                    onOpen()
                  } else {
                    setCash(row)
                    onOpenCash()
                  }
                }}
              />
              <Popconfirm
                title='Sure to delete this Payment'
                onConfirm={() => {
                  handleDelete(row.id)
                }}
              >
                <IconButton
                  variant='ghost'
                  aria-label='delete'
                  icon={<AiFillDelete color='red' fontSize='25px' />}
                />
              </Popconfirm>
            </HStack>
          </HStack>
        )
      },
    },
  ]
  // eslint-disable-next-line

  return (
    <>
      <VStack>
        <SpinnerOverlay loading={submitting} />

        {isOpen && (
          <BankModal
            isOpen={isOpen}
            onClose={() => {
              onClose()
              setBank(null)
            }}
            data={bank}
            branches={branches}
            user={user}
          />
        )}
        {isOpenCash && (
          <CashModal
            isOpen={isOpenCash}
            onClose={() => {
              onCloseCash()
              setCash(null)
            }}
            data={cash}
            branches={branches}
            user={user}
          />
        )}

        <Heading>PAYMENTS</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<AiFillCaretDown />}
              variant='solid'
              colorScheme='orange'
              bg='#F6872B'
            >
              Create Payment
            </MenuButton>
            <MenuList justifyContent='center' alignItems='center'>
              <MenuItem textAlign='center' onClick={onOpen}>
                บัญชีธนาคาร
              </MenuItem>
              <MenuItem textAlign='center' onClick={onOpenCash}>
                เงินสด
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </VStack>
      <Table
        columnsData={columns}
        tableData={paymentTypes}
        loading={isLoading}
      />
    </>
  )
}
