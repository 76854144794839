/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import moment from 'moment'

import useStaff from 'hooks/useStaff'

import FormInputs from 'components/DynamicForm'
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore'
import { db } from 'config/firebase'

function StaffModal({ isOpen, onClose, event, date }) {
  const { staff, skipDayOff } = event
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm()
  const { staffs } = useStaff()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)

  const startTime = watch('timeStart')
  const selectedDate = watch('date')
  const selectedStaff = watch('staff')
  const isAllday = watch('isAllDay')

  useEffect(() => {
    if (isAllday === 'true') {
      setValue('timeEnd', '20.00')
      setValue('timeStart', '09.00')
    }
  }, [isAllday])

  async function submitData(data) {
    setLoading(true)

    try {
      const staff = staffs.find((item) => item.id === data.staff)

      if (data.isAllDay && skipDayOff) {
        await deleteDoc(doc(db, 'Schedules', event?.scheduleId))
      } else {
        await addDoc(collection(db, 'Schedules'), {
          ...data,
          staff,
          isAllDay: data.isAllDay === 'true',
          program: { courseName: '', choice: 'holiday' },
          isSkipDayOff: false,
          createdAt: new Date(),
        })
      }

      toast({
        position: 'top',
        Header: 'Update Schedule',
        description: 'Schedule has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      onClose()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Add Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const corseCheckedForm = (staffs) => [
    {
      label: `ครูผู้สอน`,
      name: 'staff',
      type: 'dropdown',
      options:
        staffs.map((item) => ({
          value: item.id,
          data: item,
          label: `${item.nickname} (${item.fullname})`,
        })) || [],
      placeholder: '',
      defaultValue: staff.id,
      required: true,
      disabled: true,
    },
    {
      label: 'วันที่',
      name: 'date',
      type: 'date',
      placeholder: '',
      defaultValue: moment(date).format('yyyy-MM-DD'),
      required: true,
      readonly: true,
    },
    {
      label: 'ลาเต็มวัน',
      name: 'isAllDay',
      type: 'radio',
      options: [
        { value: 'false', label: 'ไม่ใช่' },
        { value: 'true', label: 'ใช่' },
      ],
      defaultValue: 'false',
    },
  ]

  const corseTimeForm = (Time) => [
    {
      label: `เริ่ม`,
      name: 'timeStart',
      type: 'dropdown',
      options: Time,
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: !selectedStaff || !selectedDate,
    },
    {
      label: `สิ้นสุด`,
      name: 'timeEnd',
      type: 'dropdown',
      options: Time,
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: !selectedStaff || !selectedDate || !startTime,
    },
  ]

  let Time = useMemo(() => {
    if (selectedStaff && selectedStaff !== '') {
      const staff = staffs.find((item) => item.id === selectedStaff)
      const isWeekend = new Date(date).getDay() % 6 === 0
      if (staff) {
        const {
          startTimeWeekend,
          startTimeWorkday,
          endTimeWeekend,
          endTimeWorkday,
        } = staff

        let start = isWeekend ? startTimeWeekend : startTimeWorkday
        let end = isWeekend ? endTimeWeekend : endTimeWorkday

        return [...Array(48)]
          .map((e, i) => {
            return (
              (i / 2 < 10 ? '0' : '') +
              (i / 2 - ((i / 2) % 1)) +
              ((i / 2) % 1 !== 0 ? '.30' : '.00')
            )
          })
          .filter(
            (time) =>
              Number(time) >= (isWeekend ? 9 : 13) &&
              Number(time) <= (isWeekend ? 20 : 20)
          )
          .filter(
            (time) =>
              Number(time) >= Number(start.replace(':', '.')) &&
              Number(time) <= Number(end.replace(':', '.'))
          )
      }
    }
  }, [selectedStaff, staffs])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Update Staff Holiday</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={corseCheckedForm(staffs) || []}
            data={null}
          />
          {isAllday === 'false' && (
            <FormInputs
              errors={errors}
              control={control}
              forms={corseTimeForm(Time) || []}
              data={null}
            />
          )}
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submitData)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default StaffModal
