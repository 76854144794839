import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import usePayment from 'hooks/usePayment'

import SpinnerOverlay from 'components/SpinnerOverlay'
import FormInputs from 'components/DynamicForm'
import { bankForm } from '../fields'

export default function BankModal({ isOpen, onClose, data, branches, user }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const { addPaymentType, updatePaymentType } = usePayment()
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function submit(values) {
    if (data?.id) {
      update(values)
    } else {
      create(values)
    }
  }
  function create(values) {
    try {
      setLoading(true)
      let branch = branches.find((branch) => branch?.id === values.branchId)
      addPaymentType({
        ...values,
        branch: branch?.name,
        branchId: branch?.id,
        type: 'bank',
        createdAt: new Date(),
        createdBy: user?.uid,
      })
      toast({
        position: 'top',
        Header: 'Create Bank Payment',
        description: 'Bank Payment has been created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      onClose()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function update(values) {
    try {
      setLoading(true)
      let branch = branches.find((branch) => branch?.id === values.branchId)
      await updatePaymentType(data.id, {
        ...values,
        branch: branch?.name,
        branchId: branch?.id,
        updatedAt: new Date(),
        updatedBy: user?.uid,
      })
      toast({
        position: 'top',
        Header: 'Update Bank Payment',
        description: 'Bank Payment has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      onClose()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <SpinnerOverlay loading={isLoading} />
      <ModalContent>
        <ModalHeader textAlign='center'>เพิ่มบัญชีธนาคาร</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={
              bankForm(
                branches?.map((branch) => ({
                  value: branch.id,
                  label: branch?.name,
                }))
              ) || []
            }
            data={data}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
