/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { db } from 'config/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import moment from 'moment'

import FormInputs from 'components/DynamicForm'

const activeForm = [
  {
    label: `Return Date`,
    name: 'returnDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: `New Expire Date`,
    name: 'newExpireDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    required: true,
    readonly: true,
  },
]

export default function ReActiveModal({ isOpen, onClose, data, kidId }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    setValue,
  } = useForm()
  const returnDate = watch('returnDate')

  useEffect(() => {
    if (returnDate && returnDate !== '') {
      const offDays = Math.abs(
        moment(data?.offDate, 'YYYY-MM-DD').diff(
          moment(returnDate, 'YYYY-MM-DD'),
          'days'
        )
      )
      const newExpireDate = moment(data?.expireDate.toDate())
        .add(offDays, 'day')
        .format('YYYY-MM-DD')
      setValue('newExpireDate', newExpireDate)
    }
  }, [returnDate])

  function submit(values) {
    update(values)
  }

  function update(values) {
    setLoading(true)
    updateDoc(
      doc(db, `Kids/${kidId}/Status`, data.id),
      {
        ...values,
        returnDate: new Date(moment(values.returnDate, 'YYYY-MM-DD')),
        expireDate: new Date(moment(values.newExpireDate, 'YYYY-MM-DD')),
        reason: '',
        status: 'member',
      },
      { merge: true }
    )
      .then(() =>
        updateDoc(
          doc(db, `Kids`, kidId),
          {
            status: 'member',
            expireDate: new Date(moment(values.newExpireDate, 'YYYY-MM-DD')),
            memberDetails: {
              ...values,
              reason: '',
              returnDate: new Date(moment(values.returnDate, 'YYYY-MM-DD')),
              expireDate: new Date(moment(values.newExpireDate, 'YYYY-MM-DD')),
              status: 'member',
            },
          },
          { merge: true }
        )
      )
      .then(() => {
        toast({
          position: 'top',
          Header: 'Update Kid Active',
          description: 'Kid Active has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Update Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Active Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={activeForm || []}
            data={data}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
