const courseForm = [
  {
    label: 'Province',
    name: 'province',
    type: 'dropdown',
    defaultValue: 'all',
    options: [
      { label: 'All (ทั้งหมด)', value: 'all' },
      { label: 'BK (กรุงเทพ)', value: 'bk' },
      { label: 'CM (เชียงใหม่)', value: 'cm' },
    ],
    required: true,
  },
  {
    label: 'Type',
    name: 'type',
    type: 'dropdown',
    options: [
      'Evaluation',
      'Camp',
      'Occupational therapy (OT)',
      'Special education (SE)',
      'Speech therapy (ST)',
      'Extra program',
    ],
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Course Name',
    name: 'name',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Description',
    name: 'description',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    required: false,
  },
  {
    label: 'Hours',
    name: 'hours',
    type: 'number',
    placeholder: '',
    defaultValue: 1,
    required: true,
  },
  {
    label: 'Score',
    name: 'score',
    type: 'number',
    placeholder: '',
    defaultValue: 1,
    required: true,
  },
]

const programForm = [
  {
    label: 'Choice',
    name: 'choice',
    type: 'text',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Amount',
    name: 'amount',
    type: 'number-format',
    placeholder: 1,
    required: true,
  },
  {
    label: 'Price',
    name: 'price',
    type: 'number-format',
    placeholder: 0,
    required: true,
  },
  {
    label: 'Color',
    name: 'color',
    type: 'color',
    defaultValue: '#FFF',
    required: true,
  },
]

export { courseForm, programForm }
