import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'

import FormInputs from 'components/DynamicForm'
import { adminForm as form } from '../fields'
import SpinnerOverlay from 'components/SpinnerOverlay'

import useStaff from 'hooks/useStaff'

export default function AdminModal({
  isOpen,
  onClose,
  data,
  branches,
  allBranch,
}) {
  const { updateAccount, createAccount } = useStaff()
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()

  function submit(values) {
    if (data?.id) {
      updateStaffStaff(values)
    } else {
      createAdminAccount(values)
    }
  }

  async function createAdminAccount(values) {
    let branch = allBranch.find((branch) => branch?.name === values.branch)
    setLoading(true)
    try {
      await createAccount({
        ...values,
        role: values.role,
        branch: branch.name,
        branchId: branch.id,
      })
      toast({
        position: 'top',
        Header: 'Create Account',
        description: 'Manager has been created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      onClose()
      setLoading(false)
      resetField()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function updateStaffStaff(values) {
    try {
      let branch = allBranch.find((branch) => branch?.name === values.branch)
      setLoading(true)

      await updateAccount({
        ...data,
        ...values,
        id: data.id,
        branch: branch.name,
        branchId: branch.id,
        role: values.role,
      })
      toast({
        position: 'top',
        Header: 'Update Admin',
        description: 'Manager has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      onClose()
      setLoading(false)
      resetField()
    } catch (e) {
      toast({
        position: 'top',
        Header: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <>
      <SpinnerOverlay loading={isLoading} />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'lg'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>
            {data?.id ? 'Edit' : 'Create'} Manager
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            <FormInputs
              errors={errors}
              control={control}
              forms={branches || []}
              data={data}
            />
            <FormInputs
              errors={errors}
              control={control}
              forms={form || []}
              data={data}
            />
            <FormControl my={4} w={'100%'} isInvalid={errors.role}>
              <FormLabel>Role</FormLabel>
              <Controller
                name='role'
                control={control}
                defaultValue={data?.role}
                rules={{
                  required: `This field is required`,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      colorScheme='lime'
                      size='md'
                      onChange={onChange}
                      value={value}
                      placeholder='Please Select role'
                    >
                      <option value='Manager'>Manager</option>
                      <option value='Owner'>Owner</option>
                      <option value='CoAdmin'>Co Admin</option>
                    </Select>
                  )
                }}
              />
              <FormErrorMessage>
                {errors.role && errors.role.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button mr={3} variant='outline' onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme='lime'
              onClick={handleSubmit(submit)}
              bg='brand'
              isLoading={isLoading}
              loadingText='Submitting'
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
