import { useState, useEffect, useMemo } from 'react'
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table as ChakraTable,
  Tbody,
  Td,
  Text,
  Tr,
  Thead,
  Th,
  useDisclosure,
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Heading,
  Stack,
  HStack,
  Checkbox,
  IconButton,
  Tag,
  TagLabel,
  Skeleton,
  Grid,
  GridItem,
  VStack,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import { search } from 'ss-search'
import moment from 'moment/moment'
import useReport from 'hooks/useReport'
import Table from 'components/Horizons/table'
import { FaFileExport } from 'react-icons/fa'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'config/firebase'
import axios from 'axios'

const createFileExcel = httpsCallable(functions, 'createReportRefund')

function ModalItems({ onClose, isOpen, data }) {
  const { kid, programs, completed } = data
  return (
    <Modal
      size='8xl'
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader fontWeight='normal'></ModalHeader>
        <ModalCloseButton />
        <ModalBody p={{ base: '5px', sm: '5px', md: '15px' }} overflowY='auto'>
          <Heading size='lg' textAlign='center'>{`${kid?.name} ${
            kid?.nickname ? `(${kid?.nickname})` : ''
          }`}</Heading>
          <Heading size='lg' textAlign='center'>{`${kid?.code}`}</Heading>
          <Heading px={5} size='md' my={5}>
            รายการชำระ
          </Heading>
          <Box p='0.5rem' border='1px solid #E2E8F0' my={5}>
            <ChakraTable
              pt='0px'
              mt='0px'
              size={{ base: 'sm' }}
              overflowX='auto'
            >
              <Thead bg='#E0E1E2'>
                <Tr>
                  <Th textAlign='start' color='black' w='15%'>
                    <Text>โปรแกรม</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>ใช้แล้ว</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>ราคา</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>ยอดชำระปัจจุบัน</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>ที่ชำระแล้วทั้งหมด</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>ค้างชำระ</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>คืนเงิน</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>วิธีการชำระ</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>เลขที่เอกสาร</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>วันที่ชำระ</Text>
                  </Th>
                  <Th textAlign='start' color='black'>
                    <Text>Tag</Text>
                  </Th>
                  <Th textAlign='center' color='black'>
                    <Text>ข้อมูลชำระค้างล่าสุด</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {programs?.map((p, i) => {
                  const completedProgram = completed.find((c) => c.id === p.id)
                  const paid =
                    p.collection === 'Status' &&
                    completedProgram &&
                    completedProgram?.isWaive
                      ? 'Member Waive'
                      : p?.price
                      ? p?.price
                      : ''
                  const indexOftransaction = p.transactions.findIndex(
                    (t) => t?.trRef === p?.trRef
                  )
                  const totalPaidToIndex = p.transactions.reduce(
                    (a, b, index) => {
                      if (index > indexOftransaction) return a
                      return b.action === 'paid'
                        ? a + Number(b.paid)
                        : b.action === 'refund'
                        ? a - Number(b.paid)
                        : a
                    },
                    0
                  )

                  return (
                    <Tr key={i}>
                      <Td textAlign='start'>
                        <Text>{p.description}</Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>
                          {p.collection === 'Programs' &&
                          completedProgram &&
                          completedProgram?.amount
                            ? `${completedProgram?.completed} / ${completedProgram?.amount}`
                            : '-'}
                        </Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>{p?.fullPrice?.toLocaleString()}</Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>
                          {' '}
                          {data.payment && data.payment !== 'refund'
                            ? Number(paid).toLocaleString()
                            : '-'}
                        </Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>{Number(totalPaidToIndex).toLocaleString()}</Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>
                          {Number(
                            p?.fullPrice - completedProgram?.totalPaid || 0
                          ).toLocaleString() || '0'}
                        </Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>
                          {data.payment && data.payment === 'refund'
                            ? Number(paid).toLocaleString()
                            : 0}
                        </Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>
                          {data.payment && data.payment === 'refund'
                            ? 'คืนเงิน'
                            : data.payment ?? 'ยังไม่ได้ชำระ'}
                        </Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>{data.ref}</Text>
                      </Td>
                      <Td textAlign='start'>
                        <Text>
                          {moment(data.paymentDate, 'YYYY-MM-DD').format(
                            'DD/MM/YYYY'
                          )}
                        </Text>
                      </Td>
                      <Td textAlign='start'>
                        {RenderTags(p.tag, p?.isPaidAfterRemaining)}
                      </Td>
                      <Td>
                        {p?.isPaidAfterRemaining &&
                        (p.tag === 'remaining' || p.tag === 'completed') ? (
                          <VStack px={0} mx={0} alignItems='start'>
                            <Text>{p?.isPaidAfterRemaining?.ref}</Text>
                            <Text>
                              วันที่ชำระ :{' '}
                              {p?.isPaidAfterRemaining?.paymentDate}
                            </Text>
                          </VStack>
                        ) : p?.isPaidAfterSystemCreatedPayment ? (
                          <Text>
                            วันที่ค้างชำระ :{' '}
                            {p?.isPaidAfterSystemCreatedPayment?.paymentDate}
                          </Text>
                        ) : (
                          ''
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </ChakraTable>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const RenderTags = (tags, isPaidAfterRemaining) => {
  let tag

  switch (tags) {
    case 'prepaid':
      tag = (
        <Tag bg='#01AEF0' color='white' borderRadius='full'>
          <TagLabel>ชำระล่วงหน้า</TagLabel>
        </Tag>
      )
      break
    case 'completed':
      tag = (
        <Tag bg='#8CC63E' color='white' borderRadius='full'>
          <TagLabel>ชำระครบ</TagLabel>
        </Tag>
      )
      break
    case 'remaining':
      tag = (
        <Tag bg='#FFC20F' color='white' borderRadius='full'>
          <TagLabel>ค้างชำระ</TagLabel>
        </Tag>
      )
      break
    case 'clear':
      tag = (
        <Tag bg='gray' color='white' borderRadius='full'>
          <TagLabel>เคลียร์ยอด</TagLabel>
        </Tag>
      )
      break
    case 'refund':
      tag = (
        <Tag bg='purple' color='white' borderRadius='full'>
          <TagLabel>คืนเงิน</TagLabel>
        </Tag>
      )
      break

    default:
      return
  }

  if (isPaidAfterRemaining && tags === 'remaining')
    tag = (
      <Tag bg='gray' color='lime' borderRadius='full'>
        <TagLabel>ชำระค้าง</TagLabel>
      </Tag>
    )
  return tag
}

export function Refund({ branches, managerBranchId, role }) {
  const calculateTotalPrice = (list) => {
    return list.reduce((acc, item) => acc + Number(item.price), 0)
  }
  const columnsData = [
    {
      Header: 'วันที่สร้าง',
      accessor: 'createdAt',
      width: '12%',
      extra: (date) => {
        return moment(date.toDate()).format('LLL')
      },
    },
    {
      Header: 'รหัส',
      accessor: 'kid.code',
      width: '8%',
    },
    {
      Header: 'ชื่อ-นามสกุล',
      accessor: 'kid.name',
      width: '10%',
    },
    {
      Header: 'ชื่อเล่น',
      accessor: 'kid.nickname',
      width: '8%',
    },

    {
      Header: 'ยอดคืนเงิน',
      accessor: 'totalPrice',
      width: '10%',
      extra: (_, row) => {
        const data = calculateTotalPrice(row.programs)
        return `${data?.toLocaleString()} บาท`
      },
    },

    {
      Header: 'Action',
      accessor: 'kid',
      width: '10%',
      extra: (data, row) => (
        <Button
          variant='link'
          textDecoration='underline'
          onClick={() => {
            setModalData(row)
            onOpen()
          }}
        >
          รายละเอียด
        </Button>
      ),
    },
  ]
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || '',
    type: '',
    date: moment().format('YYYY-MM-DD'),
    tag: 'refund',
  })
  const [modalData, setModalData] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const [downloadIsLoading, setDownloadIsLoading] = useState(false)

  const { onOpen, isOpen, onClose } = useDisclosure()
  const { reportRevenue, isLoading } = useReport(
    searchQuery?.date,
    searchQuery?.branch,
    'revenue'
  )
  const [dataQuery, setDataQuery] = useState([])

  async function handleDownloadFile(dataDownload) {
    setDownloadIsLoading(true)
    const findBranch = branches.find((b) => b.id === searchQuery.branch)
    const mapDateData = dataDownload.map((da) => ({
      ...da,
      createdAt: moment(da.createdAt.toDate()).format('DD/MM/YYYY'),
    }))

    createFileExcel(
      JSON.stringify({ data: mapDateData, branch: findBranch || '' })
    ).then(async (i) => {
      const url = i.data.fileUrl
      const fileName = i.data.fileName

      await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}`)
        document.body.appendChild(link)
        link.click()
      })
      setDownloadIsLoading(false)
    })
  }

  const sumReport = useMemo(() => {
    const sum = dataQuery?.reduce((sum, data) => {
      const total = calculateTotalPrice(data.programs)
      if (data.type === 'refund') {
        return sum + total
      }
      return sum
    }, 0)

    return {
      sum: sum || 0,
    }
  }, [dataQuery])

  useEffect(() => {
    if (reportRevenue) {
      const filterReciept = reportRevenue
        .filter((r) => r.type !== 'reciept')
        .filter((s) => {
          return s.programs.some((p) => p.tag === 'refund')
        })
        .map((s) => {
          const filteredProgramsTag = s.programs.filter(
            (p) => p.tag === 'refund'
          )
          return {
            ...s,
            programs: filteredProgramsTag,
          }
        })
      setDataQuery(filterReciept)
    }
  }, [reportRevenue])

  function handleSearch(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, text: e.target.value })
    let textSearch = e.target.value
    const searchQueryArr = reportRevenue
    const searchKeys = ['kid.name', 'kid.nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      searchQuery.type !== ''
        ? searchResults.filter((s) => {
            return s.type === searchQuery.type
          })
        : searchResults
    const filteredTag =
      'refund' !== ''
        ? filteredResults
            .filter((s) => {
              return s.programs.some((p) => p.tag === 'refund')
            })
            .map((s) => {
              const filteredProgramsTag = s.programs.filter(
                (p) => p.tag === 'refund'
              )
              return {
                ...s,
                programs: filteredProgramsTag,
              }
            })
        : filteredResults
    setDataQuery(filteredTag)
  }

  return (
    <Box>
      {isOpen && (
        <ModalItems isOpen={isOpen} onClose={onClose} data={modalData} />
      )}
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={5}
          p={5}
        >
          <SimpleGrid spacing={{ base: 0, md: '10px' }} minChildWidth='200px'>
            <FormControl>
              <FormLabel>Search Name</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) => handleSearch(e)}
                value={searchQuery.text}
                placeholder='Search Name'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Pleas Select branch'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {/* {role === 'Owner' && <option value='all'>All Branches</option>} */}
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <HStack w='full' justifyContent='space-between'>
                <FormLabel>Date</FormLabel>
                <Checkbox
                  colorScheme='lime'
                  onChange={(e) => {
                    setIsChecked(e.target.checked)
                    if (!e.target.checked) {
                      setSearch({
                        ...searchQuery,
                        date: moment().format('YYYY-MM-DD'),
                      })
                    }
                  }}
                >
                  เลือกช่วงเวลา
                </Checkbox>
              </HStack>
              {isChecked ? (
                <DatePicker.RangePicker
                  allowClear={false}
                  format={'DD MMMM YYYY'}
                  onChange={(date) => {
                    setSearch({
                      ...searchQuery,
                      date: [date[0], date[1]],
                    })
                  }}
                />
              ) : (
                <DatePicker
                  allowClear={false}
                  format={'DD MMMM YYYY'}
                  defaultValue={moment(searchQuery.date)}
                  onChange={(date) =>
                    setSearch({
                      ...searchQuery,
                      date: moment(date).format('YYYY-MM-DD'),
                    })
                  }
                />
              )}
            </FormControl>
          </SimpleGrid>
          <HStack alignSelf={'end'} justifyContent='flex-end' w='full' mt={3}>
            <IconButton
              variant='outline'
              fontSize={'23px'}
              icon={<FaFileExport color='#8CC63E' />}
              onClick={() => {
                handleDownloadFile(dataQuery)
              }}
              disabled={downloadIsLoading || !dataQuery.length > 0}
              isLoading={downloadIsLoading}
            />
          </HStack>
        </Box>
      </form>
      {searchQuery.branch !== '' ? (
        <>
          <Grid gap={2} mb={5} templateColumns='repeat(4, 1fr)'>
            <GridItem colSpan={{ base: 4, md: 2, lg: 2 }}>
              <Stack
                p={{ base: 3, md: 4 }}
                borderWidth='1px'
                borderColor='grey'
                borderRadius='10px'
              >
                <HStack justifyContent='space-between'>
                  <Heading size={{ base: 'sm', md: 'md' }}>จำนวนเอกสาร</Heading>
                  <Skeleton isLoaded={!isLoading}>
                    <Heading size={{ base: 'sm', md: 'md' }}>
                      {dataQuery.length}
                    </Heading>
                  </Skeleton>
                </HStack>
              </Stack>
            </GridItem>

            <GridItem colSpan={{ base: 4, md: 4, lg: 2 }}>
              <Stack
                p={{ base: 3, md: 4 }}
                borderWidth='1px'
                borderColor='#01AEF0'
                borderRadius='10px'
              >
                <HStack justifyContent='space-between'>
                  <Heading size={{ base: 'sm', md: 'md' }}>
                    ยอดคืนเงินทั้งหมด
                  </Heading>
                  <Skeleton isLoaded={!isLoading}>
                    <Heading
                      size={{ base: 'sm', md: 'md' }}
                    >{`${(sumReport?.sum).toLocaleString()} บาท`}</Heading>
                  </Skeleton>
                </HStack>
              </Stack>
            </GridItem>
          </Grid>
          <Table
            columnsData={columnsData}
            tableData={dataQuery}
            noSearch
            loading={isLoading}
          />
        </>
      ) : (
        <Center>
          <Heading>Please Select Branch</Heading>
        </Center>
      )}
    </Box>
  )
}
