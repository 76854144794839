/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import FormInputs from 'components/DynamicForm'
import { functions } from 'config/firebase'
import { httpsCallable } from 'firebase/functions'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

const updateKidCodeCounter = httpsCallable(functions, 'updateKidCodeCounter')

export default function MemberModal({
  isOpen,
  onClose,
  data,
  kidId,
  scheduleId,
  branches,
  managerBranchId,
  user,
  kidStatus,
}) {
  const [isLoading, setLoading] = useState(false)
  const [status] = useState('member')
  const toast = useToast()
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    setValue,
  } = useForm()

  const activeDate = watch('activeDate')
  const selectedBranch = watch('branch')
  const [isWaive, setIsWaive] = useState(false)
  useEffect(() => {
    if (selectedBranch !== '') {
      const selectBranch = selectedBranch
        ? branches.find((branches) => branches.id === selectedBranch)
        : branches.find((branches) => branches.id === data.branchId)

      const code = String(
        selectBranch?.counters ? selectBranch?.counters + 1 : 1
      ).padStart(4, '0')

      setValue(
        'code',
        kidStatus === 'off' ? data?.code : `${selectBranch?.code}${code}`
      )
      setValue('price', selectBranch?.memberPrice && selectBranch?.memberPrice)
    } else {
      setValue('code', '')
    }
  }, [selectedBranch])

  useEffect(() => {
    if (activeDate) {
      setValue(
        'expireDate',
        moment(activeDate).add(1, 'years').format('YYYY-MM-DD')
      )
    }
  }, [activeDate])

  useEffect(() => {
    if (isWaive) {
      setValue('price', '0')
    } else {
      const selectBranch = selectedBranch
        ? branches.find((branches) => branches.id === selectedBranch)
        : branches.find((branches) => branches.id === data.branchId)
      setValue('price', selectBranch?.memberPrice && selectBranch?.memberPrice)
    }
  }, [isWaive, selectedBranch])

  function submit(values) {
    create(values)
  }
  async function create(values) {
    setLoading(true)

    // const obj = branches.find((branches) => branches.id === values?.branchId)

    // const selectBranch = JSON.parse(JSON.stringify(obj))

    // addDoc(collection(db, `Kids/${kidId}/Status`), {
    //   ...values,
    //   activeDate: new Date(moment(values.activeDate, 'YYYY-MM-DD')),
    //   expireDate: new Date(moment(values.expireDate, 'YYYY-MM-DD')),
    //   createdAt: new Date(),
    //   createdBy: user.uid,
    //   status: status,
    //   isWaive,
    // })
    //   .then(() =>
    //     updateDoc(
    //       doc(db, `Kids`, kidId),
    //       {
    //         status,
    //         code: values.code,
    //         branch: selectBranch,
    //         branchId: selectBranch?.id,
    //         memberDetails: {
    //           ...values,
    //           activeDate: new Date(moment(values.activeDate, 'YYYY-MM-DD')),
    //           expireDate: new Date(moment(values.expireDate, 'YYYY-MM-DD')),
    //           status,
    //           createdAt: new Date(),
    //           createdBy: user.uid,
    //           isWaive,
    //         },
    //       },
    //       { merge: true }
    //     )
    //   )
    //   .then(() =>
    //     updateDoc(doc(db, `Schedules`, scheduleId), {
    //       isTaked: true,
    //       updatedAt: new Date(),
    //       updatedBy: user.uid,
    //     })
    //   )
    //   .then(() =>
    //     addDoc(collection(db, `Schedules/${scheduleId}/Logs`), {
    //       ...values,
    //       action: 'upgradetoMember',
    //       kidId,
    //       createdAt: new Date(),
    //       createdBy: user.uid,
    //       activeDate: new Date(moment(values.activeDate, 'YYYY-MM-DD')),
    //       expireDate: new Date(moment(values.expireDate, 'YYYY-MM-DD')),
    //       status: status,
    //       isWaive,
    //     })
    //   )
    return await updateKidCodeCounter({
      kidId: kidId,
      branchId: values.branchId,
      isWaive,
      values,
      status,
      scheduleId,
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Update Kid Status',
          description: 'Kid Status has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Add Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  const activeForm = [
    {
      label: `Select Branch`,
      name: 'branchId',
      type: 'dropdown',
      options:
        branches?.map((branch) => ({
          value: branch.id,
          label: branch.name,
        })) || [],
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: managerBranchId,
    },
    {
      label: `Kid Code`,
      name: 'code',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
      readonly: true,
    },
    {
      label: `Member Start Date`,
      name: 'activeDate',
      type: 'date',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: `Member Expire Date`,
      name: 'expireDate',
      type: 'date',
      placeholder: '',
      defaultValue: '',
      required: true,
      readonly: true,
    },
    {
      label: `Member Price`,
      name: 'price',
      type: 'number-format',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Kid's Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={activeForm || []}
            data={data}
          />
          <FormControl as='fieldset'>
            <FormLabel as='legend'>Member Waive</FormLabel>
            <Checkbox
              checked={isWaive}
              colorScheme='lime'
              onChange={(e) => setIsWaive(e.target.checked)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(submit)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
