/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  Tooltip,
} from '@chakra-ui/react'
import axios from 'axios'
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import usePayment from 'hooks/usePayment'
import { useEffect, useMemo, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import { Popconfirm } from 'antd'
import CreateRecieptButton from 'components/CreateRecieptButton'
import Table from 'components/Horizons/table'
import { db, functions } from 'config/firebase'
import moment from 'moment'
import { RenderTags } from 'pages/Report/components/revenues'
import {
  MdOutlineDownloadForOffline,
  MdOutlineHighlightOff,
} from 'react-icons/md'
import { IoRefreshOutline } from 'react-icons/io5'
import { httpsCallable } from 'firebase/functions'

const refreshCreatePdf = httpsCallable(functions, 'createReceiptCall')

export default function KidPayment({ user, role }) {
  const toast = useToast()
  const { id } = useParams()
  const { paymentTypes, getDownloadURLToken, cancelPayment, createVoidPDF } =
    usePayment()
  const [isLoadingActive, setLoadingActive] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const [searchParams] = useSearchParams()
  const [courseList, setdataCourseList] = useState([])
  const [paymentList, setPaymentList] = useState([])
  const [tab, setTab] = useState(0)
  const [refreshCreateLoading, setRefreshCreateLoading] = useState(false)
  const [refreshCreateCancelLoading, setRefreshCreateCancelLoading] =
    useState(false)
  const tabMenu = useMemo(() => {
    return Number(searchParams.get('tab'))
  }, [searchParams])

  useEffect(() => {
    if (id && paymentList) {
      getCourseList(id)
    }
  }, [id, paymentList, tabMenu])

  const getCourseList = async (id) => {
    const queryKid = query(
      collection(db, `Kids/${id}/Status`),
      orderBy('createdAt', 'desc')
    )

    const queryKid1 = query(
      collection(db, `Kids/${id}/Programs`),
      orderBy('createdAt', 'desc')
    )

    const [status, active] = await Promise.all([
      await (
        await getDocs(queryKid)
      ).docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })),
      await (
        await getDocs(queryKid1)
      ).docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })),
    ])

    const programs = await Promise.all(
      active.map(async (doc) => {
        const querySchedules = query(
          collection(db, 'Schedules'),
          where('programId', '==', doc.id),
          orderBy('order', 'asc')
        )
        const data = await getDocs(querySchedules)

        const firstLast = (
          await Promise.all(
            data.docs.map((d, i) => {
              return moment(d.data().date, 'YYYY-MM-DD').format('DD/MM/YYYY')
            })
          )
        )
          .filter((doc) => doc && doc !== 'Invalid date' && doc !== '')
          .sort(function (a, b) {
            var aa = a.split('/').reverse().join(),
              bb = b.split('/').reverse().join()
            return aa < bb ? -1 : aa > bb ? 1 : 0
          })
        return {
          ...doc,
          id: doc.id,
          description: `(${doc?.courseName}) ${
            doc?.choice !== 'Evaluation' ? doc?.choice : ''
          }  ${firstLast[0]} ${
            firstLast[firstLast.length - 1]
              ? ` - ${firstLast[firstLast.length - 1]} `
              : ''
          }`,
        }
      })
    )
    setdataCourseList(status.concat(programs))
    setLoadingActive(false)
  }

  useEffect(() => {
    if (id) {
      const queryKid = query(
        collection(db, `Payments`),
        where('kidId', '==', id),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setPaymentList(data)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  async function handleCancel(row) {
    setSubmitting(true)

    try {
      const { id, kidId } = row
      await cancelPayment(id, kidId, row).then(() => setSubmitting(false))
      toast({
        position: 'top',
        Header: 'Cancel Payment',
        description: 'Payment has been Canceled.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        position: 'top',
        Header: 'Cancel Fail.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setSubmitting(false)
    }
  }

  const downloadPdf = async (path) => {
    const downloadUrl = await getDownloadURLToken(path)
    handleDownload(downloadUrl, path)
  }

  const handleDownload = async (url, name) => {
    setSubmitting(true)

    try {
      await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}`)
          document.body.appendChild(link)
          link.click()
        })
        .then(() => setSubmitting(false))
    } catch (error) {
      setSubmitting(false)

      console.log(error)
    }
  }

  const columnsCourse = [
    {
      Header: 'Created At',
      accessor: 'createdAt',
      key: 'createdAt',
      extra: (date, row) => moment(date.toDate()).format('DD/MM/YYYY HH:mm'),
    },
    {
      Header: 'Program',
      accessor: 'choice',
      key: 'choice',
      width: '30%',
      extra: (data, row) => {
        return data
          ? `${row.description}`
          : `Member ${
              row?.activeDate && row?.activeDate !== ''
                ? moment(row?.activeDate.toDate()).format('DD/MM/YYYY')
                : ''
            } - ${
              row?.expireDate && row?.expireDate !== ''
                ? moment(row?.expireDate.toDate()).format('DD/MM/YYYY')
                : ''
            } `
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      key: 'price',
      extra: (price) => `${Number(price).toLocaleString('th-TH')} บาท`,
    },
    {
      Header: 'Paid',
      extra: (_, row) => {
        const sum = row.payment?.transactions?.reduce((a, b) => {
          return b.action === 'paid'
            ? a + Number(b.paid)
            : b.action === 'refund'
            ? a - Number(b.paid)
            : a
        }, 0)
        return row?.payment && row?.payment?.transactions && sum > 0 ? (
          <Text color={sum === 0 ? 'gray' : 'green.400'}>{`${sum.toLocaleString(
            'th-TH'
          )} บาท`}</Text>
        ) : (
          '-'
        )
      },
    },
    {
      Header: 'Clear Amount',
      extra: (_, row) => {
        const sum = row.payment?.transactions?.reduce((a, b) => {
          return b.action === 'clear' ? a + Number(b.paid) : a
        }, 0)
        return row?.payment && row?.payment?.transactions && sum > 0 ? (
          <Text color={'orange.400'}>{`${sum.toLocaleString(
            'th-TH'
          )} บาท`}</Text>
        ) : (
          '-'
        )
      },
    },
    {
      Header: 'Remaining',
      key: 'remaining',
      extra: (_, row) => {
        const sum =
          row?.payment?.transactions?.reduce((a, b) => {
            return b.action === 'paid' || b.action === 'clear'
              ? a + Number(b.paid)
              : a
          }, 0) || 0
        const remaining = Number(row.price) - sum

        return row?.payment?.transactions && remaining !== 0 ? (
          <Text color='red.400'>
            {`${remaining.toLocaleString('th-TH')} บาท`}
          </Text>
        ) : (
          '-'
        )
      },
    },
    {
      Header: 'Refund',
      extra: (_, row) => {
        const sum = row.payment?.transactions?.reduce((a, b) => {
          return b.action === 'refund' ? a + Number(b.paid) : a
        }, 0)
        return row?.payment && row?.payment?.transactions && sum > 0 ? (
          <Text color={sum === 0 ? 'gray' : 'purple'}>{`${sum.toLocaleString(
            'th-TH'
          )} บาท`}</Text>
        ) : (
          '-'
        )
      },
    },
    {
      Header: 'Status',
      accessor: 'payment',
      key: 'payment',
      align: 'center',
      width: '10%',
      extra: (payment, row) => {
        return (
          <Heading
            fontSize={'md'}
            color={
              payment?.status === 'paid'
                ? 'brand'
                : payment?.status === 'refund'
                ? 'purple'
                : 'gray'
            }
          >
            {payment?.status === 'paid'
              ? 'Paid'
              : payment?.status === 'clear'
              ? 'Clear'
              : payment?.status === 'refund'
              ? 'Refund'
              : 'Waiting'}
          </Heading>
        )
      },
    },
    {
      Header: 'Note',
      accessor: 'payment.note',
      key: 'payment.note',
      align: 'center',
      width: '10%',
    },
  ]

  const handleRefreshCreateReceipt = (id) => {
    setRefreshCreateLoading(true)
    return refreshCreatePdf({ receipt: id })
      .then((response) => {
        toast({
          position: 'top',
          Header: 'Update Payment',
          description: 'Payment has been Re-Create Success',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((error) => {
        console.log(error)
        toast({
          position: 'top',
          Header: 'Update Payment',
          description: 'Payment has been Failed!',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
      .finally(() => {
        setRefreshCreateLoading(false)
      })
  }

  const handleRefreshCreateCancelReceipt = (id) => {
    setRefreshCreateCancelLoading(true)
    return createVoidPDF(id)
      .then((response) => {
        toast({
          position: 'top',
          Header: 'Update Payment',
          description: 'Payment has been Re-Create Success',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((error) => {
        console.log(error)
        toast({
          position: 'top',
          Header: 'Update Payment',
          description: 'Payment has been Failed!',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
      .finally(() => {
        setRefreshCreateCancelLoading(false)
      })
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'วันที่สร้าง',
        accessor: 'createdAt',
        key: 'createdAt',
        width: '12%',
        extra: (date) => {
          return moment(date.toDate()).format('DD/MM/YYYY HH:mm')
        },
      },
      {
        Header: 'เลขที่เอกสาร',
        accessor: 'ref',
        key: 'ref',
        width: '12%',
        extra: (type) => {
          return type ?? '-'
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        key: 'type',
        width: '8%',
        extra: (type) => {
          return type === 'bill'
            ? 'บิลเงินสด'
            : type === 'reciept'
            ? 'ใบเสร็จรับเงิน'
            : type === 'clear'
            ? 'Clear'
            : ''
        },
      },

      {
        Header: 'สาขา',
        accessor: 'branch',
        key: 'branch',
        width: '12%',
        extra: (branch) => {
          return branch.name
        },
      },

      {
        Header: 'การชำระเงิน',
        accessor: 'payment',
        key: 'payment',
        width: '12%',
        extra: (payment) => {
          return payment ?? '-'
        },
      },
      {
        Header: 'สถานะ',
        accessor: 'status',
        key: 'status',
        width: '8%',
        align: 'center',
        extra: (status, row) => {
          return (
            <Heading
              fontSize='md'
              color={
                status === 'paid'
                  ? 'brand'
                  : status === 'cancel'
                  ? 'red'
                  : 'gray'
              }
              textTransform={'uppercase'}
            >
              {status === 'paid'
                ? status
                : status === 'cancel'
                ? 'CANCELED'
                : status}
            </Heading>
          )
        },
      },
      {
        Header: 'Tag',
        accessor: 'programs',
        width: '10%',
        extra: (data) => {
          const tags = data.map((d) => {
            const isPaidAfterRemaining =
              d?.docsRef && d?.docsRef[d?.docsRef.length - 1]?.paymentRef
            return { tag: d.tag, isPaidAfterRemaining }
          })

          return (
            <SimpleGrid spacing='4px'>
              {[...new Set(tags)].map((d, i) => (
                <Box key={i}>{RenderTags(d?.tag, d?.isPaidAfterRemaining)}</Box>
              ))}
            </SimpleGrid>
          )
        },
      },
      {
        Header: 'ยอดรวมสุทธิ',
        accessor: 'totalPrice',
        key: 'totalPrice',
        align: 'right',
        width: '8%',
        extra: (data, row) => `${Number(data).toLocaleString('th-TH')} บาท`,
      },
      {
        Header: '',
        accessor: 'Action',
        key: 'Action',
        width: '5%',
        align: 'center',
        extra: (data, row) => {
          return (
            <HStack spacing={0} m={0} p={0} justifyContent='center'>
              {/* <Text>{row?.id}</Text> */}
              {user?.email?.includes('@ls.co.th') &&
              (row?.type === 'bill' || row?.type === 'reciept') &&
              row?.status === 'paid' ? (
                <Tooltip
                  label={
                    'สร้างเอกสารใหม่อีกครั้ง (ปุ่มนี้จะเห็นเฉพาะ @ls.co.th เท่านั้น)'
                  }
                >
                  <IconButton
                    variant='ghost'
                    color='orange'
                    aria-label='refresh create receipt'
                    icon={<IoRefreshOutline />}
                    disabled={refreshCreateLoading}
                    isLoading={refreshCreateLoading}
                    onClick={() => {
                      handleRefreshCreateReceipt(row?.id)
                    }}
                  />
                </Tooltip>
              ) : row?.status === 'cancel' ? (
                <Tooltip
                  label={
                    'สร้างยกเลิกเอกสารใหม่อีกครั้ง (ปุ่มนี้จะเห็นเฉพาะ @ls.co.th เท่านั้น)'
                  }
                >
                  <IconButton
                    variant='ghost'
                    color='orange'
                    aria-label='refresh create receipt'
                    icon={<IoRefreshOutline />}
                    disabled={refreshCreateCancelLoading}
                    isLoading={refreshCreateCancelLoading}
                    onClick={() => {
                      handleRefreshCreateCancelReceipt(row?.id)
                    }}
                  />
                </Tooltip>
              ) : null}
              {row?.type === 'bill' &&
                (row?.status === 'paid' || row?.status === 'remaining') && (
                  <>
                    <CreateRecieptButton
                      id={row?.id}
                      receiptRef={row?.receiptRef}
                    />
                  </>
                )}
              {row?.type !== 'remaining' && (
                <>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<MdOutlineDownloadForOffline fontSize='25px' />}
                      variant='ghost'
                      aria-label='download'
                      color={'brand'}
                    />
                    <MenuList justifyContent='center' alignItems='center'>
                      {row?.type === 'bill' && (
                        <>
                          <MenuItem
                            textAlign='center'
                            color='navy'
                            onClick={() => downloadPdf(row.billPdfRefEN)}
                            isDisabled={!row.billPdfRefEN || isSubmitting}
                          >
                            Cash Bill (ENGLISH)
                            {(!row.billPdfRefEN || isSubmitting) && (
                              <Spinner ml={3} />
                            )}
                          </MenuItem>

                          <MenuItem
                            textAlign='center'
                            color='navy'
                            onClick={() => downloadPdf(row.billPdfRefTH)}
                            isDisabled={!row.billPdfRefTH || isSubmitting}
                          >
                            บิลเงินสด (ภาษาไทย)
                            {(!row.billPdfRefTH || isSubmitting) && (
                              <Spinner ml={3} />
                            )}
                          </MenuItem>
                        </>
                      )}

                      {row?.type === 'reciept' && (
                        <MenuItem
                          textAlign='center'
                          color='navy'
                          isDisabled={!!!row.receiptPdfRef || isSubmitting}
                          onClick={() => downloadPdf(row.receiptPdfRef)}
                        >
                          ใบเสร็จรับเงิน
                          {(!row.receiptPdfRef || isSubmitting) && (
                            <Spinner ml={3} />
                          )}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </>
              )}

              {(row?.status === 'paid' || row?.status === 'remaining') && (
                <Popconfirm
                  title='Sure to cancel this Payment'
                  onConfirm={() => {
                    handleCancel(row)
                  }}
                >
                  <IconButton
                    disabled={isSubmitting}
                    variant='ghost'
                    aria-label='cancel'
                    icon={<MdOutlineHighlightOff color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              )}
            </HStack>
            // )
          )
        },
      },
    ]
  }, [paymentTypes, isSubmitting])

  return (
    <>
      <Center my='5'>
        <Heading>PAYMENTS</Heading>
      </Center>
      <Stack spacing={10}>
        <Box>
          <Flex
            justifyContent='space-between'
            alignItems={'flex-end'}
            w={'100%'}
            my={2}
          >
            <Heading as='h5' size='sm' color='brand'>
              Payments
            </Heading>
            {role !== 'Staff' && (
              <HStack>
                {role === 'Owner' && (
                  <Link to={`/payments/refund/create?kid=${id}`}>
                    <Button textAlign='center' py={2} colorScheme='purple'>
                      Refund
                    </Button>
                  </Link>
                )}
                {role === 'Owner' && (
                  <Link to={`/payments/clear/create?kid=${id}`}>
                    <Button textAlign='center' py={2} color='black'>
                      Clear Bill
                    </Button>
                  </Link>
                )}
                <Link to={`/payments/bill/create?kid=${id}`}>
                  <Button
                    colorScheme='orange'
                    bg='#F6872B'
                    textAlign='center'
                    py={2}
                  >
                    Create Cash Bill
                  </Button>
                </Link>
              </HStack>
            )}
          </Flex>

          <Box
            borderWidth='1px'
            borderColor={'brand'}
            borderRadius='12px'
            p={5}
          >
            <Table
              columnsData={columnsCourse}
              tableData={courseList}
              loading={isLoadingActive}
              noSearch
            />
          </Box>
        </Box>

        <Box>
          <Flex
            justifyContent='space-between'
            alignItems={'flex-end'}
            w={'100%'}
            my={2}
          >
            <Heading as='h5' size='sm' color='brand'>
              Payment Documents
            </Heading>
          </Flex>

          <Box
            borderWidth='1px'
            borderColor={'brand'}
            borderRadius='12px'
            p={5}
          >
            <Tabs
              variant='solid-rounded'
              mt={'2rem'}
              colorScheme='lime'
              onChange={(i) => setTab(i)}
              index={tab}
            >
              <TabList overflowX='auto'>
                <Tab
                  _focus={{ boxShadow: 'none' }}
                  _selected={{ color: 'white', bg: 'brand' }}
                  borderBottom='2px solid rgba(226, 232, 240, 1)'
                  borderRadius='15px'
                  mx={1}
                >
                  บิลเงินสด
                </Tab>
                <Tab
                  _focus={{ boxShadow: 'none' }}
                  _selected={{ color: 'white', bg: 'brand' }}
                  borderBottom='2px solid rgba(226, 232, 240, 1)'
                  borderRadius='15px'
                  mx={1}
                >
                  ใบเสร็จรับเงิน
                </Tab>
                <Tab
                  _focus={{ boxShadow: 'none' }}
                  _selected={{ color: 'white', bg: 'brand' }}
                  borderBottom='2px solid rgba(226, 232, 240, 1)'
                  borderRadius='15px'
                  mx={1}
                >
                  Clear
                </Tab>
                <Tab
                  _focus={{ boxShadow: 'none' }}
                  _selected={{ color: 'white', bg: 'brand' }}
                  borderBottom='2px solid rgba(226, 232, 240, 1)'
                  borderRadius='15px'
                  mx={1}
                >
                  Refund
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Table
                    columnsData={columns}
                    tableData={
                      paymentList.filter((l) => l.type === 'bill') || []
                    }
                    loading={isLoading}
                    noSearch
                  />
                </TabPanel>
                <TabPanel>
                  <Table
                    columnsData={columns}
                    tableData={
                      paymentList.filter((l) => l.type === 'reciept') || []
                    }
                    loading={isLoading}
                    noSearch
                  />
                </TabPanel>
                <TabPanel>
                  <Table
                    columnsData={columns}
                    tableData={
                      paymentList.filter((l) => l.type === 'clear') || []
                    }
                    loading={isLoading}
                    noSearch
                  />
                </TabPanel>
                <TabPanel>
                  <Table
                    columnsData={columns}
                    tableData={
                      paymentList.filter((l) => l.type === 'refund') || []
                    }
                    loading={isLoading}
                    noSearch
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Stack>
    </>
  )
}
