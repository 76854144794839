/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  HStack,
} from '@chakra-ui/react'
import InputComponent from './input'

const FormInputs = ({ errors, control, forms, data }) => {
  function setFocus(id) {
    const yOffset = -250
    const element = document.getElementById(id)
    const y =
      (element?.getBoundingClientRect()?.top || 0) +
      window.pageYOffset +
      yOffset

    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  return forms?.map((form) => {
    const {
      name,
      label,
      type,
      required,
      options,
      defaultValue,
      placeholder,
      hideOnEdit,
      rules,
      readonly,
      disabled,
      disableOnEdit,
    } = form

    if (data && hideOnEdit) {
      return null
    }

    return (
      <FormControl
        my={3}
        id={name}
        isInvalid={errors[name]}
        key={name}
        w={'100%'}
      >
        <FormLabel>
          <HStack spacing={0}>
            <Text>{label}</Text>
            {required && <Text color='red'>*</Text>}{' '}
          </HStack>
        </FormLabel>
        <Controller
          name={name}
          control={control}
          rules={{
            ...rules,
            required: required && `This field is required`,
          }}
          defaultValue={(data && data[name]) || defaultValue}
          render={({ field: { onChange, value } }) => (
            <InputComponent
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              type={type}
              options={options}
              defaultValue={(data && data[name]) || defaultValue}
              readonly={readonly}
              disabled={disabled}
              disableOnEdit={data && disableOnEdit}
            />
          )}
        />
        <FormErrorMessage>
          {errors[name] && errors[name].message}
        </FormErrorMessage>
      </FormControl>
    )
  })
}

export default FormInputs
