/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useFirestoreQuery } from '@react-query-firebase/firestore'
import { DatePicker, Popconfirm } from 'antd'
import axios from 'axios'
import Table from 'components/Horizons/table'
import { db } from 'config/firebase'
import { collection, orderBy, query, where } from 'firebase/firestore'
import useBranch from 'hooks/useBranch'
import usePayment from 'hooks/usePayment'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import {
  MdOutlineDownloadForOffline,
  MdOutlineHighlightOff,
} from 'react-icons/md'
import { Link, useSearchParams } from 'react-router-dom'
import { search } from 'ss-search'
import CreateRecieptButton from 'components/CreateRecieptButton'

export default function BillsTable({ user, role, tab }) {
  const managerBranchId =
    role === 'Manager' || role === 'Staff' ? user?.branchId : ''
  const toast = useToast()
  const { branches } = useBranch()
  const { cancelPayment, getDownloadURLToken, paymentTypes } = usePayment()
  const [isChecked, setIsChecked] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || '',
    status: '',
    type: '',
    month: moment().format('YYYY-MM-DD'),
  })

  useEffect(() => {
    if (managerBranchId !== '') {
      setSearchParams({ branchId: managerBranchId })
    }
  }, [managerBranchId])

  useEffect(() => {
    const branchId = searchParams.get('branchId')
    if (branchId !== '') {
      setSearch({ ...searchQuery, branch: branchId })
    }
  }, [searchParams.get('branchId')])

  useEffect(() => {
    const branchId = searchParams.get('branchId')
    if (branchId !== '') {
      setSearch({ ...searchQuery, branch: branchId })
    }
  }, [searchParams.get('branchId')])

  const paymentRef = useMemo(() => {
    let start = new Date(moment(searchQuery?.month).startOf('day'))
    let end = new Date(moment(searchQuery?.month).endOf('day'))

    if (isChecked) {
      start = new Date(moment(searchQuery?.month).startOf('month'))
      end = new Date(moment(searchQuery?.month).endOf('month'))
    }

    return query(
      collection(db, 'Payments'),
      where('type', '==', 'bill'),
      where('branch.id', '==', searchQuery?.branch),
      where('createdAt', '>=', start),
      where('createdAt', '<=', end),
      orderBy('createdAt', 'desc')
    )
  }, [searchQuery?.branch, searchQuery?.month, isChecked])

  // Provide the query to the hook
  const { data, isLoading, refetch } = useFirestoreQuery(
    ['billPayment', searchQuery?.branch, searchQuery?.month, isChecked, tab],
    paymentRef,
    {
      subscribe: true,
    },
    {
      select(snapshot) {
        return snapshot?.docs?.map((docSnapshot) => ({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        }))
      },
    }
  )

  const paymentQuery = useMemo(() => {
    if (data) {
      let textSearch = searchQuery.text
      const searchQueryArr =
        data &&
        data
          .filter((payment) =>
            searchQuery.branch !== ''
              ? payment.branch?.id === searchQuery.branch
              : payment
          )
          .filter((payment) =>
            searchQuery.status !== ''
              ? payment.status === searchQuery.status
              : payment
          )
          .filter((payment) =>
            searchQuery.type !== ''
              ? payment.type === searchQuery.type
              : payment
          )
      const searchKeys = ['name', 'nickname', 'ref', 'kid']
      const results = search(searchQueryArr, searchKeys, textSearch)
      const searchResults = textSearch === '' ? searchQueryArr : results
      return searchResults || []
    }
    return []
  }, [data, searchQuery])

  const downloadPdf = async (path) => {
    const downloadUrl = await getDownloadURLToken(path)
    handleDownload(downloadUrl, path)
    // const newWindow = window.open(downloadUrl, '_blank', 'noopener,noreferrer')

    // if (newWindow) newWindow.opener = null
  }

  const handleDownload = async (url, name) => {
    setSubmitting(true)

    try {
      await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}`)
          document.body.appendChild(link)
          link.click()
        })
        .then(() => {
          setSubmitting(false)
        })
    } catch (error) {
      setSubmitting(true)

      console.log(error)
    }
  }

  async function handleCancel(row) {
    setSubmitting(true)

    try {
      const { id, kidId } = row
      await cancelPayment(id, kidId, row)
        .then(() => setSubmitting(false))
        .then(() => refetch())
      toast({
        position: 'top',
        Header: 'Cancel Payment',
        description: 'Payment has been Canceled.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        position: 'top',
        Header: 'Cancel Fail.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setSubmitting(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'เลขที่เอกสาร',
        accessor: 'ref',
        key: 'ref',
        width: '15%',
      },

      {
        Header: 'KID',
        accessor: 'kid',
        key: 'kid',
        width: '15%',
        extra: (data) => (
          <Link to={`/kids/${data?.id}?tab=4`}>{data?.label}</Link>
        ),
      },
      {
        Header: 'ชื่อลูกค้า',
        accessor: 'name',
        key: 'name',
        width: '15%',
      },
      {
        Header: 'สาขา',
        accessor: 'branch',
        key: 'branch',
        width: '12%',
        extra: (branch) => {
          return branch.name
        },
      },
      {
        Header: 'วันที่สร้าง',
        accessor: 'createdAt',
        key: 'createdAt',
        width: '12%',
        extra: (date) => {
          return moment(date.toDate()).format('LLL')
        },
      },
      {
        Header: 'การชำระเงิน',
        accessor: 'payment',
        key: 'payment',
        width: '12%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        key: 'status',
        align: 'center',
        width: '10%',
        extra: (status, row) => {
          return (
            <Heading
              fontSize='md'
              color={status === 'paid' ? 'brand' : 'red'}
              textTransform={'uppercase'}
            >
              {status === 'paid' ? status : 'CANCELED'}
            </Heading>
          )
        },
      },
      {
        Header: 'ยอดรวมสุทธิ',
        accessor: 'totalPrice',
        key: 'totalPrice',
        align: 'right',
        width: '15%',
        extra: (data, row) => `${Number(data).toLocaleString('th-TH')} บาท`,
      },
      {
        Header: '',
        accessor: 'Action',
        key: 'Action',
        width: '5%',
        align: 'center',
        extra: (data, row) => {
          const payType = paymentTypes.find((type) => type.id === row.paymentId)
          const docType = payType?.paymentDocumentType
          return (
            <HStack spacing={1} m={0} p={0} justifyContent='center'>
              {row?.status === 'paid' && (
                <CreateRecieptButton
                  id={row?.id}
                  receiptRef={row?.receiptRef}
                />
              )}
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<MdOutlineDownloadForOffline fontSize='25px' />}
                  variant='ghost'
                  aria-label='download'
                  color={'brand'}
                />
                <MenuList justifyContent='center' alignItems='center'>
                  <MenuItem
                    textAlign='center'
                    color='navy'
                    onClick={() => downloadPdf(row.billPdfRefEN)}
                    isDisabled={
                      !row.billPdfRefEN ||
                      (docType && docType === 'receipt') ||
                      isSubmitting
                    }
                  >
                    Cash Bill (ENGLISH)
                    {!row.billPdfRefEN && <Spinner ml={3} />}
                  </MenuItem>

                  <MenuItem
                    textAlign='center'
                    color='navy'
                    onClick={() => downloadPdf(row.billPdfRefTH)}
                    isDisabled={
                      !row.billPdfRefTH ||
                      (docType && docType === 'receipt') ||
                      isSubmitting
                    }
                  >
                    บิลเงินสด (ภาษาไทย)
                    {!row.billPdfRefTH && <Spinner ml={3} />}
                  </MenuItem>
                </MenuList>
              </Menu>

              {row?.status === 'paid' && (
                <Popconfirm
                  title='Sure to cancel this Payment'
                  onConfirm={() => {
                    handleCancel(row)
                  }}
                >
                  <IconButton
                    disabled={isSubmitting}
                    variant='ghost'
                    aria-label='cancel'
                    icon={<MdOutlineHighlightOff color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              )}
            </HStack>
            // )
          )
        },
      },
    ]
  }, [paymentTypes, isSubmitting])

  const sumReport = useMemo(() => {
    const sum =
      paymentQuery?.reduce(
        (sum, data) => (data.status === 'paid' ? sum + data.totalPrice : sum),
        0
      ) || 0

    return sum
  }, [paymentQuery])

  return (
    <Box>
      <Text color='brand'>Search</Text>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          refetch()
        }}
      >
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          p={5}
        >
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 5 }}
            spacing={{ base: 0, md: 2 }}
          >
            <FormControl>
              <FormLabel>Search</FormLabel>
              <Input
                id='search'
                type='search'
                placeholder='Search'
                onChange={(e) =>
                  setSearch({ ...searchQuery, text: e.target.value })
                }
                value={searchQuery.text}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Please Select Branch'
                onChange={(e) => {
                  setSearchParams({ branchId: e.target.value })
                  // setSearch({ ...searchQuery, branch: e.target.value })
                }}
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                id='status'
                placeholder='Status'
                onChange={(e) =>
                  setSearch({ ...searchQuery, status: e.target.value })
                }
                value={searchQuery.status}
              >
                <option value='paid'>Paid</option>
                <option value='cancel'>Cancel</option>
              </Select>
            </FormControl>
            <FormControl>
              <HStack w='full' justifyContent='space-between'>
                <FormLabel>{!isChecked ? 'Date' : 'Month'}</FormLabel>
                <Checkbox
                  colorScheme='lime'
                  onChange={(e) => {
                    setIsChecked(e.target.checked)
                    if (!e.target.checked) {
                      setSearch({
                        ...searchQuery,
                        date: moment().format('YYYY-MM'),
                      })
                    }
                  }}
                >
                  เลือกแบบเดือน
                </Checkbox>
              </HStack>
              {!isChecked ? (
                <DatePicker
                  allowClear={false}
                  format={'DD MMMM YYYY'}
                  value={moment(searchQuery.month)}
                  onChange={(date) => {
                    setSearch({
                      ...searchQuery,
                      month: moment(date).format('YYYY-MM-DD'),
                      text: '',
                    })
                  }}
                />
              ) : (
                <DatePicker
                  allowClear={false}
                  picker='month'
                  format={'MMMM YYYY'}
                  value={moment(searchQuery.month)}
                  onChange={(date) =>
                    setSearch({
                      ...searchQuery,
                      month: moment(date).format('YYYY-MM'),
                      text: '',
                    })
                  }
                />
              )}
            </FormControl>
          </SimpleGrid>
        </Box>
      </form>

      <Flex
        justifyContent='space-between'
        w='full'
        my={4}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Flex flexDirection={{ base: 'column', lg: 'row' }}>
          <Stack
            p={{ base: 3, md: 4 }}
            my={{ base: 2, md: 4 }}
            mx={{ base: 1, md: 2 }}
            borderWidth='1px'
            borderColor='brand'
            borderRadius='10px'
            minW={{ base: '300px', md: '300px' }}
          >
            <HStack justifyContent='space-between'>
              <Heading size={{ base: 'sm', md: 'md' }}>จำนวนเอกสาร</Heading>
              <Heading size={{ base: 'sm', md: 'md' }}>
                {paymentQuery?.length}
              </Heading>
            </HStack>
          </Stack>
          <Stack
            p={{ base: 3, md: 4 }}
            my={{ base: 2, md: 4 }}
            mx={{ base: 1, md: 2 }}
            borderWidth='1px'
            borderColor='brand'
            borderRadius='10px'
            minW={{ base: '300px', md: '300px' }}
          >
            <HStack justifyContent='space-between'>
              <Heading size={{ base: 'sm', md: 'md' }}>รายรับปัจจุบัน</Heading>
              <Heading
                size={{ base: 'sm', md: 'md' }}
              >{`${sumReport.toLocaleString()} บาท`}</Heading>
            </HStack>
          </Stack>
        </Flex>
      </Flex>

      <Table
        columnsData={columns}
        tableData={paymentQuery}
        loading={isLoading}
        noSearch
        useCurrentPage
      />
    </Box>
  )
}
