import { useState, useRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Icon,
  Input,
  IconButton,
  Flex,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { addDoc, collection } from 'firebase/firestore'
import { db, storage } from 'config/firebase'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'

import { FiFile, FiDelete } from 'react-icons/fi'

const FileUpload = (props) => {
  const { register, accept, children } = props
  const inputRef = useRef(null)
  const { ref, ...rest } = register

  const handleClick = () => inputRef.current?.click()

  return (
    <InputGroup onClick={handleClick}>
      <input
        type={'file'}
        multiple={false}
        hidden
        accept={accept}
        {...rest}
        ref={(e) => {
          ref(e)
          inputRef.current = e
        }}
      />
      <>{children}</>
    </InputGroup>
  )
}

export default function AddDocModal({ isOpen, onClose, kidId }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const {
    handleSubmit,
    formState: { errors },
    resetField,
    register,
    watch,
  } = useForm()

  const fileUpload = watch('file_')

  async function create(data, e) {
    e.preventDefault()
    setLoading(true)
    const promises = []
    const storageRef = ref(
      storage,
      `Kids/${kidId}/Documents/${new Date().getTime()}-${data.file_[0].name}`
    )
    const uploadTask = uploadBytes(storageRef, data.file_[0])

    promises.push(
      uploadTask
        .then((uploadResult) => {
          return getDownloadURL(uploadResult.ref)
        })
        .then((url) => url)
    )

    const downloadURL = await Promise.all(promises)

    addDoc(collection(db, `Kids/${kidId}/Documents`), {
      title: data.title,
      url: downloadURL,
      fileName: data.file_[0].name,
      createdAt: new Date(),
    })
      .then(() => {
        toast({
          position: 'top',
          title: 'Add Kid Document',
          description: 'Kid Document has been Added.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        onClose()
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Add Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }
  const validateFiles = (value) => {
    if (!value || value?.length < 1) {
      return 'Files is required'
    }
    for (const file of Array.from(value)) {
      const fsMb = file.size / (1024 * 1024)
      const MAX_FILE_SIZE = 10
      if (fsMb > MAX_FILE_SIZE) {
        return 'Max file size 10mb'
      }
    }
    return true
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit(create)}>
        <ModalContent>
          <ModalHeader textAlign='center'>Add Document</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            <FormControl isInvalid={!!errors.title} my={3} isRequired>
              <FormLabel>{'Title'}</FormLabel>

              <Input
                {...register(`title`, {
                  required: 'Title is required',
                })}
                name='title'
                type={'text'}
                defaultValue={''}
              />

              <FormErrorMessage>
                {errors.title && errors?.title.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.file_} isRequired my={3}>
              <FormLabel>{'File'}</FormLabel>
              <FileUpload
                accept={'*'}
                multiple
                register={register('file_', { validate: validateFiles })}
              >
                <Button leftIcon={<Icon as={FiFile} />}>Upload</Button>
              </FileUpload>

              {fileUpload && fileUpload.length > 0 && (
                <Flex alignItems={'center'} pt={4}>
                  <Button variant='link'>{fileUpload[0].name}</Button>
                  <IconButton
                    colorScheme={'red'}
                    icon={<Icon as={FiDelete} />}
                    variant='link'
                    onClick={() => resetField('file_')}
                  />
                </Flex>
              )}

              <FormErrorMessage>
                {errors.file_ && errors?.file_.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button mr={3} variant='outline' onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme='lime'
              bg='brand'
              isLoading={isLoading}
              type='submit'
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
