/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Center,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import Payment from './payment'
import BillsTable from './bill'
import ReceiptTable from './receipt'
import { useSearchParams } from 'react-router-dom'
import { isEmpty } from 'underscore'

export default function PaymentPage({ user, role }) {
  const [tab, setTab] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const search = {}
    for (const [key, value] of searchParams.entries()) {
      Object.assign(search, { [key]: value })
    }
    !isEmpty(search) && setSearchParams(Object.assign(search))
  }, [tab])

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <Center pb={4}>
        <Heading>PAYMENTS</Heading>
      </Center>
      <Box>
        <Tabs
          variant='solid-rounded'
          mt={'2rem'}
          colorScheme='lime'
          onChange={(i) => setTab(i)}
          index={tab}
        >
          <TabList overflowX='auto'>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
            >
              สร้างเอกสาร
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
            >
              บิลเงินสด
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
            >
              ใบเสร็จรับเงิน
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel py={10}>
              <Payment user={user} role={role} tab={tab} />
            </TabPanel>

            <TabPanel py={10}>
              <BillsTable user={user} role={role} tab={tab} />
            </TabPanel>

            <TabPanel py={10}>
              <ReceiptTable user={user} role={role} tab={tab} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}
