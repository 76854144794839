import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Heading,
  Center,
} from '@chakra-ui/react'
import useBranch from 'hooks/useBranch'

import { PerformanceData } from './components/performance'
import { CampData } from './components/camp'
import { Evalution } from './components/evaluation'
import { ActiveCases } from './components/active'
import { Revenues } from './components/revenues'
// import { RevenuesCourses } from './components/revenuesCourses'
import { Usages } from './components/ap'
import { Clear } from './components/clear'
import { OffCases } from './components/off'
// import { Remaining } from './components/remaining'
import { Refund } from './components/refund'

function Report({ user, role }) {
  const managerBranchId =
    role === 'Manager' || role === 'Staff' ? user?.branchId : null
  const { branches } = useBranch()

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <Center pb={4}>
        <Heading>Report</Heading>
      </Center>
      <Box w='100%'>
        <Tabs variant='solid-rounded' colorScheme='lime'>
          <TabList borderBottom='0px solid none' overflowX='auto'>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              PERFORMANCE
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              CAMP
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              EVALUATION
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              ACTIVE CASES
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              OFF CASES
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              DAILY REVENUES
            </Tab>
            {/* <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              REVENUES BY COURSES
            </Tab> */}
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              REPORT AP
            </Tab>
            {/* <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              REMAINING
            </Tab> */}
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              CLEAR AP
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
              h='45px'
              fontSize={14}
            >
              REFUND
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PerformanceData
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
              />
            </TabPanel>
            <TabPanel>
              <CampData
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
              />
            </TabPanel>
            <TabPanel>
              <Evalution
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
              />
            </TabPanel>
            <TabPanel>
              <ActiveCases
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
              />
            </TabPanel>
            <TabPanel>
              <OffCases
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
                role={role}
              />
            </TabPanel>
            <TabPanel>
              <Revenues
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
                role={role}
              />
            </TabPanel>
            {/* <TabPanel>
              <RevenuesCourses
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
                role={role}
              />
            </TabPanel> */}
            <TabPanel>
              <Usages
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
                role={role}
              />
            </TabPanel>
            <TabPanel>
              <Clear
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
                role={role}
              />
            </TabPanel>
            <TabPanel>
              <Refund
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
                role={role}
              />
            </TabPanel>
            {/* <TabPanel>
              <Remaining
                data={[]}
                managerBranchId={managerBranchId}
                branches={branches}
                role={role}
              />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}

export default Report
