/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table as ChakraTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Heading,
  HStack,
  IconButton,
  Checkbox,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import { search } from 'ss-search'
import moment from 'moment/moment'
import Table from 'components/Horizons/table'
import { FaFileExport } from 'react-icons/fa'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'config/firebase'
import axios from 'axios'
import { useQuery } from 'react-query'

const createFileExcelReportPerformance = httpsCallable(
  functions,
  'createReportPerformance'
)

const getStaffPerformance = httpsCallable(functions, 'getStaffPerformance')

function ModalItems({ onClose, isOpen, data, date }) {
  const { staff, reportByDate } = data
  return (
    <Modal
      size='auto'
      onClose={onClose}
      isOpen={isOpen}
      blockScrollOnMount={true}
    >
      <ModalOverlay />
      <ModalContent w='auto'>
        <ModalHeader fontWeight='normal'>{`${staff?.nickname} / ${staff?.branch} / ${staff?.position}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          w={{ base: '21rem', sm: '21rem', md: '50rem' }}
          p={{ base: '5px', sm: '5px', md: '15px' }}
          maxH='80vh'
          overflowY='auto'
        >
          <Center
            p='0.5rem'
            w='full'
            border='1px solid #E2E8F0'
            overflowX='auto'
          >
            <ChakraTable pt='0px' mt='0px' variant='striped'>
              <Thead bg='#E0E1E2'>
                <Tr>
                  <Th textAlign='start' color='black'>
                    <Text>วันที่</Text>
                  </Th>
                  <Th textAlign='center' color='black'>
                    <Text>Evalution</Text>
                  </Th>
                  {/* <Th textAlign='center' color='black'>
                    <Text>EXP.</Text>
                  </Th> */}
                  <Th textAlign='center' color='black'>
                    <Text>Act.</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {reportByDate.length > 0 ? (
                  reportByDate
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map((data) => {
                      return (
                        <Tr key={data.date}>
                          <Td textAlign='start'>
                            <Text>
                              {moment(data.date).format('DD/MM/YYYY')}
                            </Text>
                          </Td>
                          <Td textAlign='center'>
                            <Text>
                              {!isNaN(data.evaluation) && data.evaluation !== 0
                                ? data.evaluation
                                : ''}
                            </Text>
                          </Td>
                          {/* <Td textAlign='center'>
                            <Text>{!isNaN(data.exp) && data.exp}</Text>
                          </Td> */}
                          <Td textAlign='center'>
                            <Text>
                              {!isNaN(data.act) && data.act !== 0
                                ? data.act
                                : ''}
                            </Text>
                          </Td>
                        </Tr>
                      )
                    })
                ) : (
                  <Tr>
                    <Td textAlign='start'>
                      <Text>{moment(date).format('DD/MM/YYYY')}</Text>
                    </Td>
                    <Td textAlign='center'>
                      <Text>0</Text>
                    </Td>
                    {/* <Td textAlign='center'>
                      <Text>0</Text>
                    </Td> */}
                    <Td textAlign='center'>
                      <Text>0</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </ChakraTable>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export function PerformanceData({ branches, managerBranchId }) {
  const columnsData = [
    {
      Header: 'ชื่อ',
      accessor: 'staff.nickname',
    },
    {
      Header: 'สาขา',
      accessor: 'staff.branch',
      width: '15%',
      extra: (data) => {
        const staffBranch = branches.find(
          (branch) => branch.code === data || branch.name === data
        )

        return staffBranch?.name || ''
      },
    },
    {
      Header: 'วิชาชีพ',
      accessor: 'staff.position',
      extra: (data) => {
        if (data === 'Occupational therapy (OT)') return '(OT)'
        else if (data === 'Special education (SE)') return '(SE)'
        else if (data === 'Speech therapy (ST)') return '(ST)'
      },
    },
    {
      Header: 'Type',
      accessor: 'staff.type',
    },
    {
      Header: 'EVA',
      accessor: 'report.evaluation',
      extra: (data) => data ?? 0,
    },
    // {
    //   Header: 'EXP',
    //   accessor: 'report.exp',
    //   extra: (data) => data ?? 0,
    // },
    {
      Header: 'Act',
      accessor: 'report.act',
      extra: (data) => data ?? 0,
    },
    {
      Header: 'Total',
      accessor: 'report',
      extra: (data, row) => (
        <Button
          variant='link'
          onClick={() => {
            setModalData(row)
            onOpen()
          }}
        >
          {`${!isNaN(data.total) && data.total ? data.total : 0} / (${
            !isNaN(data?.percent) && data?.percent
              ? data.percent?.toFixed(1)
              : 0
          }%)`}
        </Button>
      ),
    },
  ]
  const [isChecked, setIsChecked] = useState(false)

  const [downloadIsLoading, setDownloadIsLoading] = useState(false)
  const [searchQuery, setSearch] = useState({
    text: '',
    position: '',
    branch: managerBranchId || '',
    month: moment().format('YYYY-MM'),
  })

  const { data: report, isLoading } = useQuery(
    ['peformanceReport', searchQuery?.month, searchQuery?.branch],
    async () => {
      const response = await getStaffPerformance({
        branchId: searchQuery?.branch,
        month: moment(searchQuery?.month).format('YYYY-MM'),
      })
      if (!response.data) {
        throw new Error('Network response was not ok')
      }
      return JSON.parse(response.data)
    },
    { refetchOnWindowFocus: false }
  )

  const [dataQuery, setDataQuery] = useState([])
  const [modalData, setModalData] = useState()

  const { onOpen, isOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (report) {
      const sortData = sortResultsByPosition(report)
      let filterBydate = sortData
      if (isChecked) {
        filterBydate = sortData.map((data) => {
          const reportByDate = data.reportByDate.filter(
            (data) => data.date === searchQuery.month
          )
          return {
            ...data,
            report: { ...reportByDate[0] },
            reportByDate,
          }
        })
      }

      setDataQuery(filterBydate)
    }
  }, [report])

  useEffect(() => {
    setSearch({
      ...searchQuery,
      month: isChecked
        ? moment().format('YYYY-MM-DD')
        : moment().format('YYYY-MM'),
    })
  }, [isChecked])

  function sortResultsByPosition(data) {
    const ot = data
      .filter((r) => r.staff.position === 'Occupational therapy (OT)')
      .sort((a, b) => a.staff.type.localeCompare(b.staff.type))

    const se = data
      .filter((r) => r.staff.position === 'Special education (SE)')
      .sort((a, b) => a.staff.type.localeCompare(b.staff.type))

    const st = data
      .filter((r) => r.staff.position === 'Speech therapy (ST)')
      .sort((a, b) => a.staff.type.localeCompare(b.staff.type))

    return [...ot, ...se, ...st]
  }

  async function handleDownloadFile(dataDownload) {
    setDownloadIsLoading(true)
    createFileExcelReportPerformance(JSON.stringify(dataDownload)).then(
      async (i) => {
        const url = i.data.fileUrl
        const fileName = i.data.fileName
        await axios({
          url,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${fileName}`)
            document.body.appendChild(link)
            link.click()
          })
          .catch((err) => {
            console.error(err)
            setDownloadIsLoading(false)
          })
        setDownloadIsLoading(false)
      }
    )
  }

  function handleSearch(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, text: e.target.value })
    let textSearch = e.target.value
    const searchQueryArr = report
    const searchKeys = ['staff.name', 'staff.nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      searchQuery?.position && searchQuery?.position !== ''
        ? searchResults.filter((s) => {
            return s?.staff?.position === searchQuery?.position
          })
        : sortResultsByPosition(searchResults)

    setDataQuery(filteredResults)
  }

  function handleSelectPosition(e) {
    e.preventDefault()
    setSearch({ ...searchQuery, position: e.target.value })
    let textSearch = searchQuery.text
    const searchQueryArr = report
    const searchKeys = ['staff.name', 'staff.nickname']
    const results = search(searchQueryArr, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    const filteredResults =
      e?.target?.value !== ''
        ? searchResults?.filter((s) => {
            return s?.staff?.position === e?.target?.value
          })
        : sortResultsByPosition(searchResults)
    setDataQuery(filteredResults)
  }

  return (
    <Box>
      {isOpen && (
        <ModalItems
          isOpen={isOpen}
          onClose={onClose}
          data={modalData}
          date={searchQuery?.month}
        />
      )}
      <Text color='brand'>Search</Text>
      <Box
        borderWidth='1px'
        borderColor='brand'
        borderRadius='10px'
        mt={1}
        mb={5}
        p={5}
      >
        {/* <HStack columns={{ base: 1, md: 3 }} spacing={{ base: 0, md: 10 }}> */}
        <HStack
          spacing={{ base: 0, md: '20px' }}
          flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
        >
          <FormControl>
            <FormLabel>Search Name</FormLabel>
            <Input
              id='search'
              type='search'
              onChange={(e) => handleSearch(e)}
              value={searchQuery.text}
              placeholder='Search Name'
            />
          </FormControl>
          <FormControl>
            <FormLabel>Position</FormLabel>
            <Select
              id='branch'
              placeholder='All'
              onChange={(e) => handleSelectPosition(e)}
              value={searchQuery.position}
              isDisabled={managerBranchId}
            >
              {[
                'Occupational therapy (OT)',
                'Special education (SE)',
                'Speech therapy (ST)',
              ].map((position) => (
                <option key={position} value={position}>
                  {position}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Branch</FormLabel>
            <Select
              id='branch'
              placeholder='Pleas Select branch'
              onChange={(e) =>
                setSearch({
                  ...searchQuery,
                  branch: e.target.value,
                  text: '',
                  position: '',
                })
              }
              value={searchQuery.branch}
              isDisabled={managerBranchId}
            >
              {branches
                .sort(
                  (a, b) =>
                    b.accountType.localeCompare(a.accountType) ||
                    new Date(b.createdDate) - new Date(a.createdDate)
                )
                .map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
            </Select>
          </FormControl>

          <FormControl>
            <HStack w='full' justifyContent='space-between'>
              <FormLabel>{isChecked ? 'Date' : 'Month'}</FormLabel>
              <Checkbox
                colorScheme='lime'
                onChange={(e) => {
                  setIsChecked(e.target.checked)
                  if (!e.target.checked) {
                    setSearch({
                      ...searchQuery,
                      date: moment().format('YYYY-MM-DD'),
                    })
                  }
                }}
              >
                เลือกแบบวันที่
              </Checkbox>
            </HStack>
            {isChecked ? (
              <DatePicker
                allowClear={false}
                format={'DD MMMM YYYY'}
                value={moment(searchQuery.month)}
                onChange={(date) => {
                  setSearch({
                    ...searchQuery,
                    month: moment(date).format('YYYY-MM-DD'),
                    text: '',
                  })
                }}
              />
            ) : (
              <DatePicker
                allowClear={false}
                picker='month'
                format={'MMMM YYYY'}
                value={moment(searchQuery.month)}
                onChange={(date) =>
                  setSearch({
                    ...searchQuery,
                    month: moment(date).format('YYYY-MM'),
                    text: '',
                  })
                }
              />
            )}
          </FormControl>
          <HStack alignSelf={'end'}>
            <IconButton
              variant='outline'
              fontSize={'23px'}
              icon={<FaFileExport color='#8CC63E' />}
              onClick={() => {
                handleDownloadFile(dataQuery)
              }}
              disabled={downloadIsLoading || !dataQuery.length > 0}
              isLoading={downloadIsLoading}
            />
          </HStack>
        </HStack>
      </Box>
      {searchQuery.branch !== '' ? (
        <Table
          columnsData={columnsData}
          tableData={dataQuery}
          noSearch
          loading={isLoading}
        />
      ) : (
        <Center>
          <Heading>Please Select Branch</Heading>
        </Center>
      )}
    </Box>
  )
}
