/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, Fragment } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Text,
  Checkbox,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select as ChakraSelect,
  Divider,
  Heading,
  Center,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import moment from 'moment'
import Select from 'react-select'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { db } from 'config/firebase'
import { collection, query, onSnapshot, where } from 'firebase/firestore'

import FormInputs from 'components/DynamicForm'
import useStaff from 'hooks/useStaff'
import useSchedule from 'hooks/useSchedule'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const formatGroupLabel = (data) => (
  <Box {...groupStyles}>
    <Text fontSize='lg'>{data.name}</Text>
    <span style={groupBadgeStyles}>
      {data.programs.filter((p) => p.isActive).length}
    </span>
  </Box>
)

export default function CourseModal({
  isOpen,
  onClose,
  kidId,
  kidStatus,
  kid,
  managerBranchId,
  selected,
  branchProvince,
}) {
  const [countStaff, setCountStaff] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const [courses, setCourseList] = useState([])
  const { getStaffByType } = useStaff()
  const [staffs, setStaff] = useState([])
  const [staffsByType, setStaffByType] = useState([])

  const { createSchedule, createScheduleManyStaff } = useSchedule({})
  const [staffWorkingDays, setStaffWorkingDays] = useState([])
  const [result, setResult] = useState([])
  const [checkboxValue, setCheckboxValue] = useState([])

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
    clearErrors,
  } = useForm()

  const { fields, replace } = useFieldArray({
    control,
    name: 'programs',
  })

  const selectedCourse = watch('course')
  const selectedDate = watch('date')
  const selectedStaff = watch('staff')
  const startTime = watch('timeStart')
  const endTime = watch('timeEnd')
  const watchPrograms = watch('programs', { nest: true })

  useEffect(() => {
    if (selected && courses && courses.length > 0) {
      const selectedCourse = courses.find(
        (item) => item.id === selected.courseId
      )

      const selectedOption = selectedCourse?.options?.find(
        (opt) =>
          opt.value.choice === selected.choice &&
          opt.value.amount === selected.amount
      )

      setValue('course', selectedOption)
      setValue('date', moment(selected.lastDate).add(7, 'days'))
    }
  }, [selected, courses])

  useEffect(() => {
    if (Number(countStaff) > 1) {
      const data = new Array(Number(countStaff)).fill('').map((_, index) => ({
        staff:
          index === 0 || watchPrograms[index]?.['staff'] ? selectedStaff : '',
        timeStart:
          index === 0 || watchPrograms[index]?.['timeStart'] ? startTime : '',
        timeEnd:
          index === 0 || watchPrograms[index]?.['timeEnd'] ? endTime : '',
        days: index === 0 || watchPrograms[index]?.['days'] ? [] : [],
      }))
      replace(data)
    } else {
      replace([])
    }
  }, [countStaff])

  useEffect(() => {
    const querySnap = query(
      collection(db, 'Courses'),
      where('province', 'in', ['all', branchProvince])
    )
    const unsubscribe = onSnapshot(querySnap, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        const options = doc
          .data()
          .programs.filter((p) => p.isActive)
          .map((program) => ({
            value: {
              courseId: doc.id,
              courseName: doc.data().name,
              coursDescription: doc.data().description,
              hours: doc.data().hours,
              score: doc.data().score,
              type: doc.data().type,
              ...program,
            },
            label: `${doc.data().name} ${program.choice} (${program.amount} ${
              program.unit
            })`,
          }))
        data.push({ ...doc.data(), id: doc.id, options })
      })

      if (kidStatus === 'evaluation' || kidStatus === 'off') {
        data = data.filter((item) => item.type === 'Evaluation')
      }
      setCourseList(data)
    })
    return () => {
      unsubscribe()
    }
  }, [branchProvince])

  useEffect(() => {
    if (selectedCourse && selectedCourse !== '') {
      setCountStaff(1)
      getStaff(selectedCourse?.value?.type, weekday[selectedDate?.day()])
      setCheckboxValue([selectedDate?.day().toString()])
    }
  }, [selectedCourse, selectedDate])

  useEffect(() => {
    if (
      startTime &&
      startTime !== '' &&
      selectedCourse &&
      selectedCourse !== ''
    ) {
      const offsetTime = selectedCourse?.value?.hours * 60
      const timeSplit = startTime.split('.')
      let hours = Number(timeSplit[0])
      let minutes = Number(timeSplit[1])
      let date = new Date().setHours(hours, minutes)
      const endTime = moment(date).add(offsetTime, 'm').format('HH.mm')
      setValue('timeEnd', endTime)
      clearErrors('timeEnd')
    }
  }, [startTime])

  const Time = useMemo(() => {
    if (selectedStaff && selectedStaff !== '') {
      // let isCamp = selectedCourse?.value?.type === 'Camp'
      const staff = staffsByType.find((item) => item.id === selectedStaff)
      const isWeekend =
        selectedCourse.value.amount > 1
          ? Number(checkboxValue[0]) % 6 === 0
          : new Date(selectedDate).getDay() % 6 === 0

      if (staff) {
        const {
          startTimeWeekend,
          startTimeWorkday,
          endTimeWeekend,
          endTimeWorkday,
          workingDay,
          workingDayWeekend,
        } = staff
        let start = isWeekend ? startTimeWeekend : startTimeWorkday
        let end = isWeekend ? endTimeWeekend : endTimeWorkday

        setStaffWorkingDays(workingDay.concat(workingDayWeekend))

        return [...Array(48)]
          .map((e, i) => {
            return (
              (i / 2 < 10 ? '0' : '') +
              (i / 2 - ((i / 2) % 1)) +
              ((i / 2) % 1 !== 0 ? '.30' : '.00')
            )
          })
          .filter(
            (time) =>
              Number(time) >= Number(start.replace(':', '.')) &&
              Number(time) <= Number(end.replace(':', '.'))
          )
      }
    } else {
      setStaffWorkingDays([])
    }
  }, [selectedCourse, selectedStaff, selectedDate, checkboxValue])

  const getStaffTime = (staffId, day) => {
    if (staffId && staffId !== '' && day?.length > 0 && day[0] !== '') {
      // let isCamp = selectedCourse?.value?.type === 'Camp'
      const staff = staffsByType.find((item) => item.id === staffId)
      const isWeekend = Number(day[0]) % 6 === 0
      const {
        startTimeWeekend,
        startTimeWorkday,
        endTimeWeekend,
        endTimeWorkday,
      } = staff
      let start = isWeekend ? startTimeWeekend : startTimeWorkday
      let end = isWeekend ? endTimeWeekend : endTimeWorkday

      const times = [...Array(48)]
        .map((e, i) => {
          return (
            (i / 2 < 10 ? '0' : '') +
            (i / 2 - ((i / 2) % 1)) +
            ((i / 2) % 1 !== 0 ? '.30' : '.00')
          )
        })
        .filter(
          (time) =>
            Number(time) >= Number(start.replace(':', '.')) &&
            Number(time) <= Number(end.replace(':', '.'))
        )
      return times
    } else {
      return []
    }
  }

  const getStaffTimeEnd = (startTime) => {
    if (startTime && startTime !== '') {
      const offsetTime = selectedCourse?.value?.hours * 60
      const timeSplit = startTime.split('.')
      let hours = Number(timeSplit[0])
      let minutes = Number(timeSplit[1])
      let date = new Date().setHours(hours, minutes)
      const endTime = moment(date).add(offsetTime, 'm').format('HH.mm')
      return endTime
    }
  }

  const getStaffWorkingDays = (staffId) => {
    if (staffId && staffId !== '') {
      const staff = staffsByType.find((item) => item.id === staffId)

      const { workingDay, workingDayWeekend } = staff

      return workingDay.concat(workingDayWeekend)
    } else {
      return []
    }
  }

  // const disabledDate = (current) => {
  //   const staff = staffsByType.find((item) => item.id === selectedStaff)
  //   if (staff) {
  //     const { workingDay, workingDayWeekend } = staff

  //     const disabledDates = weekday.map(
  //       (day, index) =>
  //         !workingDay.concat(workingDayWeekend).includes(day) && index
  //     )

  //     return (
  //       current < moment().startOf('day') ||
  //       moment(current).day() ===
  //         disabledDates.find((date) => date === moment(current).day())
  //     )
  //   } else {
  //     // return current < moment().startOf('day')
  //   }
  // }

  const corseCheckedForm = (Time, staffs) => [
    {
      label: `เวลาเริ่ม`,
      name: 'timeStart',
      type: 'dropdown',
      options: Time?.filter(
        (item, i) => i < Time.length - selectedCourse?.value?.hours * 2
      ),
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled:
        selectedCourse === '' ||
        !selectedCourse ||
        !selectedStaff ||
        !selectedDate,
    },
    {
      label: `เวลาเลิก`,
      name: 'timeEnd',
      type: 'dropdown',
      options: Time,
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: true,
    },
  ]

  const getStaff = async (type, day) => {
    const respone = await getStaffByType(type, day)
    const allStaffByType = await getStaffByType(type)

    if (managerBranchId) {
      setStaff(
        respone
          .filter((staff) => staff.branchId === managerBranchId)
          .filter((staff) => !staff.disabled)
      )
      setStaffByType(
        allStaffByType
          .filter((staff) => staff.branchId === managerBranchId)
          .filter((staff) => !staff.disabled)
      )
    } else {
      setStaff(
        respone
          .filter((staff) => staff.branchId === kid?.branchId)
          .filter((staff) => !staff.disabled)
      )
      setStaffByType(
        allStaffByType
          .filter((staff) => staff.branchId === kid?.branchId)
          .filter((staff) => !staff.disabled)
      )
    }
  }

  const createDataOneStaff = async (data) => {
    setLoading(true)
    try {
      const { date, timeStart, timeEnd, course, staff } = data
      const selectedStaff = staffsByType.find((item) => item.id === staff)

      const res = await createSchedule(
        JSON.stringify({
          ...data,
          staffId: selectedStaff.id,
          staff: selectedStaff,
          date: date.format('YYYY-MM-DD'),
          timeStart,
          timeEnd,
          kidId,
          kid,
          programName: course.label,
          checkboxValue,
          action: 'createSchedule',
        })
      )

      if (res?.data?.isAvailable) {
        if (res.data.result) {
          setResult(res.data.result)
          setLoading(false)
        } else {
          toast({
            position: 'top',
            title: 'Update Schedule',
            description: 'Schedule has been added.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
          onClose()
        }
      } else {
        throw new Error(res.data?.message)
      }
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Add Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const createDataManyStaff = async (data) => {
    setLoading(true)
    try {
      const { date, programs, course } = data

      const staffsArray = programs.map((p) => ({
        ...p,
        staffId: p.staff,
        staff: staffsByType.find((item) => item.id === p.staff),
      }))

      const res = await createScheduleManyStaff(
        JSON.stringify({
          date: date.format('YYYY-MM-DD'),
          kidId,
          kid,
          programName: course.label,
          staffs: staffsArray,
          course,
          action: 'createSchedule',
        })
      )

      if (res?.data?.isAvailable) {
        if (res.data.result) {
          setResult(res.data.result)
          setLoading(false)
        } else {
          toast({
            position: 'top',
            title: 'Update Schedule',
            description: 'Schedule has been added.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          setLoading(false)
          onClose()
        }
      } else {
        throw new Error(res.data?.message)
      }
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Add Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const create = (data) => {
    if (countStaff > 1) {
      createDataManyStaff(data)
    } else {
      createDataOneStaff(data)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={result.length > 0 ? '2xl' : 'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Add Program</ModalHeader>
        <ModalCloseButton />
        {result.length > 0 ? (
          <ModalBody p={5}>
            <TableContainer>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Time</Th>
                    <Th>Staff</Th>
                    <Th>Result</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {result.map(
                    (
                      { date, timeEnd, timeStart, message, isAvailable, staff },
                      index
                    ) => (
                      <Tr key={index} spacing={10}>
                        <Td>{moment(date).format('DD/MM/YYYY')}</Td>
                        <Td>{`${timeStart}-${timeEnd} `}</Td>
                        <Td>{`${staff.nickname} (${staff.fullname})`}</Td>
                        <Td color={isAvailable ? 'green' : 'red'}>{message}</Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        ) : (
          <ModalBody p={10}>
            <FormControl
              my={3}
              id='course'
              name='course'
              isInvalid={true}
              w={'100%'}
            >
              <FormLabel>Program</FormLabel>
              <Controller
                name='course'
                control={control}
                rules={{
                  required: 'Please select a course',
                }}
                defaultValue={''}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id='course'
                    name='course'
                    placeholder='Select course'
                    options={courses}
                    formatGroupLabel={formatGroupLabel}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <FormErrorMessage>
                {errors.course && errors.course.message}
              </FormErrorMessage>
            </FormControl>
            {selectedCourse && selectedCourse !== '' && (
              <>
                <FormControl
                  my={3}
                  id='course'
                  name='course'
                  isInvalid={true}
                  w={'100%'}
                >
                  <FormLabel>
                    {watch('date') ? (
                      <>
                        {new Date(watch('date')).toLocaleDateString('th-TH', {
                          weekday: 'long',
                        })}{' '}
                        {moment(watch('date')).format('DD/MM/YYYY')}
                      </>
                    ) : (
                      <>
                        {Number(selectedCourse?.value?.amount) > 1
                          ? 'วันที่เริ่ม'
                          : 'วันที่เรียน'}
                        <span style={{ color: 'red' }}> *</span>
                      </>
                    )}
                  </FormLabel>
                  <Controller
                    name='date'
                    control={control}
                    rules={{
                      required: 'Please select a date',
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        status={errors.date && 'error'}
                        id='date'
                        name='date'
                        placeholder='Select date'
                        onChange={onChange}
                        value={value}
                        format='DD/MM/YYYY'
                        disabled={selectedCourse === '' || !selectedCourse}
                        // disabledDate={disabledDate}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.date && errors.date.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl my={3} id='course' name='course' w={'100%'}>
                  <FormLabel>จำนวนครั้งที่เรียนต่อสัปดาห์</FormLabel>
                  <NumberInput
                    value={countStaff}
                    min={1}
                    onChange={(v) => setCountStaff(v)}
                    isReadOnly={Number(selectedCourse?.value?.amount) === 1}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </>
            )}

            {/* // Only 1 Staff // */}

            {Number(countStaff) === 1 &&
              selectedCourse !== '' &&
              selectedCourse &&
              selectedDate && (
                <>
                  <FormInputs
                    errors={errors}
                    control={control}
                    forms={[
                      {
                        label: `ครูผู้สอน`,
                        name: 'staff',
                        type: 'dropdown',
                        options:
                          staffs.map((item) => ({
                            value: item.id,
                            data: item,
                            label: `${item.nickname} (${item.fullname})`,
                          })) || [],
                        placeholder: '',
                        defaultValue: '',
                        required: true,
                        disabled:
                          selectedCourse === '' ||
                          !selectedCourse ||
                          !selectedDate,
                      },
                    ]}
                    data={null}
                  />
                  {Number(countStaff) === 1 &&
                    kidStatus === 'member' &&
                    Number(selectedCourse?.value?.amount) > 1 &&
                    selectedCourse &&
                    selectedDate &&
                    selectedStaff && (
                      <FormControl my={3} id='course' name='course' w={'100%'}>
                        <FormLabel>วันที่ต้องการลงเรียน</FormLabel>

                        <SimpleGrid columns={4} spacing={2}>
                          {weekday.map((day, index) => {
                            return (
                              <Checkbox
                                colorScheme='lime'
                                isChecked={checkboxValue?.includes(
                                  index.toString()
                                )}
                                onChange={() => {
                                  if (
                                    checkboxValue?.includes(index.toString())
                                  ) {
                                    setCheckboxValue([])
                                  } else {
                                    setCheckboxValue([index.toString()])
                                  }
                                }}
                                key={day}
                                isDisabled={!staffWorkingDays.includes(day)}
                              >
                                {day}
                              </Checkbox>
                            )
                          })}
                        </SimpleGrid>
                      </FormControl>
                    )}
                  <FormInputs
                    errors={errors}
                    control={control}
                    forms={corseCheckedForm(Time, staffs) || []}
                    data={null}
                  />
                </>
              )}

            {/* // End of 1 Staff // */}

            {Number(countStaff) > 1 &&
              fields.length > 1 &&
              fields.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Center>
                      <Heading size='sm'>No. {i + 1}</Heading>
                    </Center>
                    <FormControl
                      key={`programs.${i}.staff`}
                      mb='3'
                      isInvalid={errors.programs?.[i]?.['staff']}
                      id={`programs[${i}]name`}
                    >
                      <FormLabel>{`ครูผู้สอน`}</FormLabel>
                      <ChakraSelect
                        placeholder={`Please Select`}
                        {...register(`programs.${i}.staff`, {
                          required: true,
                        })}
                        name={`programs.${i}.staff`}
                        type='text'
                      >
                        {staffsByType.map((s, index) => {
                          return (
                            <option
                              key={`select-${s.id}-${index}`}
                              value={s.id}
                            >
                              {`${s.nickname} (${s.fullname})`}
                            </option>
                          )
                        })}
                      </ChakraSelect>
                      <FormErrorMessage>
                        {errors.programs?.[i]?.['staff'] &&
                          `Please select Staff`}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      my={3}
                      id='course'
                      name='course'
                      w={'100%'}
                      isInvalid={errors.programs?.[i]?.['days']}
                    >
                      <FormLabel>วันที่ต้องการลงเรียน</FormLabel>

                      <SimpleGrid columns={4} spacing={2}>
                        {weekday.map((day, index) => (
                          <Checkbox
                            defaultValue={[]}
                            colorScheme='lime'
                            isChecked={watchPrograms[i]?.['days']?.includes(
                              index.toString()
                            )}
                            onChange={() => {
                              if (
                                watchPrograms[i]?.['days']?.includes(
                                  index.toString()
                                )
                              ) {
                                setValue(`programs.${i}`, {
                                  ...watchPrograms[i],
                                  days: [],
                                })
                              } else {
                                setValue(`programs.${i}`, {
                                  ...watchPrograms[i],
                                  days: [index.toString()],
                                })
                              }
                            }}
                            key={day}
                            isDisabled={
                              !getStaffWorkingDays(
                                watchPrograms[i]?.['staff']
                              ).includes(day)
                            }
                          >
                            {day}
                          </Checkbox>
                        ))}
                      </SimpleGrid>
                      <FormErrorMessage>
                        {errors.programs?.[i]?.['days'] && `Please select day`}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      key={`programs.${i}.timeStart`}
                      mb='3'
                      isInvalid={errors.programs?.[i]?.['timeStart']}
                      id={`programs[${i}]timeStart`}
                    >
                      <FormLabel>{`เวลาเริ่ม`}</FormLabel>
                      <ChakraSelect
                        placeholder={`Please Select`}
                        {...register(`programs.${i}.timeStart`, {
                          required: true,
                        })}
                        name={`programs.${i}.timeStart`}
                        type='text'
                        defaultValue={item.timeStart}
                        onChange={(e) => {
                          const start = e.target.value
                          const end = getStaffTimeEnd(start)
                          setValue(
                            `programs.${i}`,
                            {
                              ...watchPrograms[i],
                              timeStart: start,
                              timeEnd: end,
                            },
                            { shouldValidate: true }
                          )
                        }}
                        isDisabled={!watchPrograms[i]?.['staff']}
                      >
                        {getStaffTime(
                          watchPrograms[i]?.['staff'],
                          watchPrograms[i]?.['days']
                        )
                          .slice(0, `-${selectedCourse?.value?.hours * 2}`)
                          .map((s, index) => {
                            return (
                              <option key={`select-${s}-${index}`} value={s}>
                                {s}
                              </option>
                            )
                          })}
                      </ChakraSelect>
                      <FormErrorMessage>
                        {errors.programs?.[i]?.['timeStart'] &&
                          `Please select timeStart`}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      key={`programs.${i}.timeEnd`}
                      mb='3'
                      isInvalid={errors.programs?.[i]?.['timeEnd']}
                      id={`programs[${i}]timeEnd`}
                    >
                      <FormLabel>{`เวลาเลิก`}</FormLabel>
                      <ChakraSelect
                        placeholder={`Please Select`}
                        {...register(`programs.${i}.timeEnd`, {
                          required: true,
                        })}
                        name={`programs.${i}.timeEnd`}
                        type='text'
                        defaultValue={item.timeEnd}
                        isDisabled={true}
                      >
                        {getStaffTime(
                          watchPrograms[i]?.['staff'],
                          watchPrograms[i]?.['days']
                        ).map((s, index) => {
                          return (
                            <option key={`select-${s}-${index}`} value={s}>
                              {s}
                            </option>
                          )
                        })}
                      </ChakraSelect>
                      <FormErrorMessage>
                        {errors.programs?.[i]?.['timeEnd'] &&
                          `Please select timeEnd`}
                      </FormErrorMessage>
                    </FormControl>

                    <Divider my={2} />
                  </Fragment>
                )
              })}
          </ModalBody>
        )}

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          {result.length === 0 && (
            <Button
              colorScheme='lime'
              onClick={handleSubmit(create)}
              bg='brand'
              isLoading={isLoading}
            >
              Submit
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
