import { useState } from 'react'

import {
  Input,
  Select,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  CheckboxGroup,
  InputGroup,
  InputRightElement,
  IconButton,
  NumberInput,
  NumberInputField,
  Flex,
} from '@chakra-ui/react'
import { BiShow, BiHide } from 'react-icons/bi'
import { Select as ReactSelect } from 'chakra-react-select'

const InputComponent = ({
  options,
  defaultValue,
  type,
  readonly,
  disabled,
  disableOnEdit,
  ...rest
}) => {
  const [showPass, setShowPass] = useState(false)
  const handleClick = () => setShowPass(!showPass)

  // currency
  const format = (val) => Number(val).toLocaleString('th-TH')
  const parse = (val) => val.replace(/^\$/, '')

  switch (type) {
    case 'password':
      return (
        <InputGroup>
          <Input
            {...rest}
            type={showPass ? 'text' : 'password'}
            isReadOnly={readonly}
            isDisabled={disableOnEdit}
          />
          <InputRightElement width='4.5rem'>
            <IconButton h='1.75rem' size='lg' onClick={handleClick}>
              {showPass ? <BiHide /> : <BiShow />}
            </IconButton>
          </InputRightElement>
        </InputGroup>
      )
    case 'text':
      return (
        <Input
          {...rest}
          type={type}
          isReadOnly={readonly}
          isDisabled={disableOnEdit}
        />
      )
    case 'email':
      return (
        <Input
          {...rest}
          type='email'
          isReadOnly={readonly}
          isDisabled={disableOnEdit}
        />
      )
    case 'number':
      return (
        <Input
          {...rest}
          type='number'
          isReadOnly={readonly}
          onWheel={(e) => e.target.blur()}
          isDisabled={disableOnEdit}
        />
      )
    case 'number-format':
      return (
        <NumberInput
          {...rest}
          isReadOnly={readonly}
          type='number'
          defaultValue={format(rest.defaultValue)}
          onChange={(valueString) => {
            rest.onChange(parse(valueString))
          }}
          value={format(rest.value)}
          isDisabled={disableOnEdit}
        >
          <NumberInputField />
        </NumberInput>
      )
    case 'textarea':
      return (
        <Textarea
          {...rest}
          type={type}
          rows='5'
          isReadOnly={readonly}
          isDisabled={disableOnEdit}
        />
      )
    case 'date':
      return (
        <Input
          {...rest}
          type='date'
          isReadOnly={readonly}
          isDisabled={disableOnEdit}
        />
      )
    case 'time':
      return (
        <Input
          {...rest}
          type='time'
          isReadOnly={readonly}
          isDisabled={disableOnEdit}
        />
      )
    case 'react-select':
      const selectOptions =
        options &&
        options.map((option) => ({
          label: option,
          value: option,
        }))
      return (
        <ReactSelect
          {...rest}
          value={{
            label: rest.value,
            value: rest.value,
          }}
          isDisabled={disableOnEdit}
          onChange={(e) => rest.onChange(e.value)}
          placeholder={`Please Select`}
          _placeholder={{ opacity: 0.4, color: '#595959' }}
          options={selectOptions}
          chakraStyles={{
            dropdownIndicator: (provided, state) => ({
              ...provided,
              p: 0,
              w: '40px',
              color: 'black',
            }),
            indicatorSeparator: (provided) => ({
              borderWidth: 0,
            }),
            control: (provided, state) => ({
              ...provided,
              borderBottomLeftRadius: state.menuIsOpen ? 0 : 'md',
              borderBottomRightRadius: state.menuIsOpen ? 0 : 'md',
              transitionDuration: 0,
              px: 0,
              py: 0,
              my: 0,
              mx: 0,
            }),
            menu: (provided) => ({
              ...provided,
              my: 0,
              py: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }),
            menuList: (provided) => ({
              ...provided,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderWidth: 0,
            }),
          }}
        />
      )
    case 'dropdown':
      return (
        <Select
          {...rest}
          placeholder={`Please Select`}
          isReadOnly={readonly}
          isDisabled={disabled || disableOnEdit}
        >
          {options?.map((item, index) => {
            const optionType = typeof item
            return optionType === 'string' ? (
              <option key={`select-${item}-${index}`} value={item}>
                {item}
              </option>
            ) : (
              <option
                key={`select-${item?.value}-${index}`}
                value={item?.value}
              >
                {item?.label}
              </option>
            )
          })}
        </Select>
      )
    case 'radio':
      return (
        <RadioGroup
          {...rest}
          placeholder={`Please Select`}
          my={2}
          colorScheme='lime'
        >
          <Stack spacing={[1, 3, 5]} direction={['column', 'column', 'row']}>
            {options?.map((item, index) => {
              const optionType = typeof item
              return optionType === 'string' ? (
                <Radio
                  key={`select-${item}-${index}`}
                  value={item}
                  isDisabled={disabled}
                >
                  {item}
                </Radio>
              ) : (
                <Radio
                  key={`select-${item}-${index}`}
                  value={item.value}
                  isDisabled={disabled}
                >
                  {item.label}
                </Radio>
              )
            })}
          </Stack>
        </RadioGroup>
      )
    case 'checkbox':
      return (
        <CheckboxGroup colorScheme='lime' {...rest} isReadOnly={readonly}>
          <Flex
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
              xl: 'row',
            }}
            // justifyContent={'center'}
            alignItems={'start'}
          >
            {/* <HStack spacing={[1, 3, 5]} flexDirection={{ base: 'column', sm: 'column', md: 'row' }}> */}
            {options?.map((item, index) => (
              <Checkbox
                key={`select-${item}-${index}`}
                value={item}
                isDisabled={disabled}
                px={'10px'}
              >
                {item}
              </Checkbox>
            ))}
          </Flex>
        </CheckboxGroup>
      )
    default:
      return (
        <Input
          {...rest}
          type={type}
          isReadOnly={readonly}
          isDisabled={disableOnEdit}
        />
      )
  }
}

export default InputComponent
