import { db, functions, storage } from 'config/firebase'
import {
  query,
  collection,
  orderBy,
  deleteDoc,
  doc,
  addDoc,
  updateDoc,
  where,
  getDocs,
} from 'firebase/firestore'
import { ref, getDownloadURL } from 'firebase/storage'
import { httpsCallable } from 'firebase/functions'
import { useCollection } from '@lemasc/swr-firestore'

const onCancelPayment = httpsCallable(functions, 'cancelPayment')
const reCreateVoidPDF = httpsCallable(functions, 'voidReceiptPDF')

export default function usePayment() {
  const { data: paymentTypes } = useCollection(
    'PaymentTypes',
    {
      constraints: [orderBy('createdAt', 'desc')],
    },
    {
      listen: true,
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: 0,
    }
  )

  const getDownloadURLToken = async (pdfRef) => {
    try {
      return await getDownloadURL(ref(storage, pdfRef))
    } catch (err) {
      console.log(`${err}`)
    }
  }

  const deletePayment = (id) => {
    return deleteDoc(doc(db, 'Payments', id))
  }

  const addPaymentType = (data) => {
    return addDoc(collection(db, 'PaymentTypes'), data)
  }

  const updatePaymentType = (id, data) => {
    return updateDoc(doc(db, 'PaymentTypes', id), data)
  }

  const deletePaymentType = (id) => {
    return deleteDoc(doc(db, 'PaymentTypes', id))
  }

  const cancelPayment = async (paymentId, kidId, data) => {
    return onCancelPayment({ paymentId, refCode: data.ref }).then(async () => {
      return await createVoidPDF({ paymentId })
    })
  }

  const createVoidPDF = async (paymentId) => {
    return await reCreateVoidPDF({ paymentId })
  }

  const getPaymentByKidId = async (kidId) => {
    const paymentsSnap = await (
      await getDocs(
        query(collection(db, `Payments`), where('kidId', '==', kidId))
      )
    ).docs.map((doc) => ({ ...doc.data(), id: doc.id }))

    return paymentsSnap
  }

  return {
    paymentTypes: paymentTypes || [],
    deletePayment,
    addPaymentType,
    updatePaymentType,
    deletePaymentType,
    cancelPayment,
    getDownloadURLToken,
    getPaymentByKidId,
    createVoidPDF,
  }
}
