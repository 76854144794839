/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'
import { db, functions } from 'config/firebase'
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  getDocs,
  where,
  updateDoc,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

export default function useKid(id, branchId) {
  const delelteKidProgramAndSchedules = httpsCallable(
    functions,
    'delelteKidProgramAndSchedules'
  )
  const createPDFProfile = httpsCallable(functions, 'createPDFProfile')
  const removeProgramMaynyKid = httpsCallable(
    functions,
    'removeProgramMaynyKid'
  )
  const [kid, setKidData] = useState()
  const [kidCourses, setKidCouse] = useState([])
  const [evaluationList, setdataEvaluationList] = useState([])
  const [activeList, setActiveList] = useState([])
  const [docList, setdataDocList] = useState([])

  const [isLoading, setLoading] = useState(true)
  const [isLoadingActiveList, setLoadingLoadingActiveList] = useState(true)
  const [isLoadingCourse, setLoadingLoadingCourse] = useState(true)

  useEffect(() => {
    if (id) {
      setLoading(true)

      const queryKid = query(doc(db, 'Kids', id))
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        setKidData({
          ...snapShot.data(),
          id: snapShot.id,
        })

        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setLoading(true)

      const queryKid = query(
        collection(db, `Kids/${id}/Programs`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, async (snapShot) => {
        const data =
          (await Promise.all(
            snapShot.docs.map(async (doc) => {
              const querySchedules = query(
                collection(db, 'Schedules'),
                where('programId', '==', doc.id)
              )
              const schedules = await (
                await getDocs(querySchedules)
              ).docs.map((s) => ({ ...s.data(), id: s.id }))

              return { ...doc.data(), id: doc.id, schedules }
            })
          )) || []

        setKidCouse(data)
        setLoadingLoadingCourse(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setLoading(true)
      const queryKid = query(
        collection(db, `Kids/${id}/Evaluation`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setdataEvaluationList(data)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setLoadingLoadingActiveList(true)

      const queryKid = query(
        collection(db, `Kids/${id}/Status`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKid, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setActiveList(data)
        setLoadingLoadingActiveList(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setLoading(true)

      const queryKidDoc = query(
        collection(db, `Kids/${id}/Documents`),
        orderBy('createdAt', 'desc')
      )
      const unsubscribe = onSnapshot(queryKidDoc, (snapShot) => {
        const data = []
        snapShot.forEach((snapShot) => {
          data.push({ ...snapShot.data(), id: snapShot.id })
        })
        setdataDocList(data)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [id])

  const deleteKid = async (id) => {
    return deleteDoc(doc(db, 'Kids', id))
  }

  const deleteKidCollection = async (id, collection, docId) => {
    return deleteDoc(doc(db, `Kids/${id}/${collection}`, docId))
  }

  const updateKid = async (id, data) => {
    const kidRef = doc(db, 'Kids', `${id}`)

    return setDoc(kidRef, data, { merge: true })
  }

  const getKidsProgramById = async (programId) => {
    setLoading(true)
    if (programId) {
      const querySchedules = query(
        collection(db, 'Schedules'),
        where('programId', '==', programId)
      )
      const docSnap = await getDocs(querySchedules)
      setLoading(false)

      return docSnap.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
    }
  }

  const getKidById = async (kidId) => {
    const docSnap = await getDoc(doc(db, `Kids`, kidId))

    return { ...docSnap.data(), id: docSnap.id }
  }

  const updateKidProgram = async (kidId, programId, data) => {
    const kidRef = doc(db, `Kids/${id}/Programs`, `${programId}`)

    return updateDoc(kidRef, data, { merge: true })
  }

  const deleteKidProgram = async (program, kidId) => {
    try {
      setLoading(true)
      await delelteKidProgramAndSchedules({ programId: program.id, kidId })
    } catch (e) {
      setLoading(false)
      return e
    } finally {
      setLoading(false)
    }
  }

  const getAllKids = useCallback(async (branchId) => {
    let queryKids = query(
      collection(db, 'Kids'),
      orderBy('branchId', 'desc'),
      orderBy('createdAt', 'desc')
    )
    if (branchId) {
      queryKids = query(
        collection(db, 'Kids'),
        where('branchId', '==', branchId),
        orderBy('createdAt', 'desc')
      )
    }

    const snapShot = await getDocs(queryKids)

    let data = snapShot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    const evaluation = data
      .filter((doc) => doc.status === 'evaluation')
      .sort((a, b) => b.createdAt - a.createdAt)
    const member = data
      .filter((doc) => doc.status === 'member' && doc.code !== 'KID CODE')
      .sort((a, b) => b.code.localeCompare(a.code))

    const off = data
      .filter((doc) => doc.status === 'off' && doc.code !== 'KID CODE')
      .sort((a, b) => b.code.localeCompare(a.code))

    return [...evaluation, ...member, ...off]
  }, [])

  const getAllKidsNoOff = useCallback(async (branchId) => {
    let queryKids = query(
      collection(db, 'Kids'),
      orderBy('branchId', 'desc'),
      orderBy('createdAt', 'desc')
    )
    if (branchId) {
      queryKids = query(
        collection(db, 'Kids'),
        where('branchId', '==', branchId),
        orderBy('createdAt', 'desc')
      )
    }

    const snapShot = await getDocs(queryKids)

    let data = snapShot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    const evaluation = data
      .filter((doc) => doc.status === 'evaluation')
      .sort((a, b) => b.createdAt - a.createdAt)
    const member = data
      .filter((doc) => doc.status === 'member' && doc.code !== 'KID CODE')
      .sort((a, b) => b.code.localeCompare(a.code))

    const off = data
      .filter((doc) => doc.status === 'off' && doc.code !== 'KID CODE')
      .sort((a, b) => b.code.localeCompare(a.code))
    return [...evaluation, ...member, ...off]
  }, [])

  return {
    kid,
    isLoading,
    isLoadingCourse,
    isLoadingActiveList,
    setLoading,
    deleteKid,
    updateKid,
    kidCourses,
    evaluationList,
    activeList,
    deleteKidCollection,
    docList,
    getKidsProgramById,
    getKidById,
    deleteKidProgram,
    createPDFProfile,
    getAllKids,
    removeProgramMaynyKid,
    updateKidProgram,
    getAllKidsNoOff,
  }
}
