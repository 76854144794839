/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useMemo } from 'react'
import {
  Center,
  Button,
  Box,
  HStack,
  Heading,
  SimpleGrid,
  useToast,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'

import useKid from 'hooks/useKid'
import FormInputs from 'components/DynamicForm'

const developmentForm = (role) => [
  {
    label: 'การวินิจฉัยโรค',
    name: 'diagnosis',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: 'วันที่วินิจฉัยโรคครั้งแรก',
    name: 'treatmentDate',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `แพทย์`,
    name: 'doctor',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `โรงพยาบาล`,
    name: 'hospital',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `อายุเมื่อทราบความผิดปกติ`,
    name: 'ageObserve',
    type: 'number',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `โรคประจำตัว`,
    name: 'congenitalDisease',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `อาหารที่แพ้`,
    name: 'allergicFood',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `ยาที่แพ้`,
    name: 'allergicDrug',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `รูปแบบการรักษาที่เคยได้รับ/สถานที่`,
    name: 'treatment',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },

  {
    label: `พัฒนาการด้านที่ต้องการสร้างเสริม`,
    name: 'expectedDevelopment',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
  {
    label: `พัฒนาการ`,
    name: 'development',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
  },
]

export default function KidForm({ role, user, kid }) {
  const { id } = useParams()
  const toast = useToast()
  const [isSubmit, setSubmitting] = useState(false)
  const { updateKid } = useKid()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm()

  function submit(values) {
    setSubmitting(true)
    if (data?.id) {
      update(values)
    }
  }

  const data = useMemo(() => {
    return kid || null
  }, [kid])

  async function update(values) {
    try {
      await updateKid(id, {
        development: {
          ...values,
          updatedAt: new Date(),
          updatedBy: user.uid,
        },
      })

      setSubmitting(false)
      toast({
        position: 'top',
        Header: 'Update Kid',
        description: 'Kid has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      //   navigate('/kids')
    } catch (e) {
      setSubmitting(false)
      toast({
        position: 'top',
        Header: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/kids'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Box p={id ? 0 : 10}>
        {!kid ? (
          <Center h='70vh'>
            <div className='custom-loader' />
          </Center>
        ) : (
          <>
            <Center my={5}>
              <Heading>DEVELOPMENT</Heading>
            </Center>
            <Heading as='h5' size='sm' my={2} color='brand'>
              Development Information
            </Heading>
            <Box
              p={5}
              borderRadius={10}
              mb={10}
              borderWidth='1px'
              borderColor='brand'
            >
              <Box>
                <SimpleGrid
                  columns={[1, 1, 2, 2]}
                  spacingX={[1, 1, 1, '25px']}
                  justifyContent='center'
                  alignItems='center'
                >
                  <FormInputs
                    errors={errors}
                    control={control}
                    forms={developmentForm(role) || []}
                    data={data && data?.development}
                  />
                </SimpleGrid>
              </Box>
            </Box>

            {role !== 'Staff' && (
              <HStack justifyContent='center' my={10}>
                <Button mr={3} variant='outline' onClick={onOpen}>
                  Cancel
                </Button>
                <Button
                  colorScheme='lime'
                  bg='brand'
                  onClick={handleSubmit(submit)}
                  isLoading={isSubmit}
                >
                  Submit
                </Button>
              </HStack>
            )}
          </>
        )}
      </Box>
    </>
  )
}
