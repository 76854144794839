import { useMemo, useState } from 'react'
import {
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Popconfirm } from 'antd'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import useBranch from 'hooks/useBranch'
import Table from 'components/Horizons/table'

import BranchModal from './Modal/branch'
import SpinnerOverlay from 'components/SpinnerOverlay'

export default function BracnhInfo({ user }) {
  const { deleteBranch, branches: branchesList, isLoading } = useBranch()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editData, setEdit] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const branches = useMemo(() => {
    if (branchesList) return branchesList

    return []
  }, [branchesList])

  const columns = [
    {
      Header: 'Branch Code',
      accessor: 'code',
      key: 'code',
      width: '20%',
    },
    {
      Header: 'Type',
      accessor: 'accountType',
      key: 'code',
      width: '20%',
    },
    {
      Header: 'Province',
      accessor: 'province',
      key: 'province',
      width: '20%',
      extra: (data) => {
        if (data === 'bk') {
          return `BK (กรุงเทพ)`
        } else if (data === 'cm') {
          return `CM (เชียงใหม่)`
        }
      },
    },
    {
      Header: 'Branch Name',
      accessor: 'name',
      key: 'name',
      width: '20%',
    },
    {
      Header: 'Company Name',
      accessor: 'companyName',
      key: 'companyName',
      width: '20%',
    },
    {
      Header: 'Action',
      accessor: 'Action',
      key: 'Action',
      width: '10%',
      extra: (data, row) => {
        return (
          <HStack justifyContent='space-between'>
            <HStack spacing={2}>
              <IconButton
                variant='ghost'
                aria-label='edit'
                icon={<AiFillEdit color='#8CC63E' fontSize='25px' />}
                onClick={() => {
                  setEdit(row)
                  onOpen()
                }}
              />
              <Popconfirm
                title='Sure to delete this Branch'
                onConfirm={() => {
                  handleDelete(row.id)
                }}
              >
                <IconButton
                  variant='ghost'
                  aria-label='delete'
                  icon={<AiFillDelete color='red' fontSize='25px' />}
                />
              </Popconfirm>
            </HStack>
          </HStack>
        )
      },
    },
  ]
  // eslint-disable-next-line

  async function handleDelete(id) {
    setSubmitting(true)

    try {
      await deleteBranch(id)
      toast({
        position: 'top',
        Header: 'Delete Branch',
        description: 'Branch has been Deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      toast({
        position: 'top',
        Header: 'Delete Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <VStack>
        <SpinnerOverlay loading={submitting} />

        {isOpen && (
          <BranchModal
            isOpen={isOpen}
            onClose={() => {
              setEdit(null)
              onClose()
            }}
            user={user}
            data={editData}
          />
        )}
        <Heading>BRANCHES</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Button
            variant='solid'
            colorScheme='orange'
            bg='#F6872B'
            onClick={onOpen}
            user={user}
          >
            Create Branch
          </Button>
        </Flex>
      </VStack>
      <Table columnsData={columns} tableData={branches} loading={isLoading} />
    </>
  )
}
