/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react'
import {
  Box,
  Flex,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  HStack,
  Heading,
  Center,
  IconButton,
  Checkbox,
} from '@chakra-ui/react'
import { search } from 'ss-search'
import { useFirestoreQuery } from '@react-query-firebase/firestore'
import { query, collection } from 'firebase/firestore'
import { db, functions } from 'config/firebase'
import axios from 'axios'
import Table from 'components/Horizons/table'
import moment from 'moment'
import { FaFileExport } from 'react-icons/fa'
import { httpsCallable } from 'firebase/functions'
import { DatePicker } from 'antd'
import { where, orderBy } from '@lemasc/swr-firestore/constraints'
import useBranch from 'hooks/useBranch'

const getCreateExcelEventLogs = httpsCallable(functions, 'createExcelEventLogs')

export default function EventLog({ user, role }) {
  const { branches: BranchesList } = useBranch()

  const branches = useMemo(() => {
    if (BranchesList) {
      return BranchesList
    }
    return []
  }, [BranchesList])

  const [downloadIsLoading, setDownloadIsLoading] = useState(false)
  const managerBranchId =
    role === 'Manager' || role === 'Staff' ? user?.branchId : null
  const [isChecked, setIsChecked] = useState(false)
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || '',
    status: '',
    event: '',
    month: moment().format('YYYY-MM-DD'),
  })

  const eventRef = useMemo(() => {
    let start = new Date(moment(searchQuery?.month).startOf('day'))
    let end = new Date(moment(searchQuery?.month).endOf('day'))

    if (isChecked) {
      start = new Date(moment(searchQuery?.month).startOf('month'))
      end = new Date(moment(searchQuery?.month).endOf('month'))
    }

    return query(
      collection(db, 'EventLogs'),
      where('branch.code', '==', searchQuery?.branch),
      where('createdAt', '>=', start),
      where('createdAt', '<=', end),
      orderBy('createdAt', 'desc')
    )
  }, [searchQuery?.branch, searchQuery?.month, isChecked])

  // Provide the query to the hook
  const { data, isLoading } = useFirestoreQuery(
    ['eventLogs', searchQuery?.branch, searchQuery?.month, isChecked],
    eventRef,
    {
      subscribe: false,
    },
    {
      select(snapshot) {
        return snapshot?.docs?.map((docSnapshot) => ({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        }))
      },
    }
  )

  const logs = useMemo(() => {
    if (data) {
      let textSearch = searchQuery.text
      const searchQueryArr = data
        .filter((log) =>
          searchQuery.branch !== ''
            ? log.branch?.code === searchQuery.branch
            : log
        )
        .filter((log) =>
          searchQuery.event !== '' ? log.event === searchQuery.event : log
        )
      const searchKeys = [
        'kid.nickname',
        'kid.code',
        'data.staff.fullname',
        'data.staff.nickname',
      ]
      const results = search(searchQueryArr, searchKeys, textSearch)
      const searchResults = textSearch === '' ? searchQueryArr : results
      return searchResults || []
    }
    return []
  }, [data, searchQuery])

  function handleSearch(e) {
    e.preventDefault()
  }

  async function handleDownloadFile(dataDownload) {
    setDownloadIsLoading(true)
    getCreateExcelEventLogs(JSON.stringify(dataDownload)).then(async (i) => {
      const url = i.data.fileUrl
      const fileName = i.data.fileName
      await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${fileName}`)
          document.body.appendChild(link)
          link.click()
          setDownloadIsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          setDownloadIsLoading(false)
        })
      setDownloadIsLoading(false)
    })
  }

  const EventText = (event) => {
    let text = ''
    switch (event) {
      case 'CompletedSchedule':
        text = 'Completed Schedule'
        break
      case 'AddProgramAndSchedules':
        text = 'Add New Program and Schedules'
        break
      case 'DeleteProgramAndSchedules':
        text = 'Delete Program and Schedules'
        break
      case 'CanceledSchedule':
        text = 'Canceled Schedule'
        break
      case 'AddNewScheduleAfterCanceled':
        text = 'Add NewSchedule After Canceled'
        break
      case 'UpdateSchedule':
        text = 'Update Schedule'
        break
      case 'ReSchedule':
        text = 'Re Schedule'
        break
      default:
        break
    }
    return text
  }

  const eventText = [
    {
      label: 'Completed Schedule',
      value: 'CompletedSchedule',
    },
    {
      label: 'Add New Program and Schedules',
      value: 'AddProgramAndSchedules',
    },
    {
      label: 'Delete Program and Schedules',
      value: 'DeleteProgramAndSchedules',
    },
    {
      label: 'Add NewSchedule After Canceled',
      value: 'AddNewScheduleAfterCanceled',
    },
    {
      label: 'Update Schedule',
      value: 'UpdateSchedule',
    },
    {
      label: 'Re Schedule',
      value: 'ReSchedule',
    },
    {
      label: 'Canceled Schedule',
      value: 'CanceledSchedule',
    },
  ]

  const columns = useMemo(() => {
    return [
      // {
      //   Header: 'ID',
      //   accessor: 'id',
      //   key: 'id',
      //   width: '15%',
      // },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        key: 'createdAt',
        width: '12%',
        extra: (createdAt) => {
          const date = createdAt?.toDate()?.toLocaleString() || ''
          return date
        },
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        key: 'createdBy',
        width: '12%',
        extra: (data) => {
          return `${data?.fullname || ''} ${
            data?.nickname ? `(${data?.nickname})` : 'contact@craftinglab.co'
          }`
        },
      },

      {
        Header: 'Event',
        accessor: 'event',
        key: 'event',
        width: '12%',
        extra: (event) => {
          return EventText(event) || event
        },
      },
      {
        Header: 'Branch',
        accessor: 'branch.name',
        key: 'branch.name',
        width: '15%',
      },
      {
        Header: 'Kid',
        accessor: 'kid',
        key: 'kid',
        extra: (data) => {
          return `${data?.code || ''} (${data?.nickname || ''})`
        },
      },
      {
        Header: 'Staff',
        accessor: 'data.staff',
        key: 'staff',

        extra: (staff) => {
          return staff
            ? `${staff?.fullname || ''} (${staff?.nickname || ''})`
            : '-'
        },
      },
      {
        Header: 'Course',
        accessor: 'data',
        key: 'course',
        extra: (course) => {
          return (
            <>
              <Text>
                {`${course?.courseName || course?.program?.courseName} (${
                  course?.choice || course?.program?.choice
                }) ${
                  course?.completedNo
                    ? `ครั้งที่ ${course?.completedNo} / ${
                        course?.program?.amount || 0
                      }`
                    : ''
                } `}
              </Text>
              <Text>{`${
                course?.date ? moment(course?.date).format('DD/MM/YYYY') : ''
              } ${
                course?.timeStart
                  ? `, ${course?.timeStart} - ${course?.timeEnd}`
                  : ''
              }`}</Text>
            </>
          )
        },
      },
    ]
  }, [])

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <Center pb={4}>
        <Heading>Event Logs</Heading>
      </Center>
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          p={5}
        >
          <HStack
            spacing={{ base: 0, md: '20px' }}
            flexDirection={{ base: 'column', sm: 'column', md: 'row' }}
          >
            <FormControl>
              <FormLabel>Search</FormLabel>
              <Input
                id='search'
                event='search'
                placeholder='Search by Name'
                onChange={(e) =>
                  setSearch({ ...searchQuery, text: e.target.value })
                }
                value={searchQuery.text}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Event</FormLabel>
              <Select
                id='event'
                placeholder='ทั้งหมด'
                onChange={(e) =>
                  setSearch({ ...searchQuery, event: e.target.value })
                }
                value={searchQuery.event}
              >
                {eventText.map((e) => (
                  <option key={e.value} value={e.value}>
                    {e.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                placeholder='Please Select Branch'
                onChange={(e) =>
                  setSearch({ ...searchQuery, branch: e.target.value })
                }
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.code}>
                    {branch.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <HStack w='full' justifyContent='space-between'>
                <FormLabel>{!isChecked ? 'Date' : 'Month'}</FormLabel>
                <Checkbox
                  colorScheme='lime'
                  onChange={(e) => {
                    setIsChecked(e.target.checked)
                    if (!e.target.checked) {
                      setSearch({
                        ...searchQuery,
                        date: moment().format('YYYY-MM'),
                      })
                    }
                  }}
                >
                  เลือกแบบเดือน
                </Checkbox>
              </HStack>
              {!isChecked ? (
                <DatePicker
                  allowClear={false}
                  format={'DD MMMM YYYY'}
                  value={moment(searchQuery.month)}
                  onChange={(date) => {
                    setSearch({
                      ...searchQuery,
                      month: moment(date).format('YYYY-MM-DD'),
                      text: '',
                    })
                  }}
                />
              ) : (
                <DatePicker
                  allowClear={false}
                  picker='month'
                  format={'MMMM YYYY'}
                  value={moment(searchQuery.month)}
                  onChange={(date) =>
                    setSearch({
                      ...searchQuery,
                      month: moment(date).format('YYYY-MM'),
                      text: '',
                    })
                  }
                />
              )}
            </FormControl>
            <HStack alignSelf={'end'}>
              <IconButton
                variant='outline'
                fontSize={'23px'}
                icon={<FaFileExport color='#8CC63E' />}
                onClick={() => {
                  handleDownloadFile(logs)
                }}
                disabled={downloadIsLoading || !logs.length > 0}
                isLoading={downloadIsLoading}
              />
            </HStack>
          </HStack>
          <Flex
            justifyContent={{ base: 'center', lg: 'flex-end' }}
            w='full'
            mt={4}
          >
            <HStack mt={3}>
              <Button
                variant='outline'
                onClick={() => {
                  setSearch({ text: '', branch: '', status: '', event: '' })
                  // setPaymentQuery(allPayment)
                }}
              >
                Clear
              </Button>
              {/* <Button variant='solid' colorScheme='twitter' event='submit'>
                Search
              </Button> */}
            </HStack>
          </Flex>
        </Box>
      </form>

      <Flex
        justifyContent='space-between'
        w='full'
        my={4}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Table
          columnsData={columns}
          tableData={logs}
          loading={isLoading}
          noSearch
        />
      </Flex>
    </Box>
  )
}
