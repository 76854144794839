/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { DatePicker } from 'antd'

import moment from 'moment'

import FormInputs from 'components/DynamicForm'

import useSchedule from 'hooks/useSchedule'

export default function UpdateCourseModal({
  isOpen,
  onClose,
  data,
  kidId,
  staffs,
  kidBranchId,
  courseLabel,
  selectedCourse,
  user,
}) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const initialRef = useRef(null)
  const { checkUpdateAvailable, updateSchedule } = useSchedule({})
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm()
  const selectedStaff = watch('staffId')
  const startTime = watch('timeStart')
  const selectedDate = watch('date')

  let Time = useMemo(() => {
    if (selectedStaff && selectedStaff !== '') {
      let isCamp = data?.program?.type === 'Camp'
      const staff = staffs.find((item) => item.id === selectedStaff)
      if (staff) {
        const isWeekend = new Date(selectedDate).getDay() % 6 === 0
        const {
          startTimeWeekend,
          startTimeWorkday,
          endTimeWeekend,
          endTimeWorkday,
        } = staff
        let start = isWeekend || isCamp ? startTimeWeekend : startTimeWorkday
        let end = isWeekend ? endTimeWeekend : endTimeWorkday

        return [...Array(48)]
          .map((e, i) => {
            return (
              (i / 2 < 10 ? '0' : '') +
              (i / 2 - ((i / 2) % 1)) +
              ((i / 2) % 1 !== 0 ? '.30' : '.00')
            )
          })
          .filter(
            (time) =>
              Number(time) >= Number(start.replace(':', '.')) &&
              Number(time) <= Number(end.replace(':', '.'))
          )
      }
    }
  }, [data, selectedStaff, selectedDate, staffs])

  useEffect(() => {
    if (selectedStaff && selectedStaff !== data?.staffId) {
      setValue('date', '')
    }
  }, [selectedStaff])

  useEffect(() => {
    if (startTime && startTime !== '') {
      const offsetTime = data?.program?.hours * 60
      const timeSplit = startTime.split('.')
      let hours = Number(timeSplit[0])
      let minutes = Number(timeSplit[1])
      let date = new Date().setHours(hours, minutes)
      const endTime = moment(date).add(offsetTime, 'm').format('HH.mm')
      setValue('timeEnd', endTime)
    }
  }, [startTime])

  const corseCheckedForm = [
    {
      label: `ครูผู้สอน`,
      name: 'staffId',
      type: 'dropdown',
      options: staffs
        .filter((staff) => {
          return staff.branchId === kidBranchId
        })
        .map((staff) => ({
          label: `${staff.nickname} (${staff.fullname})`,
          value: staff.id,
        })),
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]

  const corseCheckedForm1 = [
    {
      label: `เวลาเริ่ม`,
      name: 'timeStart',
      type: 'dropdown',
      options: Time?.filter(
        (item, i) => i < Time.length - selectedCourse?.value?.hours * 2
      ),
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: `เวลาเลิก`,
      name: 'timeEnd',
      type: 'dropdown',
      options: Time,
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: true,
    },
  ]

  async function updateTakedCouse(values) {
    try {
      setLoading(true)
      const { staffId, date, timeStart, timeEnd, isTaked } = values
      const res = await checkUpdateAvailable({
        staffId: staffId,
        date: date.format('YYYY-MM-DD'),
        timeStart,
        timeEnd,
        kidId,
        programName: courseLabel,
        id: data.id,
        program: data?.program,
      })

      if (res?.data?.isAvailable) {
        const staff = staffs.find((s) => {
          return s.id === staffId
        })

        const action =
          !data.isTaked && values.isTaked
            ? 'completedSchedule'
            : 'updateSchedule'

        await updateSchedule({
          scheduleId: data.id,
          data: {
            ...values,
            staff,
            isTaked,
            date: date.format('YYYY-MM-DD'),
            updatedAt: new Date(),
            updatedBy: user.uid,
            programId: data.programId,
            action,
            kidId,
          },
        })

        toast({
          position: 'top',
          title: 'Update Schedule',
          description: 'Schedule has been updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onClose()
        setLoading(false)
      } else {
        throw new Error(res.data?.message)
      }
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
      scrollBehavior={'inside'}
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader textAlign='center'>Update Program</ModalHeader>
        <ModalBody px={10} ref={initialRef}>
          <FormInputs
            errors={errors}
            control={control}
            forms={corseCheckedForm || []}
            data={data}
          />
          <FormControl
            my={3}
            id='course'
            name='course'
            isInvalid={true}
            w={'100%'}
          >
            <FormLabel>
              วันที่<span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Controller
              name='date'
              control={control}
              rules={{
                required: 'Please select a date',
              }}
              defaultValue={moment(data?.date)}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  status={errors.date && 'error'}
                  id='date'
                  name='date'
                  placeholder='Select date'
                  onChange={onChange}
                  value={value}
                  format='DD/MM/YYYY'
                  // disabledDate={disabledDate}
                  disabled={!selectedStaff}
                />
              )}
            />
            <FormErrorMessage>
              {errors.date && errors.date.message}
            </FormErrorMessage>
          </FormControl>
          <FormInputs
            errors={errors}
            control={control}
            forms={corseCheckedForm1 || []}
            data={data}
          />
          {(user.role === 'Manager' || user.role === 'Owner') && (
            <FormControl
              my={3}
              id='isTaked'
              name='isTaked'
              isInvalid={true}
              w={'100%'}
            >
              <FormLabel>Completed</FormLabel>
              <Controller
                name='isTaked'
                control={control}
                defaultValue={data.isTaked}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Switch
                      colorScheme='lime'
                      size='lg'
                      isChecked={value}
                      onChange={onChange}
                      isDisabled={data?.isTaked}
                    />
                  )
                }}
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(updateTakedCouse)}
            bg='brand'
            isLoading={isLoading}
          >
            Update{' '}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
