import { useState, useRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Image,
  Center,
  Input,
  Text,
  Icon,
  Flex,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'
import { db, storage } from 'config/firebase'
import { doc, addDoc, collection, writeBatch } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import { MdOutlineCloudUpload } from 'react-icons/md'

import SpinnerOverlay from 'components/SpinnerOverlay'

import FormInputs from 'components/DynamicForm'
import { branchForm } from '../fields'

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'jpg',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export default function BranchModal({ isOpen, onClose, data, user }) {
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const [fileUpload, setFileUpload] = useState(null)
  const hiddenFileInput = useRef()
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm()
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const brand = useColorModeValue('brand', 'brand')
  const bg = useColorModeValue('gray.100', 'navy.700')
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100')

  function handleUploadClick() {
    hiddenFileInput.current.click()
  }

  async function handleUploadChange(e) {
    e.preventDefault()
    const fileUploaded = e.target.files[0]
    if (
      fileUploaded?.type === 'image/jpeg' ||
      fileUploaded?.type === 'image/png'
    ) {
      const resizeFileUpload = await resizeFile(fileUploaded)
      setFileUpload(resizeFileUpload)
    } else {
      setFileUpload(null)
      toast({
        Header: 'อัพโหลดไฟล์',
        position: 'top',
        description: 'ใช้ไฟล์รูปภาพ JPG, JPEG เท่านั้น',
        type: 'info',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  function submit(values) {
    if (data?.id) {
      updateBranch(values)
    } else {
      createBranch(values)
    }
  }

  async function createBranch(values) {
    setLoading(true)
    try {
      const batch = writeBatch(db)

      const newBranch = await addDoc(collection(db, 'Branches'), {
        ...values,
        createdAt: new Date(),
      }).then((branch) => branch)

      const branchRef = doc(db, 'Branches', newBranch.id)

      if (fileUpload) {
        const promises = []
        const storageRef = ref(storage, `Branches/${newBranch.id}`)
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        batch.set(branchRef, {
          ...values,
          createdAt: new Date(),
          createdBy: user.uid,
          picture: downloadURL,
          code: values?.code.replace(/\s/g, ''),
        })
      } else {
        batch.set(branchRef, {
          ...values,
          createdAt: new Date(),
          createdBy: user.uid,
          code: values?.code.replace(/\s/g, ''),
        })
      }

      batch.commit()
      toast({
        position: 'top',
        Header: 'Create Account',
        description: 'Branch has been created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      onClose()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function updateBranch(values) {
    setLoading(true)
    try {
      const batch = writeBatch(db)

      const branchRef = doc(db, 'Branches', data.id)

      if (fileUpload) {
        const promises = []
        const storageRef = ref(storage, `Branches/${data.id}`)
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        batch.set(
          branchRef,
          {
            ...values,
            updatedAt: new Date(),
            updatedBy: user.uid,
            picture: downloadURL,
            code: values?.code.replace(/\s/g, ''),
          },
          { merge: true }
        )
      } else {
        batch.set(
          branchRef,
          {
            ...values,
            updatedAt: new Date(),
            updatedBy: user.uid,
            code: values?.code.replace(/\s/g, ''),
          },
          { merge: true }
        )
      }

      await batch.commit()
      toast({
        position: 'top',
        Header: 'Update Branch',
        description: 'Branch has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      onClose()
    } catch (e) {
      toast({
        position: 'top',
        Header: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }

  return (
    <>
      <SpinnerOverlay loading={isLoading} />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'lg'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign='center'>SETTING BRANCH</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            <Center>
              {fileUpload ? (
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  bg={bg}
                  border='1px dashed'
                  borderColor={borderColor}
                  borderRadius='16px'
                  w='100%'
                  maxW='100%'
                  h='max-content'
                  minH='130px'
                  cursor='pointer'
                  py='10px !important'
                  onClick={handleUploadClick}
                >
                  <Image
                    src={URL.createObjectURL(fileUpload)}
                    cursor='pointer'
                    bg='gray.50'
                    size='2xl'
                    h={150}
                  />
                </Flex>
              ) : data?.picture ? (
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  bg={bg}
                  border='1px dashed'
                  borderColor={borderColor}
                  borderRadius='16px'
                  w='100%'
                  maxW='100%'
                  h='max-content'
                  minH='130px'
                  cursor='pointer'
                  py='10px !important'
                  onClick={handleUploadClick}
                >
                  <Image
                    src={data?.picture}
                    cursor='pointer'
                    bg='gray.50'
                    size='2xl'
                    h={150}
                  />
                </Flex>
              ) : (
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  bg={bg}
                  border='1px dashed'
                  borderColor={borderColor}
                  borderRadius='16px'
                  w='100%'
                  maxW='100%'
                  h='max-content'
                  minH='130px'
                  cursor='pointer'
                  py='10px !important'
                  onClick={handleUploadClick}
                >
                  <Stack alignItems='center' justifyContent='center'>
                    <Icon
                      as={MdOutlineCloudUpload}
                      w='80px'
                      h='80px'
                      color={textColor}
                    />
                    <Text
                      mx='auto'
                      mb='12px'
                      fontSize='lg'
                      fontWeight='700'
                      whiteSpace='pre-wrap'
                      color={textColor}
                    >
                      Browse your{' '}
                      <Text
                        as='span'
                        fontSize='lg'
                        fontWeight='700'
                        color={brand}
                      >
                        Logo
                      </Text>
                    </Text>
                    <Text
                      fontSize='sm'
                      fontWeight='500'
                      color='secondaryGray.500'
                    >
                      PNG, JPG and GIF files are allowed
                    </Text>
                  </Stack>
                </Flex>
              )}
              <Input
                type='file'
                display='none'
                ref={hiddenFileInput}
                onChange={handleUploadChange}
              />
            </Center>
            <FormInputs
              errors={errors}
              control={control}
              forms={branchForm || []}
              data={data}
            />
          </ModalBody>

          <ModalFooter justifyContent='center'>
            <Button mr={3} variant='outline' onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme='lime'
              onClick={handleSubmit(submit)}
              bg='brand'
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
