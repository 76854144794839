/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import {
  Flex,
  Heading,
  Button,
  useDisclosure,
  Center,
  Box,
  Stack,
  HStack,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import { deleteDoc, doc } from 'firebase/firestore'
import { Popconfirm } from 'antd'
import Table from 'components/Horizons/table'

import { useParams } from 'react-router-dom'
import { db, storage } from 'config/firebase'
import {
  MdOutlineHighlightOff,
  MdOutlineDownloadForOffline,
} from 'react-icons/md'
import { ref, deleteObject } from 'firebase/storage'

import useKid from 'hooks/useKid'

import AddDocModal from './Modals/AddDoc'

export default function Document({ role }) {
  const { id } = useParams()
  const toast = useToast()
  const { isLoading, docList } = useKid(id)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const downloadPdf = async (data) => {
    // const downloadUrl = await getDownloadURLToken(path)
    const { url } = data
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) newWindow.opener = null
  }

  async function handleDelete(row) {
    try {
      const { id: docId, url } = row
      const desertRef = ref(storage, url)

      await deleteDoc(doc(db, `Kids/${id}/Documents`, docId))
      // Delete the file
      await deleteObject(desertRef)

      toast({
        position: 'top',
        title: 'Delete Payment',
        description: 'Payment has been Deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        position: 'top',
        title: 'Delete Fail.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Title',
        accessor: 'title',
        key: 'title',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        key: 'createdAt',
        extra: (date) => date.toDate().toLocaleDateString(),
      },
      {
        Header: '',
        accessor: 'Action',
        key: 'Action',
        width: '10%',
        align: 'center',
        extra: (data, row) => {
          return (
            role !== 'Staff' && (
              <HStack spacing={0} m={0} p={0}>
                <IconButton
                  onClick={() => downloadPdf(row)}
                  variant='ghost'
                  aria-label='download'
                  icon={
                    <MdOutlineDownloadForOffline
                      color='green'
                      fontSize='25px'
                    />
                  }
                />

                <Popconfirm
                  title='Sure to delete this Document'
                  onConfirm={() => {
                    handleDelete(row)
                  }}
                >
                  <IconButton
                    variant='ghost'
                    aria-label='cancel'
                    icon={<MdOutlineHighlightOff color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            )
          )
        },
      },
    ]
  }, [])

  return (
    <>
      <Center my='5'>
        <Heading>DOCUMENTS</Heading>
      </Center>

      <Stack spacing={10}>
        <Box>
          <Flex
            justifyContent='space-between'
            alignItems={'flex-end'}
            w={'100%'}
            my={2}
          >
            <Heading as='h5' size='sm' color='brand'>
              Documents
            </Heading>
            {role !== 'Staff' && (
              <Button
                variant='solid'
                colorScheme='#F6872B'
                bg='#F6872B'
                onClick={onOpen}
              >
                Add Documents
              </Button>
            )}
          </Flex>
          <Box
            borderWidth='1px'
            borderColor={'brand'}
            borderRadius='12px'
            p={5}
          >
            {isOpen && (
              <AddDocModal
                isOpen={isOpen}
                onClose={() => {
                  onClose()
                }}
                kidId={id}
              />
            )}
            <Table
              columnsData={columns}
              tableData={docList || []}
              loading={isLoading}
              noSearch
            />
          </Box>
        </Box>
      </Stack>
    </>
  )
}
