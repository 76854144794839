/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Text,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import moment from 'moment'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { db } from 'config/firebase'
import {
  addDoc,
  collection,
  query,
  onSnapshot,
  where,
} from 'firebase/firestore'

import useStaff from 'hooks/useStaff'
import useSchedule from 'hooks/useSchedule'

import FormInputs from 'components/DynamicForm'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data) => (
  <Box {...groupStyles}>
    <Text fontSize='lg'>{data.name}</Text>
    <span style={groupBadgeStyles}>{data.programs.length}</span>
  </Box>
)

export default function NewKidModal({
  isOpen,
  onClose,
  managerBranchId,
  branches,
  selectedBranch,
  user,
  branchProvince,
}) {
  const { getStaffByType } = useStaff()
  const toast = useToast()

  const [isLoading, setLoading] = useState(false)
  const [courses, setCourseList] = useState([])
  const [staffs, setStaff] = useState([])
  const { checkScheduleAvailable, addSchedule } = useSchedule({})

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm()

  const selectedCourse = watch('course')
  const startTime = watch('timeStart')
  const selectedDate = watch('date')
  const selectedStaff = watch('staff')
  const watchBranch = watch('branchId')

  useEffect(() => {
    const querySnap = query(
      collection(db, 'Courses'),
      where('province', 'in', ['all', branchProvince])
    )
    const unsubscribe = onSnapshot(querySnap, (snapShot) => {
      let data = []
      snapShot.forEach((doc) => {
        const options = doc.data().programs.map((program) => ({
          value: {
            courseId: doc.id,
            courseName: doc.data().name,
            coursDescription: doc.data().description,
            hours: doc.data().hours,
            score: doc.data().score,
            type: doc.data().type,
            ...program,
          },
          label: `${program.choice} (${program.amount} ${program.unit})`,
        }))
        data.push({ ...doc.data(), id: doc.id, options })
      })

      data = data.filter((item) => item.type === 'Evaluation')

      setCourseList(data)
    })
    return () => {
      unsubscribe()
    }
  }, [branchProvince])
  useEffect(() => {
    if (managerBranchId || selectedBranch) {
      setValue('branchId', managerBranchId || selectedBranch)
    }
  }, [managerBranchId, selectedBranch])

  useEffect(() => {
    if (selectedCourse && selectedCourse !== '') {
      getStaff(selectedCourse?.value?.type)
    }
  }, [selectedCourse, watchBranch])

  useEffect(() => {
    if (
      startTime &&
      startTime !== '' &&
      selectedCourse &&
      selectedCourse !== ''
    ) {
      const offsetTime = selectedCourse?.value?.hours * 60
      const timeSplit = startTime.split('.')
      let hours = Number(timeSplit[0])
      let minutes = Number(timeSplit[1])
      let date = new Date().setHours(hours, minutes)
      const endTime = moment(date).add(offsetTime, 'm').format('HH.mm')
      setValue('timeEnd', endTime)
    }
  }, [startTime])

  // const disabledDate = (current) => {
  //   const staff = staffs.filter((item) => item.id === selectedStaff)
  //   if (staff && staff[0]) {
  //     const { workingDay, workingDayWeekend } = staff && staff[0]
  //     const weekday = [
  //       'Sunday',
  //       'Monday',
  //       'Tuesday',
  //       'Wednesday',
  //       'Thursday',
  //       'Friday',
  //       'Saturday',
  //     ]
  //     const disabledDates = weekday.map(
  //       (day, index) =>
  //         !workingDay.concat(workingDayWeekend).includes(day) && index
  //     )

  //     return (
  //       current < moment().startOf('day') ||
  //       moment(current).day() ===
  //         disabledDates.find((date) => date === moment(current).day())
  //     )
  //   }
  // }

  const kidForm = [
    {
      label: 'สาขา / BRANCH',
      name: 'branchId',
      type: 'dropdown',
      options: branches
        .filter((branch) => {
          if (managerBranchId) {
            return branch.id === managerBranchId
          } else {
            return branch
          }
        })
        .map((doc) => ({ value: doc.id, label: doc.name })),
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: managerBranchId,
    },
    {
      label: `ชื่อเล่นเด็ก`,
      name: 'nickname',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: `เบอร์ติดต่อ`,
      name: 'telephone',
      type: 'number',
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: true,
      rules: {
        maxLength: {
          value: 10,
          message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
        },
        minLength: {
          value: 10,
          message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
        },
      },
    },
    {
      label: `ชื่อ - นามสกุล ผู้ปกครอง`,
      name: 'parentName1',
      type: 'text',
      defaultValue: '',
      required: true,
    },

    {
      label: 'เบอร์ติดต่อผู้ปกครอง',
      name: 'parentTel1',
      type: 'number',
      defaultValue: '',
      required: true,
      rules: {
        maxLength: {
          value: 10,
          message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
        },
        minLength: {
          value: 10,
          message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
        },
      },
    },
    {
      label: 'ทราบข้อมูล KIDSPLUS จาก /  KNOW KIDSPLUS FROM',
      name: 'from',
      type: 'text',
      defaultValue: '',
      required: true,
    },
  ]

  const corseCheckedForm = (Time, staffs) => [
    {
      label: `เวลาเริ่ม`,
      name: 'timeStart',
      type: 'dropdown',
      options: Time?.filter(
        (item, i) => i < Time.length - selectedCourse?.value?.hours * 2
      ),
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled:
        selectedCourse === '' ||
        !selectedCourse ||
        !selectedStaff ||
        !selectedDate,
    },
    {
      label: `เวลาเลิก`,
      name: 'timeEnd',
      type: 'dropdown',
      options: Time,
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: true,
    },
  ]

  let Time = useMemo(() => {
    if (selectedStaff && selectedStaff !== '') {
      // let isCamp = selectedCourse?.value?.type === 'Camp'
      const staff = staffs.filter((item) => item.id === selectedStaff)
      const isWeekend = new Date(selectedDate).getDay() % 6 === 0

      const {
        startTimeWeekend,
        startTimeWorkday,
        endTimeWeekend,
        endTimeWorkday,
      } = staff[0]

      let start = isWeekend ? startTimeWeekend : startTimeWorkday
      let end = isWeekend ? endTimeWeekend : endTimeWorkday

      return [...Array(48)]
        .map((e, i) => {
          return (
            (i / 2 < 10 ? '0' : '') +
            (i / 2 - ((i / 2) % 1)) +
            ((i / 2) % 1 !== 0 ? '.30' : '.00')
          )
        })
        .filter(
          (time) =>
            Number(time) >= Number(start.replace(':', '.')) &&
            Number(time) <= Number(end.replace(':', '.'))
        )
    }
  }, [selectedCourse, selectedStaff, selectedDate])

  const getStaff = async (type) => {
    const respone = await getStaffByType(type)
    setStaff(respone.filter((staff) => staff.branchId === watchBranch))
  }

  async function createEvaluation(data) {
    setLoading(true)
    try {
      const staff = staffs.filter((item) => item.id === data.staff)

      const kid = await addDoc(collection(db, 'Kids'), {
        nickname: data.nickname,
        telephone: data.telephone,
        createdAt: new Date(),
        createdBy: user.uid,
        status: 'evaluation',
        branchId: data.branchId,
        parentName1: data.parentName1,
        parentTel1: data.parentTel1,
        from: data.from,
      })

      const newProgram = await addDoc(
        collection(db, `Kids/${kid?.id}/Programs`),
        {
          ...data.course.value,
          createdAt: new Date(),
          createdBy: user.uid,
        }
      )

      await addSchedule({
        action: 'evaluationNewKid',
        programId: newProgram.id,
        program: { label: data.course.label, ...data.course.value },
        kidId: kid?.id,
        kid: {
          nickname: data.nickname,
          telephone: data.telephone,
          createdAt: new Date(),
          createdBy: user.uid,
          status: 'evaluation',
        },
        order: 0,
        isTaked: false,
        date: data.date,
        staff: staff[0],
        staffId: staff[0].id,
        timeStart: data.timeStart,
        timeEnd: data.timeEnd,
        createdAt: new Date(),
        createdBy: user.uid,
        updatedAt: new Date(),
        updatedBy: user.uid,
      })

      toast({
        position: 'top',
        Header: 'Update Schedule',
        description: 'Schedule has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      onClose()
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Add Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function create(data) {
    setLoading(true)
    try {
      const { staff, date, timeStart, timeEnd } = data
      const selectedStaff = staffs.find((item) => item.id === staff)
      const isAvailable = await checkScheduleAvailable(
        selectedStaff.id,
        date.format('YYYY-MM-DD'),
        timeStart,
        timeEnd,
        ''
      )

      if (isAvailable) {
        createEvaluation({ ...data, date: date.format('YYYY-MM-DD') })
      } else {
        throw new Error('Schedule already exist')
      }
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign='center'>Evaluate New Kid</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={10}>
          <FormInputs
            errors={errors}
            control={control}
            forms={kidForm}
            data={null}
          />
          <FormControl
            my={3}
            id='course'
            name='course'
            isInvalid={true}
            w={'100%'}
          >
            <FormLabel>Evaluate Program</FormLabel>
            <Controller
              name='course'
              control={control}
              rules={{
                required: 'Please select a course',
              }}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Select
                  id='course'
                  name='course'
                  placeholder='Select course'
                  options={courses}
                  formatGroupLabel={formatGroupLabel}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <FormErrorMessage>
              {errors.course && errors.course.message}
            </FormErrorMessage>
          </FormControl>
          <FormInputs
            errors={errors}
            control={control}
            forms={[
              {
                label: `ครูผู้สอน`,
                name: 'staff',
                type: 'dropdown',
                options:
                  staffs.map((item) => ({
                    value: item.id,
                    data: item,
                    label: `${item.nickname} (${item.fullname})`,
                  })) || [],
                placeholder: '',
                defaultValue: '',
                required: true,
                disabled: selectedCourse === '' || !selectedCourse,
              },
            ]}
            data={null}
          />
          <FormControl
            my={3}
            id='course'
            name='course'
            isInvalid={true}
            w={'100%'}
          >
            <FormLabel>
              วันที่<span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <Controller
              name='date'
              control={control}
              rules={{
                required: 'Please select a date',
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  status={errors.date && 'error'}
                  id='date'
                  name='date'
                  placeholder='Select date'
                  onChange={onChange}
                  value={value}
                  format='DD/MM/YYYY'
                  disabled={
                    selectedCourse === '' || !selectedCourse || !selectedStaff
                  }
                  // disabledDate={disabledDate}
                />
              )}
            />
            <FormErrorMessage>
              {errors.date && errors.date.message}
            </FormErrorMessage>
          </FormControl>

          <FormInputs
            errors={errors}
            control={control}
            forms={corseCheckedForm(Time, staffs) || []}
            data={null}
          />
        </ModalBody>

        <ModalFooter justifyContent='center'>
          <Button mr={3} variant='outline' onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='lime'
            onClick={handleSubmit(create)}
            bg='brand'
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
