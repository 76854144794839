/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Flex,
  Heading,
  VStack,
  HStack,
  Button,
  IconButton,
  useDisclosure,
  useToast,
  Text,
} from '@chakra-ui/react'
import { Popconfirm } from 'antd'
import { functions } from 'config/firebase'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { httpsCallable } from 'firebase/functions'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import Table from 'components/Horizons/table'
import SpinnerOverlay from 'components/SpinnerOverlay'

import useStaff from 'hooks/useStaff'
import useBranch from 'hooks/useBranch'

import Modal from './Modal/admin'

const deleteAccount = httpsCallable(functions, 'deleteAccount')

export default function AdminSetting() {
  const { admins: allStaff, isLoading } = useStaff()
  const { branches: allBranch } = useBranch()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editData, setEdit] = useState(null)
  const [staffQuery, setStaffQuery] = useState(allStaff)
  const [branches, setBranches] = useState()
  const navigate = useNavigate()
  const toast = useToast()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setStaffQuery(allStaff)
  }, [allStaff])

  useEffect(() => {
    const options = allBranch.map((branch) => branch.name)
    setBranches([
      {
        label: 'Branch',
        name: 'branch',
        type: 'dropdown',
        placeholder: '',
        defaultValue: '',
        required: true,
        options,
      },
    ])
  }, [allBranch])

  function handleDelete(id) {
    setSubmitting(true)
    deleteAccount({ uid: id })
      .then(() => {
        toast({
          position: 'top',
          Header: 'Delete Admin',
          description: 'Admin has been Deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
      .finally(() => setSubmitting(false))
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        key: 'createdAt',
        width: '15%',
        extra: (date) => moment(date.toDate()).format('DD/MM/YYYY'),
      },
      {
        Header: 'Email',
        accessor: 'email',
        key: 'email',
        width: '20%',
      },
      {
        Header: 'Full Name',
        accessor: 'fullname',
        key: 'fullname',
        width: '20%',
      },
      {
        Header: 'Nick Name',
        accessor: 'nickname',
        key: 'nickname',
        width: '20%',
      },
      {
        Header: 'Office Branch',
        accessor: 'branch',
        key: 'branch',
        width: '15%',
        extra: (data, row) => {
          const branch = allBranch.find((b) => b.id === row.branchId)
          return (
            <Text color={'black'} fontSize='sm'>
              {`${branch ? branch?.name : data}`}
            </Text>
          )
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        key: 'role',
        width: '10%',
      },
      {
        Header: 'Action',
        accessor: 'Action',
        key: 'Action',
        width: '10%',
        extra: (data, row) => {
          return (
            <HStack justifyContent='space-between'>
              <HStack spacing={2}>
                <IconButton
                  variant='ghost'
                  aria-label='edit'
                  icon={<AiFillEdit color='#8CC63E' fontSize='25px' />}
                  onClick={() => {
                    setEdit(row)
                    onOpen()
                  }}
                />
                <Popconfirm
                  title='Sure to delete this Admin'
                  onConfirm={() => {
                    handleDelete(row.id)
                  }}
                >
                  <IconButton
                    variant='ghost'
                    aria-label='delete'
                    icon={<AiFillDelete color='red' fontSize='25px' />}
                  />
                </Popconfirm>
              </HStack>
            </HStack>
          )
        },
      },
    ]
  }, [onOpen])

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <SpinnerOverlay loading={submitting} />
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings')}
        />
      </Flex>
      <VStack>
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              setEdit(null)
              onClose()
            }}
            data={editData}
            branches={branches}
            allBranch={allBranch}
          />
        )}
        <Heading>Manager</Heading>
        <Flex justifyContent='flex-end' w={'100%'}>
          <Button
            variant='solid'
            colorScheme='orange'
            bg='#F6872B'
            onClick={onOpen}
          >
            Create Manager
          </Button>
        </Flex>
      </VStack>
      <Table columnsData={columns} tableData={staffQuery} loading={isLoading} />
    </Box>
  )
}
