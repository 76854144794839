import {
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  Button,
  PopoverBody,
  Popover,
  PopoverTrigger,
  Stack,
  Box,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
} from '@chakra-ui/react'
import { Badge } from 'antd'
import { signOut } from 'firebase/auth'
import { auth, db } from 'config/firebase'
import { useNavigate } from 'react-router-dom'
import { BsPersonCircle } from 'react-icons/bs'
import { MdNotificationsNone } from 'react-icons/md'
import { useEffect, useState } from 'react'
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  writeBatch,
  doc,
  where,
  limit,
  startAfter,
} from 'firebase/firestore'
import moment from 'moment/moment'
import useIdle from 'hooks/useIdleTimeout'
import { useFirestoreInfiniteQuery } from '@react-query-firebase/firestore'
import { Fragment } from 'react'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'config/firebase'
const clearNotificationByUser = httpsCallable(
  functions,
  'clearNotificationByUser'
)

export default function UserProfile({ user }) {
  const toast = useToast()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [openModal, setOpenModal] = useState(false)
  const [notiSize, setNotiSize] = useState(0)

  const navigate = useNavigate()
  const handleIdle = () => setOpenModal(true)
  const handleLogout = () => {
    signOut(auth)
    setOpenModal(false)
  }
  useIdle({ onIdle: handleIdle, idleTime: 30 * 60 })

  const notiQuery = query(
    collection(db, `Profiles/${user.uid}/Notifications`),
    orderBy('createdAt', 'desc'),
    limit(20)
  )

  const { data, isLoading, fetchNextPage, refetch, isFetchingNextPage } =
    useFirestoreInfiniteQuery(
      'noti',
      notiQuery,
      (snapshot) => {
        const lastDocument = snapshot.docs[snapshot.docs.length - 1]
        // Get the next 20 documents starting after the last document fetched.
        return lastDocument && query(notiQuery, startAfter(lastDocument))
      },
      {
        idField: 'id',
        select(snapshot) {
          return snapshot.docs.map((docSnapshot) => ({
            ...docSnapshot.data(),
            id: docSnapshot.id,
          }))
        },
      }
    )

  function makeReadNotification(notiData, notiId) {
    if (!notiData.read) {
      const batch = writeBatch(db)
      batch.update(doc(db, `Profiles/${user.uid}/Notifications`, notiId), {
        read: true,
        updatedAt: new Date(),
      })
      batch.commit()
    }
    onClose()
    navigate(notiData.action)
  }

  function clearNotification() {
    clearNotificationByUser(user?.uid).then(() => {
      onClose()
      return toast({
        position: 'top',
        Header: 'Notification',
        description: 'Notification has been clear successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  useEffect(() => {
    const notiRef = query(
      collection(db, `Profiles/${user.uid}/Notifications`),
      where('read', '==', false),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(notiRef, (snapShot) => {
      setNotiSize(snapShot.size)
      refetch()
    })

    return () => {
      unsubscribe()
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Modal
        isCentered
        onClose={handleLogout}
        isOpen={openModal}
        motionPreset='slideInBottom'
        closeOnOverlayClick={false}
      >
        <ModalOverlay bg='blackAlpha.700' backdropFilter='blur(10px)' />
        <ModalContent>
          <ModalHeader>Session Timeout</ModalHeader>
          <ModalBody>
            <Text fontSize='xl'> Please sign in again</Text>
          </ModalBody>
          <ModalFooter>
            <Center w='100%'>
              <Button
                colorScheme='lime'
                mr={3}
                onClick={handleLogout}
                variant='outline'
              >
                Sign in again
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems='center'>
          <HStack spacing='4'>
            <VStack
              display={{ base: 'none', md: 'flex' }}
              alignItems='flex-end'
              spacing='1px'
              ml='2'
            >
              <Text fontSize='lg' color='white'>
                {user?.email}
              </Text>
              <Text fontSize='md' color='white'>
                {`${user?.role || ''} ${
                  user?.branch ? '(' + user?.branch + ')' : ''
                }`}
              </Text>
            </VStack>

            <Popover
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              closeOnBlur={true}
            >
              <Badge
                size='default'
                count={notiSize}
                offset={[-5, 5]}
                style={{ zIndex: 10 }}
                // bg=''
                // color=''
                // colorScheme={'#8CC63E'}
                // borderRadius='40%'
              >
                <PopoverTrigger>
                  <IconButton
                    icon={<MdNotificationsNone />}
                    color={'#8CC63E'}
                    fontSize={{ base: '20px', sm: '20px', md: '25px' }}
                    aria-label={''}
                  />
                </PopoverTrigger>
              </Badge>

              <PopoverContent mr='1.7rem'>
                <PopoverArrow />
                <PopoverHeader display='flex' justifyContent='space-between'>
                  <Text fontWeight='bold' color='#8CC63E'>
                    Notifications
                  </Text>
                  <Button
                    size='xs'
                    variant='outline'
                    onClick={clearNotification}
                  >
                    Clear All
                  </Button>
                </PopoverHeader>
                <PopoverBody
                  h='250px'
                  p='0px'
                  overflow='scroll'
                  overflowX='hidden'
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '10px',
                      background: '#BFBFBF',
                      borderRadius: '24px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '10px',
                      borderRadius: '24px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#DBC9EE',
                      borderRadius: '24px',
                    },
                  }}
                >
                  <Stack spacing='0.5px'>
                    {!isLoading &&
                      data?.pages.map((group, i) => (
                        <Fragment key={i}>
                          {group.docs.map((noti, k) => (
                            <Box
                              px='15px'
                              py='0.5rem'
                              cursor='pointer'
                              key={k}
                              bg={noti.data().read ? 'white' : 'brand'}
                              onClick={() => {
                                makeReadNotification(noti.data(), noti.id)
                              }}
                              _hover={{ bg: 'brand.100' }}
                              color={
                                noti.data()?.title === 'kidsPaidReminder'
                                  ? 'red.600'
                                  : noti.data().read
                                  ? 'brand'
                                  : 'black'
                              }
                            >
                              <HStack
                                h='auto'
                                spacing='3'
                                flexWrap='nowrap'
                                alignItems='center'
                              >
                                <VStack alignItems='baseline' spacing={0}>
                                  <Text fontSize='sm' noOfLines={3}>
                                    {moment(
                                      noti.data()?.createdAt?.toDate()
                                    ).format('DD/MM/YYYY HH:mm:ss')}
                                  </Text>
                                  <Text fontSize='md' noOfLines={3}>
                                    {noti.data()?.message}
                                  </Text>
                                </VStack>
                              </HStack>
                            </Box>
                          ))}
                        </Fragment>
                      ))}
                    {isFetchingNextPage ? (
                      <Center>Loading..</Center>
                    ) : (
                      notiSize > 20 && (
                        <Button onClick={() => fetchNextPage()}>
                          Load More..
                        </Button>
                      )
                    )}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>

          <Menu>
            <MenuButton
              as={IconButton}
              ml={4}
              transition='all 0.3s'
              icon={<BsPersonCircle color='#8CC63E' fontSize={20} />}
              _focus={{ boxShadow: 'none' }}
            ></MenuButton>
            <MenuList fontSize='lg' bg='white' borderColor='gray.200'>
              <MenuItem onClick={() => signOut(auth).then(navigate('/login'))}>
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </>
  )
}
