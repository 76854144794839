/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Center,
  Button,
  Box,
  HStack,
  Heading,
  SimpleGrid,
  useToast,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'

import useStaff from 'hooks/useStaff'
import useBranch from 'hooks/useBranch'
import SpinnerOverlay from 'components/SpinnerOverlay'
import FormInputs from 'components/DynamicForm'
import {
  staffForm,
  workForm,
  workingDayForm,
  weekendForm,
  workDayCheckbox,
  weekEndCheckbox,
} from './fields'

export default function StaffForm({ user, role }) {
  const managerBranchId =
    role === 'Manager' || role === 'Staff' ? user?.branchId : null

  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [branches, setBranches] = useState()
  const {
    staff: data,
    isLoading: isLoadingData,
    createAccount,
    updateAccount,
  } = useStaff(id)
  const { branches: allBranch } = useBranch()

  useEffect(() => {
    const options = allBranch.map((branch) => ({
      label: branch.name,
      value: branch.id,
    }))
    setBranches([
      {
        label: 'Branch',
        name: 'branchId',
        type: 'dropdown',
        placeholder: '',
        defaultValue: managerBranchId ? managerBranchId : '',
        required: true,
        options,
        disabled: managerBranchId,
      },
    ])
  }, [allBranch, managerBranchId])

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    // watch,
    // setValue,
  } = useForm()

  // const watchType = watch('type')

  // useEffect(() => {
  //   if (watchType === 'Part-time') {
  //     setValue('workingDay', [])
  //   }
  // }, [watchType])

  function submit(values) {
    if (data?.id) {
      update(values)
    } else {
      create(values)
    }
  }

  function create(values) {
    let branch = allBranch.find((branch) => branch?.id === values.branchId)

    setLoading(true)
    createAccount({
      ...values,
      role: 'Staff',
      branch: branch.name,
      branchId: branch.id,
    })
      .then(() => {
        toast({
          position: 'top',
          Header: 'Create Account',
          description: 'Staff has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        navigate('/settings/staff')
      })
      .catch((e) => {
        setLoading(false)
        toast({
          position: 'top',
          Header: 'Create Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  async function update(values) {
    try {
      let branch = allBranch.find((branch) => branch?.id === values.branchId)
      setLoading(true)
      await updateAccount({
        ...values,
        id: data.id,
        role: 'Staff',
        branch: branch.name,
        branchId: branch.id,
        updatedAt: new Date(),
      })
      toast({
        position: 'top',
        Header: 'Update Staff',
        description: 'Staff has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
      resetField()
      navigate('/settings/staff')
    } catch (e) {
      toast({
        position: 'top',
        Header: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    }
  }
  if (isLoadingData || (id && !data)) {
    return (
      <Center minH={'100vh'}>
        <SpinnerOverlay loading={true} />
      </Center>
    )
  }

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <SpinnerOverlay loading={isLoading} />
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate('/settings/staff')}
        />
      </Flex>
      <Center>
        <Heading>{`${id ? 'EDIT' : 'CREATE'} STAFF`}</Heading>
      </Center>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Staff Information
      </Heading>
      <Box
        p={5}
        border='1px solid brand'
        borderRadius={10}
        mb={5}
        borderWidth='1px'
        borderColor='brand'
      >
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={staffForm || []}
            data={data}
          />
        </Box>
      </Box>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Work Information
      </Heading>
      <Box
        p={5}
        border='1px solid brand'
        borderRadius={10}
        borderWidth='1px'
        borderColor='brand'
      >
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={branches || []}
            data={data}
          />
          <FormInputs
            errors={errors}
            control={control}
            forms={workForm || []}
            data={data}
          />
        </Box>

        {/* {watchType === 'Full-time' && (
          <> */}
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <Heading size='md' textColor={'brand'} py='10px'>
            Working Day
          </Heading>
        </Box>
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={workDayCheckbox || []}
            data={data}
          />
        </Box>
        <SimpleGrid
          mx={{ base: 0, md: 20, lg: 40, xl: 60 }}
          columns={[1, 2]}
          spacingX='40px'
        >
          <FormInputs
            errors={errors}
            control={control}
            forms={workingDayForm || []}
            data={data}
          />
        </SimpleGrid>
        {/* </>
        )} */}

        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <Heading size='md' textColor={'brand'} py='10px'>
            Weekend
          </Heading>
        </Box>

        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={weekEndCheckbox || []}
            data={data}
          />
        </Box>

        <SimpleGrid
          mx={{ base: 0, md: 20, lg: 40, xl: 60 }}
          columns={[1, 2]}
          spacingX='40px'
        >
          <FormInputs
            errors={errors}
            control={control}
            forms={weekendForm || []}
            data={data}
          />
        </SimpleGrid>
      </Box>

      <HStack justifyContent='center' my={10}>
        <Button
          mr={3}
          variant='outline'
          onClick={() => navigate('/settings/staff')}
          type='button'
        >
          Cancel
        </Button>
        <Button
          colorScheme='lime'
          onClick={handleSubmit(submit)}
          bg='brand'
          type='submit'
          isLoading={isLoading}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  )
}
