/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, useRef } from 'react'
import {
  Button,
  Box,
  HStack,
  Heading,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  IconButton,
  useToast,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  VStack,
  Text,
  Stack,
  Image,
  Center,
  Checkbox,
} from '@chakra-ui/react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AiFillDelete } from 'react-icons/ai'
import { db } from 'config/firebase'
import {
  onSnapshot,
  query,
  collection,
  addDoc,
  getDocs,
  orderBy,
  where,
} from 'firebase/firestore'
import { BiArrowBack } from 'react-icons/bi'
import Select from 'react-select'
import moment from 'moment'
import useKid from 'hooks/useKid'
import FormInputs from 'components/DynamicForm'

const label = {
  en: {
    kidCode: 'KID',
    name: 'Customer',
    branch: 'Branch',
    address: 'Address',
    payment: 'Payment',
    no: 'No.',
    description: 'Description',
    price: 'Amount (THB)',
    totalPrice: 'Total',
    currency: 'BAHT',
    bill: 'CASH SALE',
    receipt: 'RECEIPT',
    refund: 'Refund',
  },
  th: {
    kidCode: 'เด็ก',
    name: 'ชื่อลูกค้า',
    branch: 'สาขา',
    address: 'ที่อยู่',
    payment: 'การชำระเงิน',
    no: 'ลำดับ',
    description: 'รายการ',
    price: 'จำนวนเงินทีจ่ายไป (บาท)',
    totalPrice: 'รวมเป็นเงิน',
    currency: 'บาท',
    bill: 'สร้างบิลเงินสด',
    receipt: 'สร้างใบเสร็จรับเงิน',
    refund: 'จำนวนเงินที่คืน (บาท)',
  },
}

export default function RefundForm({ type, user, role }) {
  const managerBranchId =
    role === 'Manager' || role === 'Staff' ? user?.branchId : null
  const navigate = useNavigate()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [isLoadingData, setLoadingData] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [allKid, setAllKids] = useState()
  const [branches, setBranch] = useState([])
  const { getKidById } = useKid()
  const [kidId, setKidId] = useState('')
  const [lang, setLang] = useState('th')
  const [isLoadingProgram, setLoadingProgram] = useState(false)
  const [searchParams] = useSearchParams()
  const queryKid = searchParams.get('kid')
  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    register,
    getValues,
    watch,
    setValue,
  } = useForm()

  const watchPrograms = watch({ nest: true })
  const watchKid = watch(`kid`)
  const watchBranch = watch(`branchId`)

  const totalPrice = useMemo(() => {
    let array = getValues('programs') || []
    return (
      (array.length > 0 &&
        array.reduce(
          (acc, val) => acc + (val?.price ? Number(val.price) : 0),
          0
        )) ||
      0
    )
  }, [watchPrograms])

  const { fields, remove, replace } = useFieldArray({
    control,
    name: 'programs',
  })

  const branchForm = (branches) => [
    {
      label: label[lang]?.branch,
      name: 'branchId',
      type: 'dropdown',
      options: branches.map((doc) => ({
        value: doc.id,
        label: doc.name,
      })),
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: managerBranchId !== null,
    },
  ]

  const kidForm = [
    {
      label: label[lang]?.name,
      name: 'name',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
    },

    {
      label: label[lang]?.address,
      name: 'address',
      type: 'textarea',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]

  const programForm = [
    {
      label: label[lang]?.description,
      name: 'description',
      type: 'text',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
    {
      label: label[lang]?.price,
      name: 'paid',
      type: 'number',
      isReadOnly: true,
    },
    {
      label: label[lang]?.refund,
      name: 'price',
      type: 'number',
      placeholder: '',
      defaultValue: '',
      required: true,
    },
  ]

  useEffect(() => {
    if (watchKid?.value && watchKid?.value !== kidId) {
      resetField('programs')
      setKidId(watchKid?.value?.id)
      getPrograms(watchKid?.value?.id)
    }
  }, [watchKid, queryKid])

  useEffect(() => {
    if (queryKid) {
      getKidByIdFunction(queryKid)
    }
  }, [queryKid])

  useEffect(() => {
    const queryBranches = query(collection(db, 'Branches'))
    const unsubscribe = onSnapshot(queryBranches, (snapShot) => {
      let data = []
      let branches = []
      snapShot.forEach((doc) => {
        data.push(doc.data().name)
        branches.push({ ...doc.data(), id: doc.id })
      })
      setBranch(branches)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (watchBranch && watchBranch !== '') {
      if (!queryKid) {
        setValue('kid', null)
        setValue('name', '')
        setValue('address', '')
        getKids(watchBranch)
      }
    }
  }, [watchBranch])

  const getKidByIdFunction = async (kidId) => {
    const option = await getKidById(kidId)
    await setAllKids([
      {
        label: `${option?.code || ''} ${option.nickname} ${
          option?.name ? `(${option?.name})` : ''
        }`,
        value: { ...option, id: option.id },
      },
    ])

    setValue('kid', {
      label: `${option?.code || ''} ${option.nickname} ${
        option?.name ? `(${option?.name})` : ''
      }`,
      value: { ...option, id: option.id },
    })
    setValue('name', option.name)
    setValue('branchId', option.branchId)
    setValue('address', option.address)
    setKidId(queryKid)
    getPrograms(queryKid)
    setLoadingData(false)
  }

  const getKids = async (branchId) => {
    setLoadingData(true)
    const kidRef = query(
      collection(db, 'Kids'),
      where('branchId', '==', branchId),
      orderBy('code', 'desc')
    )
    const kidByBranchSnap = await getDocs(kidRef)
    const kidByBranch = kidByBranchSnap.docs.map((doc) => {
      return {
        label: `${doc.data()?.code || ''} ${doc.data().nickname} ${
          doc.data()?.name ? `(${doc.data()?.name})` : ''
        }`,
        value: { ...doc.data(), id: doc.id },
      }
    })

    setAllKids(kidByBranch)
    setLoadingData(false)
  }

  async function getPrograms(id) {
    try {
      setLoadingProgram(true)

      const statusSnap = await getDocs(
        query(collection(db, `Kids/${id}/Status`), orderBy('createdAt', 'desc'))
      )
      const programsSnap = await getDocs(
        query(
          collection(db, `Kids/${id}/Programs`),
          orderBy('createdAt', 'desc')
        )
      )

      const status = statusSnap.docs.map((doc) => {
        if (
          doc.data()?.payment?.status === 'paid' ||
          doc.data()?.payment?.status === 'remaining' ||
          doc.data()?.payment?.status === 'refund'
        ) {
          const sum =
            doc.data().payment?.transactions?.reduce((a, b) => {
              return b.action === 'paid'
                ? a + Number(b.paid)
                : b.action === 'refund'
                ? a - Number(b.paid)
                : a
            }, 0) || 0

          if (sum <= 0) return

          const docsRef =
            doc.data()?.payment?.transactions.map((doc) => ({
              paymentId: doc.paymentNo,
              paymentRef: doc.paymentRef,
            })) || []
          return {
            id: doc.id,
            description: `Member from ${moment(
              doc.data()?.activeDate.toDate()
            ).format('DD/MM/YYYY')} to ${moment(
              doc.data()?.expireDate.toDate()
            ).format('DD/MM/YYYY')}`,
            fullPrice: doc.data()?.price,
            price: sum,
            paid: sum,

            collection: 'Status',
            docsRef,
          }
        }
        return null
      })

      const programs = await Promise.all(
        programsSnap.docs.map(async (doc) => {
          if (
            doc.data()?.payment?.status === 'paid' ||
            doc.data()?.payment?.status === 'remaining'
          ) {
            const querySchedules = query(
              collection(db, 'Schedules'),
              where('programId', '==', doc.id),
              orderBy('order', 'asc')
            )
            const data = await getDocs(querySchedules)
            const docsRef =
              doc.data()?.payment?.transactions.map((doc) => ({
                paymentId: doc.paymentNo,
                paymentRef: doc.paymentRef,
              })) || []
            const firstLast = (
              await Promise.all(
                data.docs.map((d, i) =>
                  moment(d.data().date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                )
              )
            )
              .filter((doc) => doc && doc !== 'Invalid date' && doc !== '')
              .sort(function (a, b) {
                var aa = a.split('/').reverse().join(),
                  bb = b.split('/').reverse().join()
                return aa < bb ? -1 : aa > bb ? 1 : 0
              })
            const sum =
              doc.data()?.payment?.transactions?.reduce((a, b) => {
                return b.action === 'paid'
                  ? a + Number(b.paid)
                  : b.action === 'refund'
                  ? a - Number(b.paid)
                  : a
              }, 0) || 0
            if (sum <= 0) return

            return {
              id: doc.id,
              description: `${doc.data()?.courseName} ${
                doc.data()?.choice !== 'Evaluation' ? doc.data()?.choice : ''
              } ${firstLast[0]}${
                firstLast[firstLast.length - 1]
                  ? `- ${firstLast[firstLast.length - 1]}`
                  : ''
              }`,
              fullPrice: doc.data()?.price,
              price: sum,
              paid: sum,
              collection: 'Programs',
              docsRef,
            }
          }
        })
      )

      replace([...status.filter((e) => e), ...programs.filter((f) => f && f)])
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingProgram(false)
    }
  }

  async function createPayment(values) {
    setLoading(true)
    try {
      const { address, branchId, name, programs, kid } = values
      const branchInfo = branches.find((data) => data.id === branchId)
      const refundPrograms = programs.map((data) => ({
        collection: data.collection,
        description: data.description,
        docsRef: data.docsRef,
        fullPrice: data.fullPrice,
        id: data.id,
        price: data.price,
      }))
      const data = {
        address,
        branch: branchInfo,
        name,
        payment: 'refund',
        paymentId: '',
        paymentDate: moment().format('YYYY-MM-DD'),
        type: 'refund',
        kid: { label: kid.label, ...kid.value },
        kidId,
        totalPrice,
        programs: refundPrograms,
        createdAt: new Date(),
        createdBy: user?.email,
        status: 'refund',
        language: lang,
      }
      await addDoc(collection(db, 'Payments'), data)

      setTimeout(() => {
        toast({
          position: 'top',
          Header: 'Create Payment',
          description: 'Payment has been created.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        resetField()
        navigate(-1)
      }, 2000)
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme='red' ml={3} onClick={() => navigate(-1)}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Flex justifyContent='flex-start'>
        <IconButton
          size={30}
          variant='ghost'
          color='brand'
          icon={<BiArrowBack size={30} />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Flex justifyContent='space-between' mt={6}>
        <VStack alignItems={'start'} spacing={1}>
          <Heading fontSize='2xl' color='#767262'>
            {label[lang][type]}
          </Heading>
        </VStack>
        <HStack spacing={6}>
          <Box
            as='button'
            color='#767262'
            spacing={0}
            onClick={() => setLang('th')}
          >
            <Image src='/flags/th.svg' />
            <Text fontSize='sm' color='#767262'>
              ไทย
            </Text>
          </Box>
          <Box
            as='button'
            color='#767262'
            spacing={0}
            onClick={() => setLang('en')}
          >
            <Image src='/flags/en.svg' />
            <Text fontSize='sm' color='#767262'>
              EN
            </Text>
          </Box>
        </HStack>
      </Flex>
      <Box p={5} borderRadius={10} mb={5} borderWidth='1px' borderColor='brand'>
        <Box mx={{ base: 0, md: 20, lg: 40, xl: 60 }}>
          <FormInputs
            errors={errors}
            control={control}
            forms={branchForm(branches) || []}
            data={{ branchId: managerBranchId }}
          />
          <FormControl my={3} id='kid' name='kid' isInvalid={true} w={'100%'}>
            <FormLabel>{label[lang].kidCode}</FormLabel>
            <Controller
              name='kid'
              control={control}
              rules={{
                required: 'Please select KID',
              }}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={allKid}
                  onChange={(option) => {
                    onChange(option)
                    setValue('name', option?.value?.name || '')
                    setValue('branchId', option?.value?.branchId)
                    setValue('address', option?.value?.address)
                  }}
                  placeholder={
                    isLoadingData ? 'Loading data ...' : 'Please select'
                  }
                  value={value}
                  isLoading={isLoadingData}
                  isDisabled={isLoadingData || watchBranch === ''}
                  theme={(theme) => ({
                    ...theme,
                    padding: `0px 32px 1px 16px`,
                    border: `1px solid inherit`,
                    outline: `2px solid transparent`,
                    colors: {
                      ...theme.colors,
                      // primary25: 'blue',
                      primary: '#8CC63E',
                    },
                  })}
                />
              )}
            />
            <FormErrorMessage>
              {errors.kidId && errors.kidId.message}
            </FormErrorMessage>
          </FormControl>
          <FormInputs
            errors={errors}
            control={control}
            forms={kidForm || []}
            data={null}
          />
        </Box>
      </Box>
      <Heading as='h5' size='sm' my={2} color='brand'>
        Payment Program
      </Heading>
      <Box
        p={5}
        border='1px solid brand'
        borderRadius={10}
        borderWidth='1px'
        borderColor='brand'
      >
        {isLoadingProgram ? (
          <Center h='10vh'>
            <div className='custom-loader-normal' />
          </Center>
        ) : (
          <Box>
            {fields.map((item, i) => {
              return (
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  key={i}
                  spacing={2}
                  my={4}
                  alignItems='end'
                >
                  <Text
                    textAlign={{ base: 'start', md: 'center' }}
                    fontSize='xl'
                    w={{ base: 'full', md: '15%' }}
                  >
                    {label[lang].no} {i + 1}
                  </Text>
                  {programForm.map((data) => (
                    <FormControl
                      key={`programs.${i}.${data.name}`}
                      mb='3'
                      isInvalid={errors.programs?.[i]?.[data.name]}
                      id={`programs[${i}]name`}
                    >
                      <FormLabel>{`${data.label}`}</FormLabel>
                      {data.type === 'text' ? (
                        <Input
                          {...register(`programs.${i}.${data.name}`, {
                            required: {
                              value: data.required,
                              message: `${data.label} is Require Field `,
                            },
                          })}
                          name={`programs.${i}.${data.name}`}
                          type={data.type}
                          placeholder={data.placeholder}
                          defaultValue={data.defaultValue}
                        />
                      ) : data.type === 'number' ? (
                        <Input
                          {...register(`programs.${i}.${data.name}`, {
                            required: {
                              value: data.required,
                              message: `${data.label} is Require Field `,
                            },
                            // max: {
                            //   value: Number(
                            //     watch(`programs.${i}.${data.name}`)
                            //   ),
                            //   message: 'จำนวนเงินเกิน',
                            // },
                          })}
                          name={`programs.${i}.${data.name}`}
                          placeholder={data.placeholder}
                          defaultValue={data.defaultValue}
                          type='number'
                          onWheel={(e) => e.target.blur()}
                          isReadOnly={data.isReadOnly}
                        />
                      ) : data.type === 'checkbox' ? (
                        <Box minH='40px' alignItems='center' px={6}>
                          <Checkbox
                            size='lg'
                            colorScheme='lime'
                            {...register(`programs.${i}.${data.name}`, {
                              required: {
                                value: data.required,
                                message: `${data.label} is Require Field `,
                              },
                            })}
                            name={`programs.${i}.${data.name}`}
                            defaultValue={data.defaultValue}
                          />
                        </Box>
                      ) : null}
                      <FormErrorMessage>
                        {errors.programs?.[i]?.[data.name] &&
                          errors.programs?.[i]?.[data.name]['message']}
                      </FormErrorMessage>
                    </FormControl>
                  ))}
                  <IconButton
                    w={{ base: 'full', md: 'auto' }}
                    p={0}
                    onClick={() => remove(i)}
                    colorScheme='red'
                    icon={<AiFillDelete />}
                  >
                    Delete
                  </IconButton>
                </Stack>
              )
            })}

            <Flex justifyContent={'flex-end'} alignItems='center'>
              <HStack spacing={10}>
                <Heading>{label[lang]?.totalPrice}</Heading>
                <Heading fontSize='2xl' color='#767262'>
                  {totalPrice.toLocaleString()}
                </Heading>
                <Heading fontSize='2xl' color='#767262'>
                  {label[lang]?.currency}
                </Heading>
              </HStack>
            </Flex>
          </Box>
        )}
      </Box>

      <HStack justifyContent='center' my={10}>
        <Button mr={3} variant='outline' onClick={onOpen}>
          Cancel
        </Button>
        <Button
          colorScheme='lime'
          bg='brand'
          onClick={handleSubmit(createPayment)}
          isLoading={isLoading}
          isDisabled={fields.length <= 0}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  )
}
