/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { db, functions } from 'config/firebase'
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  deleteDoc,
  doc,
  addDoc,
  getDocs,
  where,
} from 'firebase/firestore'
import moment from 'moment'
import _ from 'underscore'
import useStaff from './useStaff'
import { httpsCallable } from 'firebase/functions'

let Time = [...Array(48)]
  .map((e, i) => {
    return (
      (i / 2 < 10 ? '0' : '') +
      (i / 2 - ((i / 2) % 1)) +
      ((i / 2) % 1 !== 0 ? '.30' : '.00')
    )
  })
  .filter((departTime) => Number(departTime) >= 9 && Number(departTime) <= 20)

export default function useSchedule({ kidId, programId, date, branchId }) {
  const checkAvailable = httpsCallable(functions, 'checkAvailable')
  const checkUpdateAvailable = httpsCallable(functions, 'checkUpdateAvailable')
  const createSchedule = httpsCallable(functions, 'createSchedule')
  const createScheduleManyStaff = httpsCallable(
    functions,
    'createScheduleManyStaff'
  )
  const reSchedule = httpsCallable(functions, 'reSchedule')
  const reScheduleManyStaff = httpsCallable(functions, 'reScheduleManyStaff')
  const removeSchedule = httpsCallable(functions, 'removeSchedule')
  const unScheduleById = httpsCallable(functions, 'unScheduleById')
  const unSchedule = httpsCallable(functions, 'unSchedule')
  const updateSchedule = httpsCallable(functions, 'updateSchedule')
  const clearCourse = httpsCallable(functions, 'clearCourse')

  const { staffs } = useStaff(null, branchId, true)
  const [schedulesByProgram, setScheduleByProgram] = useState([])
  const [schedulesByKid, setScheduleByKid] = useState([])
  const [schedulesByDate, setScheduleByDate] = useState({})

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (programId) {
      const querySchedules = query(
        collection(db, 'Schedules'),
        where('programId', '==', programId),
        orderBy('order', 'asc')
      )
      const unsubscribe = onSnapshot(querySchedules, (snapShot) => {
        let data = []
        snapShot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id })
        })

        setScheduleByProgram(data)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    } else {
      setLoading(false)
    }
  }, [programId])

  useEffect(() => {
    const selectedDate = date ? date : moment(new Date()).format('yyyy-MM-DD')
    if (staffs.length > 0 && selectedDate) {
      setLoading(true)
      const querySchedules = query(
        collection(db, 'Schedules'),
        where('date', '==', selectedDate)
      )
      const unsubscribe = onSnapshot(querySchedules, (snapShot) => {
        setLoading(true)

        const isWeekend = new Date(date).getDay() % 6 === 0

        let staffSchedule =
          staffs.length > 0 &&
          staffs.reduce((r, a) => {
            r[a.id] = r[a.id] || []
            for (let i = 0; i < Time.length; i++) {
              r[a.id].push({
                staff: a,
                id: _.random(999999999),
                currenTime: Time[i].toString(),
                startTime: new Date(
                  `2022-08-15T${Time[i].toString().replace('.', ':')}`
                ),
                endTime: new Date(
                  `2022-08-15T${(Time[i + 1] || 20)
                    .toString()
                    .replace('.', ':')}`
                ),
              })
            }
            return r
          }, {})

        // Staff Lunch && offday //
        staffs.forEach((staff) => {
          const selectedDay = moment(selectedDate).locale('en').format('dddd')

          const isStaffWorkingDate = staff.workingDay
            .concat(staff.workingDayWeekend)
            .includes(selectedDay)

          const {
            startTimeWeekend,
            startTimeWorkday,
            endTimeWeekend,
            endTimeWorkday,
            endLunchWeekend,
            endLunchWorkday,
            startLunchWorkday,
            startLunchWeekend,
            id,
          } = staff
          if (isStaffWorkingDate) {
            let start = isWeekend
              ? startTimeWeekend.replace(':', '.')
              : startTimeWorkday.replace(':', '.')
            let end = isWeekend
              ? endTimeWeekend.replace(':', '.')
              : endTimeWorkday.replace(':', '.')

            let startLunch = isWeekend
              ? startLunchWeekend.replace(':', '.')
              : startLunchWorkday.replace(':', '.')
            let endLunch = isWeekend
              ? endLunchWeekend.replace(':', '.')
              : endLunchWorkday.replace(':', '.')

            staffSchedule[id].push(
              {
                staff: staff,
                name: '',
                id: _.random(999999999),
                event: 'disable',
                currenTime: start,
                startTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${'07:00'}`
                ),
                endTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${start.replace('.', ':')}`
                ),
              },
              {
                staff: staff,
                name: 'Lunch',
                id: _.random(999999999),
                event: 'lunch',
                currenTime: start,
                startTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${startLunch.replace(
                    '.',
                    ':'
                  )}`
                ),
                endTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${endLunch.replace(
                    '.',
                    ':'
                  )}`
                ),
              },
              {
                staff: staff,
                name: '',
                event: 'disable',
                currenTime: end,
                id: _.random(999999999),
                startTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${end.replace('.', ':')}`
                ),
                endTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${'20.00'.replace(
                    '.',
                    ':'
                  )}`
                ),
              }
            )
          } else {
            // delete staffSchedule[id]
            staffSchedule[id].push({
              staff: staff,
              name: 'Day Off',
              event: 'offday',
              currenTime: 'offday',
              id: _.random(999999999),
              startTime: new Date(
                `${moment().format('YYYY-MM-DD')}T${'09.00'.replace('.', ':')}`
              ),
              endTime: new Date(
                `${moment().format('YYYY-MM-DD')}T${'20.00'.replace('.', ':')}`
              ),
            })
          }
        })
        // Staff Lunch && offday //

        // Staff Schedule //
        snapShot.forEach((doc) => {
          if (doc.data().cancelLog) return
          const { program, timeEnd, timeStart, staff } = doc.data()
          if (staffSchedule[doc.data().staff?.id] && doc.data()?.isSkipDayOff) {
            const filterSkip = staffSchedule[doc.data().staff?.id]
              ?.filter((s) => s.event !== 'offday')
              ?.filter((s) => !s.isSkipDayOff)
              .map((s) => ({ ...s, isSkipDayOff: true }))

            filterSkip.push({
              staff: { ...staff, isSkipDayOff: true },
              name: 'Lunch',
              event: 'lunch',
              currenTime: '12.00',
              startTime: new Date(
                `${moment().format('YYYY-MM-DD')}T${'12.00'.replace('.', ':')}`
              ),
              endTime: new Date(
                `${moment().format('YYYY-MM-DD')}T${'13.00'.replace('.', ':')}`
              ),
              id: _.random(999999999),
            })
            staffSchedule[doc.data().staff?.id] = filterSkip
          }
          if (staffSchedule[doc.data().staff?.id] && doc.data()?.isAllDay) {
            // Hide Holiday Staff
            // delete staffSchedule[doc.data().staff?.id]
            staffSchedule[doc.data().staff?.id].push({
              staff: staff,
              name: 'Day Off',
              event: 'offday',
              currenTime: 'offday',
              startTime: new Date(
                `${moment().format('YYYY-MM-DD')}T${'09.00'.replace('.', ':')}`
              ),
              endTime: new Date(
                `${moment().format('YYYY-MM-DD')}T${'20.00'.replace('.', ':')}`
              ),
              id: doc.id,
            })
          } else if (staffSchedule[doc.data().staff?.id]) {
            const isMultiProgram = staffSchedule[
              doc.data().staff?.id
            ].findIndex((d) => {
              return (
                d.name === `${program?.courseName} (${program?.choice})` &&
                d.currenTime === timeStart &&
                d.isMulti
              )
            })

            const isCamp = staffSchedule[doc.data().staff?.id].findIndex(
              (d) => {
                return d.currenTime === timeStart && d?.program?.type === 'Camp'
              }
            )

            if (isMultiProgram !== -1) {
              let schedule = staffSchedule[doc.data().staff?.id][isMultiProgram]
              staffSchedule[doc.data().staff?.id][isMultiProgram] = {
                ...schedule,
                kid: schedule?.kid?.length
                  ? [...schedule.kid, doc.data().kid]
                  : [schedule.kid, doc.data().kid],
                kidId: Array.isArray(schedule?.kidId)
                  ? [...schedule.kidId, doc.data().kid.id]
                  : [schedule.kidId, doc.data().kid.id],
                programId: Array.isArray(schedule?.programId)
                  ? [...schedule.programId, doc.data().programId]
                  : [schedule.programId, doc.data().programId],
                kidsCompleted: Array.isArray(schedule?.kidsCompleted)
                  ? [...schedule.kidsCompleted, doc.data().isTaked]
                  : [schedule.isTaked, doc.data().isTaked],
                id: `${schedule.id},${doc.id}`,
              }
            } else if (isCamp !== -1) {
              let schedule = staffSchedule[doc.data().staff?.id][isCamp]
              staffSchedule[doc.data().staff?.id][isCamp] = {
                ...schedule,
                kid: schedule?.kid?.length
                  ? [...schedule.kid, doc.data().kid]
                  : [schedule.kid, doc.data().kid],
                kidId: Array.isArray(schedule?.kidId)
                  ? [...schedule.kidId, doc.data().kid.id]
                  : [schedule.kidId, doc.data().kid.id],
                programId: Array.isArray(schedule?.programId)
                  ? [...schedule.programId, doc.data().programId]
                  : [schedule.programId, doc.data().programId],
                campAmount: Array.isArray(schedule?.campAmount)
                  ? [...schedule.campAmount, doc.data().program.amount]
                  : [schedule.program.amount, doc.data().program.amount],
                kidsCompleted: Array.isArray(schedule?.kidsCompleted)
                  ? [...schedule.kidsCompleted, doc.data().isTaked]
                  : [schedule.isTaked, doc.data().isTaked],
                id: `${schedule.id},${doc.id}`,
              }
            } else {
              staffSchedule[doc.data().staff?.id].push({
                staff: staff,
                name: `${program?.courseName} (${program?.choice})`,
                event: program?.choice,
                currenTime: timeStart,
                startTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${timeStart.replace(
                    '.',
                    ':'
                  )}`
                ),
                endTime: new Date(
                  `${moment().format('YYYY-MM-DD')}T${timeEnd.replace(
                    '.',
                    ':'
                  )}`
                ),
                ...doc.data(),
                ...program,
                id: doc?.id,
              })
            }
          }
        })
        // Staff Schedule //
        setScheduleByDate(staffSchedule)
        setLoading(false)
      })
      return () => {
        setScheduleByDate({})
        unsubscribe()
      }
    } else {
      setLoading(false)
      setScheduleByDate({})
    }
  }, [date, staffs])

  useEffect(() => {
    if (kidId) {
      setLoading(true)

      const querySchedules = query(
        collection(db, 'Schedules'),
        where('kidId', '==', kidId),
        orderBy('order', 'asc')
      )
      const unsubscribe = onSnapshot(querySchedules, (snapShot) => {
        let data = []
        snapShot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id })
        })
        setScheduleByKid(data)
        setLoading(false)
      })
      return () => {
        unsubscribe()
      }
    }
  }, [kidId])

  const fetchScheduleByKid = async () => {
    setLoading(true)

    const querySchedules = query(
      collection(db, 'Schedules'),
      where('kidId', '==', kidId),
      orderBy('order', 'asc')
    )
    const data = await (
      await getDocs(querySchedules)
    ).docs.map((doc) => ({ ...doc.data(), id: doc.id }))

    setScheduleByKid(data)
    setLoading(false)
  }

  const checkScheduleAvailable = async (
    staffId,
    date,
    timeStart,
    timeEnd,
    kidId,
    currentStaffId,
    programMulti
  ) => {
    const querySnapShot = query(
      collection(db, 'Schedules'),
      where('staffId', '==', staffId),
      where('date', '==', date)
    )

    const queryKidSnapShot = query(
      collection(db, 'Schedules'),
      where('kidId', '==', kidId || ''),
      where('date', '==', date)
    )

    function isInRangeStart(value, range) {
      return value >= range[0] && value < range[1]
    }

    function isInRangeEnd(value, range) {
      return value > range[0] && value <= range[1]
    }

    const snapShot = await getDocs(querySnapShot)
    const kidSnapShot = await getDocs(queryKidSnapShot)
    const timeStaff = snapShot.docs.map((doc) => {
      const isMulti = doc.data().program?.isMulti
      if (isMulti && programMulti === doc.data().program.label) {
        return true
      }
      let range = [doc.data().timeStart, doc.data().timeEnd]
      const notAvaiable =
        isInRangeStart(timeStart, range) || isInRangeEnd(timeEnd, range)

      return !notAvaiable
    })
    const timeKid = kidSnapShot.docs.map((doc) => {
      let range = [doc.data().timeStart, doc.data().timeEnd]
      const notAvaiable =
        isInRangeStart(timeStart, range) || isInRangeEnd(timeEnd, range)

      return !notAvaiable
    })

    if (currentStaffId && currentStaffId !== staffId) {
      return timeStaff.every((isAvailable) => isAvailable)
    } else {
      return timeStaff.concat(timeKid).every((isAvailable) => isAvailable)
    }
  }

  const addSchedule = async (data) => {
    try {
      const res = await checkAvailable({
        staffId: data.staffId,
        timeEnd: data.timeEnd,
        timeStart: data.timeStart,
        date: moment(data.date).format('YYYY-MM-DD'),
        kidId: data.kidId,
        program: data.program,
      })

      if (res?.data?.isAvailable) {
        const scUid = await addDoc(collection(db, 'Schedules'), {
          ...data,
          createdAt: data.updatedAt,
          createdBy: data.updatedBy,
          updatedAt: data.updatedAt,
          updatedBy: data.updatedBy,
        })

        return addDoc(collection(db, `Schedules/${scUid.id}/Logs`), {
          kidId: data.kidId,
          programId: data.programId,
          date: data.date,
          isTaked: data.isTaked,
          staffId: data.staffId,
          timeEnd: data.timeEnd,
          timeStart: data.timeStart,
          createdAt: data.updatedAt,
          createdBy: data.updatedBy,
          action: data.action,
        })
      } else {
        return res.data
      }
    } catch (error) {
      return error
    }
  }

  const deleteSchedule = (id) => {
    return deleteDoc(doc(db, 'Schedules', id))
  }

  const getCompletedSchedule = async (programId, date, startTime) => {
    const startTimeStr = `${moment(startTime).format('HH')}.${moment(
      startTime
    ).format('mm')}`
    const completedQuery = query(
      collection(db, 'Schedules'),
      where('programId', '==', programId)
      // where('isTaked', '==', true)
    )

    const count = await (
      await getDocs(completedQuery)
    ).docs.map((doc) => doc.data())

    const index = count
      ?.filter((p) => !p.cancelLog)
      .filter((p) => p.isTaked)
      .sort((a, b) => a.timeStart.localeCompare(b.timeStart))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .findIndex((d) => {
        return d.date === date && d.timeStart === startTimeStr
      })

    if (index !== -1) {
      return index + 1
    }
  }

  const getCompletedScheduleManyKids = async (programId, date, startTime) => {
    const startTimeStr = `${moment(startTime).format('HH')}.${moment(
      startTime
    ).format('mm')}`
    const completedPrograms = await Promise.all(
      programId.map(async (program) => {
        const completedQuery = query(
          collection(db, 'Schedules'),
          where('programId', '==', program)
          // where('isTaked', '==', true)
        )

        const count = await (
          await getDocs(completedQuery)
        ).docs.map((doc) => doc.data())
        const index = count
          ?.filter((p) => !p.cancelLog)
          .filter((p) => p.isTaked)
          .sort((a, b) => a.timeStart.localeCompare(b.timeStart))
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .findIndex((d) => {
            return d.date === date && d.timeStart === startTimeStr
          })
        if (index !== -1) {
          return {
            kidId: count[0].kidId,
            kidCode: count[0].kid.code,
            noOfCompleted: index + 1,
          }
        }
      })
    )

    return completedPrograms
  }

  return {
    checkAvailable,
    checkUpdateAvailable,
    createSchedule,
    createScheduleManyStaff,
    isLoading,
    setLoading,
    deleteSchedule,
    checkScheduleAvailable,
    addSchedule,
    updateSchedule,
    schedulesByProgram,
    schedulesByKid,
    schedulesByDate,
    fetchScheduleByKid,
    reSchedule,
    reScheduleManyStaff,
    getCompletedSchedule,
    removeSchedule,
    getCompletedScheduleManyKids,
    unScheduleById,
    unSchedule,
    clearCourse,
  }
}
