/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import Table from 'components/Horizons/table'
import { functions } from 'config/firebase'
import { httpsCallable } from 'firebase/functions'
import useBranch from 'hooks/useBranch'
import { useEffect, useMemo, useState } from 'react'
import { GoFile } from 'react-icons/go'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { search } from 'ss-search'
import { isEmpty } from 'underscore'

const getPaymentByBranch = httpsCallable(functions, 'getPaymentByBranch')

const RefPopover = (array) =>
  array && (
    <Popover>
      <PopoverTrigger>
        <IconButton>
          <HiOutlineDocumentText fontSize={20} />
        </IconButton>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w='auto'>
          <PopoverHeader>Reference</PopoverHeader>
          <PopoverBody>
            {array?.map((p, index) => (
              <HStack
                p={1}
                key={index}
                alignItems='center'
                color='firstColor.100'
                cursor='default'
              >
                <GoFile />
                <Text>{p}</Text>
              </HStack>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )

const Payment = ({ user, role }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const managerBranchId =
    role === 'Manager' || role === 'Staff' ? user?.branchId : ''
  const { branches } = useBranch()
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId,
  })

  const fetch = async (branchId) => {
    setIsLoading(true)
    const response = await getPaymentByBranch({
      branchId: branchId,
    })

    setList(response?.data)
    setIsLoading(false)
  }

  useEffect(() => {
    if (managerBranchId !== '') {
      setSearchParams({ branchId: managerBranchId })
      setSearch({ ...searchQuery, branch: managerBranchId })
    }
  }, [managerBranchId])

  useEffect(() => {
    const branchId = searchParams.get('branchId')
    if (branchId && branchId !== '') {
      setSearch({ ...searchQuery, branch: branchId })
      fetch(branchId)
    } else {
      setList([])
    }
  }, [searchParams.get('branchId')])

  function handleSearch(textSearch) {
    const searchQueryArr = list
    const searchKeys = ['name', 'nickname', 'ref', 'kid', 'code']
    const results = search(list, searchKeys, textSearch)
    const searchResults = textSearch === '' ? searchQueryArr : results
    setList(searchResults)
  }

  const totalRemaining = useMemo(() => {
    if (list && list.length > 0) {
      const total = list?.reduce((acc, item) => {
        const remain = item?.list?.reduce((sum, child) => {
          if (child.payment && !isEmpty(child.payment)) {
            // มียอดที่เคยชำระแล้ว //
            const sumRemain = child?.payment?.transactions?.reduce(
              (sump, t) => {
                if (t.action === 'canceled') {
                  return sump
                } else {
                  return sump - Number(t.paid)
                }
              },
              Number(child.price)
            )
            return sum + sumRemain
          } else {
            // ไม่มียอดที่ชำระแล้ว //
            return sum + Number(child.price)
          }
        }, 0)

        return acc + remain
      }, 0)
      return total
    }
    return 0
  }, [list])

  const columns = [
    {
      Header: 'Code',
      accessor: 'kid.code',
      key: 'kid.code',
    },
    {
      Header: 'kid',
      accessor: 'kid',
      key: 'kid',
      extra: (kid, row) => {
        return (
          <Link to={`/kids/${kid?.id}?tab=4`}>
            {`${kid?.name ? kid?.name : ''} (${kid?.nickname})`}
          </Link>
        )
      },
    },
    {
      Header: 'ยอดค้างชำระรวม',
      accessor: 'list',
      key: 'list',
      extra: (list, row) => {
        const remain = list.reduce((sum, child) => {
          if (child.payment && !isEmpty(child.payment)) {
            // มียอดที่เคยชำระแล้ว //
            const sumRemain = child?.payment?.transactions?.reduce(
              (sump, t) => {
                if (t.action === 'canceled') {
                  return sump
                } else {
                  return sump - Number(t.paid)
                }
              },
              Number(child.price)
            )
            return sum + sumRemain
          } else {
            // ไม่มียอดที่ชำระแล้ว //
            return sum + Number(child.price)
          }
        }, 0)
        return `${remain.toLocaleString()} บาท`
      },
    },
    {
      Header: 'เอกสารอ้างอิง',
      key: 'paymentRef',
      extra: (_, row) => {
        return row.paymentRef.length > 0 ? RefPopover(row.paymentRef) : null
      },
    },
    {
      Header: 'จัดการ',
      accessor: 'kid.id',
      key: 'kid.id',
      width: '10%',
      align: 'center',
      extra: (kidId, row) => {
        return (
          <HStack p={0} m={0}>
            {role === 'Owner' && (
              <Button
                size='sm'
                textAlign='center'
                py={2}
                color='black'
                onClick={() => navigate(`/payments/clear/create?kid=${kidId}`)}
              >
                Clear Bill
              </Button>
            )}
            <Button
              variant='solid'
              colorScheme='orange'
              bg='#F6872B'
              size='sm'
              onClick={() => navigate(`/payments/bill/create?kid=${kidId}`)}
            >
              Create cash bill
            </Button>
          </HStack>
        )
      },
    },
  ]
  return (
    <Box>
      <Text color='brand'>Search</Text>
      <Box
        borderWidth='1px'
        borderColor='brand'
        borderRadius='10px'
        mt={1}
        p={5}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={{ base: 0, md: 2 }}
        >
          <FormControl>
            <FormLabel>Search</FormLabel>
            <Input
              id='search'
              type='search'
              placeholder='Search'
              onChange={(e) => {
                e.preventDefault()
                setSearch({ ...searchQuery, text: e.target.value })
                handleSearch(e.target.value)
              }}
              value={searchQuery.text}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Branch</FormLabel>
            <Select
              id='branch'
              placeholder='Please Select Branch'
              onChange={(e) => {
                setSearchParams({ branchId: e.target.value })
              }}
              value={searchQuery?.branch || ''}
              isDisabled={managerBranchId}
            >
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Flex
            justifyContent={{ base: 'center', lg: 'flex-end' }}
            w='full'
            mt={4}
          >
            <HStack mt={3}>
              <Button
                variant='outline'
                onClick={() => {
                  setSearch({ text: '', branch: '', status: '', type: '' })
                }}
              >
                Clear
              </Button>
            </HStack>
          </Flex>
        </SimpleGrid>
      </Box>

      <Flex
        justifyContent='space-between'
        w='full'
        my={4}
        px={0}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Flex flexDirection={{ base: 'column', lg: 'row' }}>
          <Stack
            p={{ base: 3, md: 4 }}
            my={{ base: 2, md: 4 }}
            mr={{ base: 0, md: 2 }}
            borderWidth='1px'
            borderColor='brand'
            borderRadius='10px'
            minW={{ base: 'auto', md: '450px' }}
          >
            <HStack justifyContent='space-between'>
              <Heading size={{ base: 'sm', md: 'md' }}>จำนวนเด็ก</Heading>
              <Heading size={{ base: 'sm', md: 'md' }}>
                {list?.length || 0}
              </Heading>
            </HStack>
          </Stack>
          <Stack
            p={{ base: 3, md: 4 }}
            my={{ base: 2, md: 4 }}
            borderWidth='1px'
            borderColor='brand'
            borderRadius='10px'
            minW={{ base: 'auto', md: '450px' }}
          >
            <HStack justifyContent='space-between'>
              <Heading size={{ base: 'sm', md: 'md' }}>
                ยอดค้างชำระรวมทั้งหมด
              </Heading>
              <Heading size={{ base: 'sm', md: 'md' }}>
                {`${totalRemaining.toLocaleString()} บาท`}
              </Heading>
            </HStack>
          </Stack>
        </Flex>
      </Flex>
      <Table
        columnsData={columns}
        tableData={list || []}
        loading={isLoading}
        noSearch
        useCurrentPage
      />
    </Box>
  )
}

export default Payment
