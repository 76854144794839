import { useState, useMemo, Fragment } from 'react'
import {
  Flex,
  Heading,
  HStack,
  Button,
  useDisclosure,
  Center,
  Box,
  Stack,
  Text,
  IconButton,
  useToast,
} from '@chakra-ui/react'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { FiRefreshCw } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import useKid from 'hooks/useKid'
import Table from 'components/Horizons/table/'
import { Popconfirm } from 'antd'

import ActiveModal from './Modals/active'
import ReActiveModal from './Modals/reActive'

import OffModal from './Modals/off'

export default function Office({ role, kid, user }) {
  const toast = useToast()
  const { id } = useParams()
  const { activeList, isLoadingActiveList, deleteKidCollection } = useKid(id)
  const [activeMember, setActiveKid] = useState(null)

  const {
    isOpen: isOpenActive,
    onOpen: onOpenActive,
    onClose: onCloseActive,
  } = useDisclosure()

  const {
    isOpen: isOpenReActive,
    onOpen: onOpenReActive,
    onClose: onCloseReActive,
  } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const columnsActive = useMemo(() => {
    return [
      {
        Header: 'Active Date',
        accessor: 'activeDate',
        key: 'activeDate',
        extra: (date) =>
          date && date !== ''
            ? moment(date.toDate()).format('DD/MM/YYYY')
            : '-',
      },

      {
        Header: 'Off / Dischage Date',
        accessor: 'offDate',
        key: 'offDate',
        extra: (date) =>
          date && date !== ''
            ? moment(date.toDate()).format('DD/MM/YYYY')
            : '-',
      },
      {
        Header: 'Return Date',
        accessor: 'returnDate',
        key: 'returnDate',
        extra: (date) =>
          date && date !== ''
            ? moment(date.toDate()).format('DD/MM/YYYY')
            : '-',
      },
      {
        Header: 'Expire Date',
        accessor: 'expireDate',
        key: 'expireDate',
        extra: (date) =>
          date && date !== ''
            ? moment(date.toDate()).format('DD/MM/YYYY')
            : '-',
      },
      {
        Header: 'Price',
        accessor: 'price',
        key: 'price',
        extra: (price, row) =>
          `${
            row?.isWaive
              ? 'Member Waive '
              : Number(price).toLocaleString('th-TH')
          }`,
      },
      {
        Header: 'Status',
        accessor: 'status',
        key: 'status',
        width: '10%',
        extra: (status) => (
          <Text
            fontWeight={600}
            textTransform={'uppercase'}
            color={status === 'member' ? 'brand' : 'red'}
          >
            {status}
          </Text>
        ),
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        key: 'reason',
      },

      {
        Header: 'Action',
        accessor: 'Action',
        key: 'Action',
        width: '10%',
        extra: (data, row) => {
          const offMonths = moment().diff(
            moment(
              row?.offDate && row?.offDate !== ''
                ? row?.offDate?.toDate()
                : new Date()
            ),
            'months'
          )
          const expireDatePast = moment().diff(
            row?.expireDate && row?.expireDate !== ''
              ? row?.expireDate?.toDate()
              : new Date(),
            'days'
          )
          return (
            role !== 'Staff' &&
            role !== 'CoAdmin' && (
              <HStack justifyContent='space-between'>
                <HStack spacing={2}>
                  {row?.status === 'member' && (
                    <IconButton
                      variant='ghost'
                      colorScheme='lime'
                      icon={<AiFillEdit color='#8CC63E' fontSize='25px' />}
                      onClick={() => {
                        setActiveKid(row)
                        onOpen()
                      }}
                    ></IconButton>
                  )}
                  {row?.status === 'off' &&
                    offMonths < 3 &&
                    expireDatePast <= 0 && (
                      <Button
                        variant='outline'
                        colorScheme='lime'
                        leftIcon={<FiRefreshCw />}
                        onClick={() => {
                          setActiveKid(row)
                          onOpenReActive()
                        }}
                      >
                        REACTIVE
                      </Button>
                    )}
                  {!row.payment && role === 'Owner' && (
                    <Popconfirm
                      title='Sure to delete this Status'
                      onConfirm={() => {
                        handleDelete('Status', row.id)
                      }}
                    >
                      <IconButton
                        variant='link'
                        aria-label='delete'
                        icon={<AiFillDelete color='red' fontSize='25px' />}
                      />
                    </Popconfirm>
                  )}
                </HStack>
              </HStack>
            )
          )
        },
      },
    ]
    // eslint-disable-next-line
  }, [])

  function handleDelete(collection, docId) {
    deleteKidCollection(id, collection, docId)
      .then(() => {
        toast({
          position: 'top',
          Header: 'Delete',
          description: 'Deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          position: 'top',
          Header: 'Delete Fail.',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <Fragment>
      <Center my='5'>
        <Heading>Kid's Status</Heading>
      </Center>
      <Stack spacing={10}>
        <Box>
          <Flex
            justifyContent='space-between'
            alignItems='flex-end'
            w={'100%'}
            my={2}
          >
            <Heading as='h5' size='sm' color='brand'>
              Kid's Status
            </Heading>
            {role !== 'Staff' && (
              <Button
                variant='solid'
                colorScheme='brand'
                bg='brand'
                onClick={onOpenActive}
              >
                New Active
              </Button>
            )}
          </Flex>

          <Box
            borderWidth='1px'
            borderColor={'brand'}
            borderRadius='12px'
            p={5}
          >
            {/* {isOpen && ( */}
            <OffModal
              isOpen={isOpen}
              onClose={() => {
                setActiveKid(null)
                onClose()
              }}
              data={activeMember}
              kidId={id}
            />
            {/* )} */}
            {/* {isOpenActive && ( */}
            <ActiveModal
              isOpen={isOpenActive}
              onClose={() => {
                setActiveKid(null)
                onCloseActive()
              }}
              data={activeMember}
              kidId={id}
              branchId={kid?.branchId || kid?.branch?.id}
              user={user}
            />
            {/* )} */}
            {/* {isOpenReActive && ( */}
            <ReActiveModal
              isOpen={isOpenReActive}
              onClose={() => {
                setActiveKid(null)
                onCloseReActive()
              }}
              data={activeMember}
              kidId={id}
            />
            {/* )} */}
            <Table
              columnsData={columnsActive}
              tableData={activeList || []}
              loading={isLoadingActiveList}
              noSearch
            />
          </Box>
        </Box>
      </Stack>
    </Fragment>
  )
}
