/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useMemo, useState } from 'react'
import {
  Flex,
  Heading,
  Button,
  useDisclosure,
  Center,
  Box,
  Stack,
  ButtonGroup,
  IconButton,
  useToast,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { Popconfirm } from 'antd'
import useKid from 'hooks/useKid'
import useSchedule from 'hooks/useSchedule'
import useBranch from 'hooks/useBranch'

import Table from 'components/Horizons/table'
import AddCourseModal from './Modals/AddCourse'
import MemberModal from './Modals/MemberModal'

const weekday = [
  { name: 'Sunday', color: 'red' },
  { name: 'Monday', color: 'yellow' },
  { name: 'Tuesday', color: 'pink' },
  { name: 'Wednesday', color: 'green' },
  { name: 'Thursday', color: 'orange' },
  { name: 'Friday', color: 'blue' },
  { name: 'Saturday', color: 'purple' },
]

export default function CourseProgram({ kid, managerBranchId, role, user }) {
  const toast = useToast()
  const { id } = useParams()
  const { kidCourses, isLoadingCourse, deleteKidProgram, updateKidProgram } =
    useKid(id)
  const { branches } = useBranch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenMember,
    onOpen: onOpenMember,
    onClose: onCloseMember,
  } = useDisclosure()
  const [programData, setProgram] = useState()

  const { schedulesByKid, fetchScheduleByKid } = useSchedule({ kidId: id })
  const [selectedCourse, setSelected] = useState()
  const branchProvince = useMemo(() => {
    const findBranch = branches.find((b) => b.id === kid?.branchId)
    return findBranch ? findBranch?.province : 'all'
  }, [kid, branches])

  const updateProgram = (programId, data) => {
    try {
      updateKidProgram(id, programId, { daysOfWeek: data })
    } catch (e) {
      console.log(e)
    }
  }

  const removeProgram = async (program) => {
    try {
      await deleteKidProgram(program, id)
      toast({
        position: 'top',
        title: 'Remove Program',
        description: 'Program has been removed.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      toast({
        position: 'top',
        title: 'Remove Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Create At',
        accessor: 'createdAt',
        key: 'name',
        width: '10%',
        extra: (date, row) => {
          return moment(date.toDate()).format('DD/MM/YYYY HH:mm')
        },
      },
      {
        Header: 'DAYS',
        accessor: 'daysOfWeek',
        key: 'name',
        width: '10%',
        extra: (days, row) => {
          return (
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} variant='outline'>
                <HStack>
                  {days &&
                    days
                      .sort((a, b) => a - b)
                      .map((day, i) => (
                        <Text key={i} color={weekday[Number(day)].color}>{`${
                          weekday[Number(day)].name
                        }`}</Text>
                      ))}
                </HStack>
              </MenuButton>
              {role !== 'Staff' && (
                <MenuList minWidth='240px'>
                  <MenuOptionGroup
                    type='checkbox'
                    onChange={(e) => updateProgram(row?.id, e)}
                    defaultValue={days}
                  >
                    {weekday.map((day, i) => (
                      <MenuItemOption
                        key={day.name}
                        value={i}
                        color={day?.color}
                      >
                        {day?.name}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              )}
            </Menu>
          )
        },
      },
      {
        Header: 'Course Name',
        accessor: 'courseName',
        key: 'name',
        width: '20%',
      },
      {
        Header: 'Program',
        accessor: 'choice',
        key: 'choice',
        width: '20%',
      },
      {
        Header: 'Date Limit',
        accessor: 'limit',
        key: 'limit',
        width: '10%',
        extra: (limit, row) => {
          const firstSchedule = row.schedules
            .filter((schedule) => schedule?.date !== '')
            .sort((a, b) => new Date(a.date) - new Date(b.date))[0]

          return limit
            ? firstSchedule &&
                moment(firstSchedule?.date).add(limit, 'M').format('DD/MM/YYYY')
            : ''
        },
      },
      {
        Header: 'Completed',
        accessor: 'amount',
        key: 'amount',
        width: '10%',
        align: 'right',
        extra: (amount, row) => {
          return `${
            row.schedules
              ?.filter((p) => !p.cancelLog)
              .reduce((acc, val) => {
                return val.isTaked ? acc + 1 : acc
              }, 0) || 0
          } / ${amount} ${row.isClear ? '(Clear)' : ''}`
        },
      },

      {
        Header: '',
        accessor: 'Action',
        key: 'Action',
        width: '10%',
        align: 'center',
        extra: (_, row) => {
          return (
            role !== 'Staff' && (
              <ButtonGroup justifyContent={'center'} alignItems='center'>
                {kid?.status.toLowerCase() !== 'member' &&
                  row?.type === 'Evaluation' && (
                    <Button
                      variant='outline'
                      aria-label='member'
                      colorScheme='orange'
                      onClick={() => {
                        setProgram(
                          schedulesByKid.filter(
                            (val) => val?.program?.name === row.name
                          )
                        )
                        onOpenMember()
                      }}
                    >
                      Active Member
                    </Button>
                  )}
                <Link to={`/kids/${id}/${row.id}`}>
                  <IconButton
                    variant='ghost'
                    aria-label='edit'
                    icon={<AiFillEdit color='#8CC63E' fontSize='25px' />}
                  />
                </Link>

                {(row?.payment?.status !== 'paid' || !row.payment) &&
                  !row.isClear && (
                    <Popconfirm
                      title='Sure to remove this Program'
                      onConfirm={() => {
                        removeProgram(row)
                      }}
                    >
                      <IconButton
                        variant='ghost'
                        aria-label='delete'
                        icon={<AiFillDelete color='red' fontSize='25px' />}
                      />
                    </Popconfirm>
                  )}
                <Button
                  size='sm'
                  variant='outline'
                  colorScheme='orange'
                  onClick={() => {
                    const filterSchedule = row?.schedules
                      ?.filter((s) => !s.cancelLog)
                      ?.filter((s) => s.date !== '')

                    setSelected({
                      courseId: row.courseId,
                      choice: row.choice,
                      amount: row.amount,
                      lastDate: filterSchedule.sort(
                        (a, b) => new Date(a.date) - new Date(b.date)
                      )?.[filterSchedule?.length - 1]?.date,
                    })
                    onOpen()
                  }}
                  borderRadius='md'
                  color='#F6872B'
                >
                  Add Again
                </Button>
              </ButtonGroup>
            )
          )
        },
      },
    ]
  }, [schedulesByKid])

  return (
    <Fragment>
      <Center my='5'>
        <Heading>PROGRAMS</Heading>
      </Center>

      <Stack spacing={10}>
        <Box>
          <Flex
            justifyContent='space-between'
            w={'100%'}
            my={2}
            alignItems='flex-end'
          >
            <Heading as='h5' size='sm' color='brand'>
              Programs
            </Heading>
            {role !== 'Staff' && (
              <Button
                variant='solid'
                colorScheme='#F6872B'
                bg='#F6872B'
                onClick={onOpen}
              >
                Add Program
              </Button>
            )}
          </Flex>

          <Box
            borderWidth='1px'
            borderColor={'brand'}
            borderRadius='12px'
            p={5}
          >
            {isOpen && (
              <AddCourseModal
                isOpen={isOpen}
                onClose={() => {
                  onClose()
                  setSelected()
                }}
                kidId={id}
                kidStatus={kid?.status}
                kid={kid}
                managerBranchId={managerBranchId}
                selected={selectedCourse}
                branchProvince={branchProvince}
              />
            )}
            {isOpenMember && (
              <MemberModal
                isOpen={isOpenMember}
                onClose={() => {
                  fetchScheduleByKid()
                  onCloseMember()
                }}
                kidId={id}
                kidStatus={kid?.status}
                data={kid}
                scheduleId={programData[0]?.id}
                branches={branches}
                managerBranchId={managerBranchId}
                user={user}
              />
            )}

            <Table
              columnsData={columns}
              tableData={kidCourses}
              loading={isLoadingCourse}
              noSearch
            />
          </Box>
        </Box>
      </Stack>
    </Fragment>
  )
}
