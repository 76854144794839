import { useState, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { Center, VStack, Image, Heading, Button, Text } from '@chakra-ui/react'

import Layout from 'layout'
import AuthLayout from 'layout/authLayout'
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore'

import { auth, db } from 'config/firebase'

import Settings from 'pages/Settings'
import BracnhSetting from 'pages/Settings/Branch'
import AdminSetting from 'pages/Settings/Admin'
import StaffSetting from 'pages/Settings/Staff'
import StaffForm from 'pages/Settings/Staff/form'
import CourseSetting from 'pages/Settings/Cousre'
import CourseForm from 'pages/Settings/Cousre/form'

import Kids from 'pages/Kids'
import KidForm from 'pages/Kids/form'
import KidInfo from 'pages/Kids/info'
import KidCourse from 'pages/Kids/Course/updateprogram'

import Payment from 'pages/Payments'
import PaymentForm from 'pages/Payments/form'
import ClearForm from 'pages/Payments/clearForm'
import RefundForm from 'pages/Payments/refundForm'

import Schedule from 'pages/Schedule'

import Report from 'pages/Report'

import Login from 'pages/Auth/login'
import ResetPassword from 'pages/Auth/resetPassword'

import NotificationSetting from 'pages/Settings/Notification'

import EventLogs from 'pages/EventLogs'

import './App.css'

const version = '1.1.8a'

function PrivateRoute({ children, user, allowed, notAllowed }) {
  if (!user) {
    return <Navigate to='/login' replace />
  } else {
    if (allowed && allowed.indexOf(user?.role) === -1) {
      return <Navigate to='/kids' replace />
    } else {
      return <Layout user={user}>{children}</Layout>
    }
  }
}

function PublicRoute({ children, bg, user }) {
  if (user) {
    return <Navigate to='/kids' replace />
  }

  return <AuthLayout bg={bg}>{children}</AuthLayout>
}

function App() {
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingVersions, setLoadingVersions] = useState(true)
  const [dbVersion, setDBVersion] = useState('')
  const [lastestVersion, setlastestVersion] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        setUser({ ...authUser })
        let idToken = await authUser.getIdTokenResult()
        let uid = authUser.uid
        onSnapshot(doc(db, 'Profiles', uid), (doc) => {
          setUser({ ...authUser, ...doc.data(), role: idToken?.claims?.role })
          setLoading(false)
        })
      } else {
        setLoading(false)
        setUser(null)
      }
    })
  }, [])

  useEffect(() => {
    const getVersion = query(
      collection(db, 'Versions'),
      orderBy('createdAt', 'desc'),
      limit(3)
    )
    const unsubscribe = onSnapshot(getVersion, (snap) => {
      let data = []
      snap.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
        })
      })
      if (data[0]?.version === version) {
        setlastestVersion(true)
      } else {
        setlastestVersion(false)
      }
      setLoadingVersions(false)
      setDBVersion(data[0]?.version || '')
    })

    return () => {
      unsubscribe()
    }
  }, [])

  if (isLoadingVersions && window.location.hostname !== 'localhost') {
    return (
      <Center h='100vh' width='full' bg='brand'>
        <VStack spacing={5}>
          <Image height='80px' src='/Logo.webp' />
          <Heading color='white'>Checking software versions ...</Heading>
          <div className='custom-loader-white' />
        </VStack>
      </Center>
    )
  }

  if (!lastestVersion && window.location.hostname !== 'localhost') {
    return (
      <Center h='100vh' width='full' bg='brand'>
        <VStack spacing={5}>
          <Image height='80px' src='/Logo.webp' />
          <Heading color='white'>System Update</Heading>
          <Text color={'white'}>
            Your Version {version} , Lastest Version {dbVersion}
          </Text>
          <Button
            onClick={() => {
              window.location.reload()
            }}
          >
            Click To Refresh
          </Button>
        </VStack>
      </Center>
    )
  }

  if (isLoading) {
    return (
      <Center h='100vh' width='full' bg='brand'>
        <VStack spacing={5}>
          <Image height='80px' src='/Logo.webp' />
          <Heading color='white'>Loading ...</Heading>
          <div className='custom-loader-white' />
        </VStack>
      </Center>
    )
  }

  return (
    <Routes>
      Schedule
      <Route
        path='/schedule'
        element={
          <PrivateRoute
            user={user}
            allowed={['Manager', 'Owner', 'Staff', 'CoAdmin']}
          >
            <Schedule user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments/refund/create'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <RefundForm user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments/clear/create'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <ClearForm type='clear' user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments/receipt/create'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <PaymentForm type='receipt' user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments/bill/create'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <PaymentForm type='bill' user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/payments'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <Payment user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/course/edit/:id'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <CourseForm user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/course/create'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <CourseForm user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/course'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <CourseSetting user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/notification'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <NotificationSetting user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/staff/edit/:id'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <StaffForm user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/staff/create'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <StaffForm user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/staff'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <StaffSetting user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/manager'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <AdminSetting user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings/branch'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <BracnhSetting user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/settings'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <Settings user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/eventlogs'
        element={
          <PrivateRoute user={user} allowed={['Owner']}>
            <EventLogs user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/kids/:id/:program'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner', 'CoAdmin']}>
            <KidCourse
              user={user}
              role={user?.role}
              managerBranchId={user?.role === 'Manager' ? user?.branchId : null}
            />
          </PrivateRoute>
        }
      />
      <Route
        path='/kids/:id'
        element={
          <PrivateRoute
            user={user}
            allowed={['Manager', 'Owner', 'Staff', 'CoAdmin']}
          >
            <KidInfo user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/kids/create'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner', 'CoAdmin']}>
            <KidForm
              user={user}
              role={user?.role}
              managerBranchId={
                user?.role === 'Manager' || user?.role === 'CoAdmin'
                  ? user?.branchId
                  : null
              }
            />
          </PrivateRoute>
        }
      />
      <Route
        path='/kids'
        element={
          <PrivateRoute user={user}>
            <Kids user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/report'
        element={
          <PrivateRoute user={user} allowed={['Manager', 'Owner']}>
            <Report user={user} role={user?.role} />
          </PrivateRoute>
        }
      />
      <Route
        path='/login'
        element={
          <PublicRoute bg={'brand'} user={user}>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path='/reset-password'
        element={
          <PublicRoute bg={'brand'} user={user}>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route path='*' element={<Navigate to='/kids' replace />} />
    </Routes>
  )
}

export default App
