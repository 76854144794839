const branchForm = [
  {
    label: 'Type',
    name: 'accountType',
    type: 'radio',
    placeholder: '',
    defaultValue: '',
    options: ['Head Quarter', 'Branch'],
    required: true,
  },
  {
    label: 'Province',
    name: 'province',
    type: 'dropdown',
    defaultValue: 'bk',
    options: [
      { label: 'BK (กรุงเทพ)', value: 'bk' },
      { label: 'CM (เชียงใหม่)', value: 'cm' },
    ],
    required: true,
  },
  {
    label: 'Branch Code',
    name: 'code',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Branch Name',
    name: 'name',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Company Name',
    name: 'companyName',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Member Price',
    name: 'memberPrice',
    type: 'number-format',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Address',
    name: 'address',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: 'Tax ID',
    name: 'taxId',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    type: 'phone',
    placeholder: '',
    defaultValue: '',
  },
  {
    label: 'Fax Number',
    name: 'faxNumber',
    type: 'text',
    placeholder: '',
    defaultValue: '',
  },
]

const bankForm = (branches = []) => [
  {
    label: 'สาขา',
    name: 'branchId',
    type: 'dropdown',
    options: branches,
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'ชื่อบัญชี',
    name: 'accountName',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'เลขที่บัญชี',
    name: 'bankAccountNo',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: false,
  },
  {
    label: 'ธนาคาร',
    name: 'bankName',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: false,
  },

  {
    label: 'สาขาธนาคาร',
    name: 'bankBranch',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: false,
  },

  {
    label: 'ประเภทบัญชี',
    name: 'accountType',
    type: 'radio',
    placeholder: '',
    defaultValue: '',
    options: ['ออมทรัพย์', 'กระแสรายวัน', 'ฝากประจำ'],
    required: true,
  },
  {
    label: 'เอกสาร',
    name: 'paymentDocumentType',
    type: 'radio',
    placeholder: '',
    defaultValue: 'all',
    options: [
      { label: 'ทั้งหมด', value: 'all' },
      { label: 'เฉพาะใบเสร็จ', value: 'receipt' },
      { label: 'เฉพาะบิลเงินสด', value: 'bill' },
    ],
    required: true,
  },
]

const cashForm = (branches = []) => [
  {
    label: 'สาขา',
    name: 'branchId',
    type: 'dropdown',
    options: branches,
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'ผู้ให้บริการ/ชื่อสาขา',
    name: 'name',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'รายละเอียด',
    name: 'description',
    type: 'textarea',
    placeholder: '',
    defaultValue: '',
    required: false,
  },
  {
    label: 'เอกสาร',
    name: 'paymentDocumentType',
    type: 'radio',
    placeholder: '',
    defaultValue: 'all',
    options: [
      { label: 'ทั้งหมด', value: 'all' },
      { label: 'เฉพาะใบเสร็จ', value: 'receipt' },
      { label: 'เฉพาะบิลเงินสด', value: 'bill' },
    ],
    required: true,
  },
]

export { branchForm, bankForm, cashForm }
