const adminForm = [
  {
    label: 'Full Name',
    name: 'fullname',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Nick Name',
    name: 'nickname',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Telephone Number',
    name: 'tel',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
    hideOnEdit: true,
  },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    placeholder: '',
    defaultValue: '',
    required: true,
    hideOnEdit: true,
  },
]

export { adminForm }
