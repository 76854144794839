/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react'
import {
  Box,
  SimpleGrid,
  Flex,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Select,
  HStack,
  Avatar,
  VStack,
  IconButton,
  Heading,
} from '@chakra-ui/react'
import { Link, useSearchParams } from 'react-router-dom'
import { search } from 'ss-search'
import moment from 'moment/moment'
import Table from 'components/Horizons/table'
import _ from 'underscore'
import useKid from 'hooks/useKid'
import useBranch from 'hooks/useBranch'
import { Popconfirm } from 'antd'
import { BiBox } from 'react-icons/bi'
import { TiArrowBackOutline } from 'react-icons/ti'
import { useDocument } from '@lemasc/swr-firestore'

function checkKidExpire(week, expireDate) {
  const toDay = new Date()
  var getDate

  switch (week) {
    case '1 Week':
      getDate = new Date(moment().add(7, 'days'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }
    case '2 Week':
      getDate = new Date(moment().add(14, 'days'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }
    case '3 Week':
      getDate = new Date(moment().add(21, 'days'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }
    case '4 Week':
      getDate = new Date(moment().add(28, 'days'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }
    case '1 Month':
      getDate = new Date(moment().add(1, 'month'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }
    case '2 Month':
      getDate = new Date(moment().add(2, 'month'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }
    case '3 Month':
      getDate = new Date(moment().add(3, 'month'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }
    case '4 Month':
      getDate = new Date(moment().add(4, 'month'))
      if (expireDate >= toDay && expireDate <= getDate) {
        return true
      } else {
        return false
      }

    default:
      return false
  }
}

function kidStatus(status, expireDate, notification) {
  if (
    status === 'member' &&
    expireDate &&
    moment(expireDate).diff(moment(), 'days') <= 0
  ) {
    return (
      <Box fontWeight='semibold' color='red'>
        EXPIRED
      </Box>
    )
  } else if (
    status === 'member' &&
    expireDate &&
    checkKidExpire(notification?.action, expireDate?.toDate())
  ) {
    return (
      <Box fontWeight='semibold' color='red'>
        <VStack w='full' alignItems={'start'}>
          <Text>Nearly Expired</Text>
          <Text>
            ({moment(expireDate?.toDate()).diff(moment(), 'days')}
            &nbsp;Days Left)
          </Text>
        </VStack>
      </Box>
    )
  } else if (status === 'member') {
    return (
      <Box fontWeight='semibold' color='brand'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'evaluation') {
    return (
      <Box fontWeight='semibold' color='#FFC211'>
        {status.toUpperCase()}
      </Box>
    )
  } else if (status === 'off') {
    return (
      <Box fontWeight='semibold' color='#767262'>
        {status.toUpperCase()}
      </Box>
    )
  }
}

export default function Kids({ user, role }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [kidQuery, setKidQuery] = useState(null)
  const [allKid, setAllKid] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isArchiveList, setisArchiveList] = useState(false)
  const { branches } = useBranch()
  const { data: notificationData } = useDocument(
    'Settings/Notification',

    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: 0,
    }
  )

  const managerBranchId = useMemo(
    () =>
      role === 'Manager' || role === 'Staff' || role === 'CoAdmin'
        ? user?.branchId
        : null,
    [role]
  )

  const { getAllKids, updateKid } = useKid(null, managerBranchId)
  const [searchQuery, setSearch] = useState({
    text: '',
    branch: managerBranchId || branches[0]?.id || '',
    status: '',
  })

  useEffect(() => {
    window.scrollTo({ top: 0 })
    if (
      role &&
      (role === 'Manager' || role === 'Staff' || role === 'CoAdmin') &&
      branches.length > 0
    ) {
      fetchKid(user?.branchId)
      setSearch({
        ...searchQuery,
        branch: managerBranchId || branches[0]?.id,
        status: searchParams.get('status') || '',
      })
    } else if (role && role === 'Owner' && branches.length > 0) {
      fetchKid(searchParams.get('branch') || branches[0]?.id)
      setSearch({
        ...searchQuery,
        branch:
          searchParams.get('branch') || managerBranchId || branches[0]?.id,
        status: searchParams.get('status') || '',
      })
    }
  }, [role, branches, searchParams.get('branch'), searchParams.get('status')])

  useEffect(() => {
    if (searchParams.get('branch')) {
      let searchQueryArr
      if (searchParams.get('status') === 'nearlyexpired') {
        searchQueryArr = _.filter(allKid, (kid) => {
          if (
            kid?.memberDetails?.status === 'member' &&
            kid?.memberDetails?.expireDate &&
            checkKidExpire(
              notificationData.action,
              kid?.memberDetails?.expireDate?.toDate()
            )
          ) {
            return kid
          }
        })
        setSearch({
          ...searchQuery,
          status: searchParams.get('status') || '',
        })
        setKidQuery(searchQueryArr)
      }
    }
  }, [searchParams.get('branch'), searchParams.get('status'), allKid])

  function clearParams() {
    searchParams.delete('branch')
    searchParams.delete('status')
    setSearchParams(searchParams)
  }

  const fetchKid = async (bId) => {
    const kids = await getAllKids(bId)
    setAllKid(kids)
    setKidQuery(kids)
    setLoading(false)
  }

  const handleArchive = async (kid, branchId) => {
    setLoading(true)
    try {
      const { isArchive, id } = kid
      await updateKid(id, { isArchive: !isArchive })

      let textSearch = searchQuery.text
      let searchQueryArr

      if (searchQuery.status === 'nearlyexpired') {
        const res = await getAllKids(searchQuery.branch)

        searchQueryArr = _.filter(res, (kid) => {
          if (
            kid?.memberDetails?.status === 'member' &&
            kid?.memberDetails?.expireDate &&
            checkKidExpire(
              notificationData?.action,
              kid?.memberDetails?.expireDate?.toDate()
            )
          ) {
            return kid
          }
        })
      } else {
        const res = await getAllKids(searchQuery.branch)
        searchQueryArr = res.filter((kid) =>
          searchQuery.status !== '' ? kid.status === searchQuery.status : kid
        )
      }

      if (searchQueryArr.length > 0) {
        const searchKeys = ['nickname', 'code']

        const results = search(searchQueryArr, searchKeys, textSearch)

        const searchResults = textSearch === '' ? searchQueryArr : results

        if (managerBranchId) {
          setKidQuery(
            searchResults.filter((kid) => kid.branchId === managerBranchId)
          )
        } else {
          setKidQuery(searchResults)
        }
      } else {
        setKidQuery([])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleSearch(e) {
    e.preventDefault()
    setLoading(true)
    clearParams()
    try {
      let textSearch = searchQuery.text
      let searchQueryArr

      if (searchQuery.branch !== allKid[0]?.branchId) {
        if (searchQuery.status === 'nearlyexpired') {
          const res = await getAllKids(searchQuery.branch)
          // searchQueryArr = res.filter((kid) =>
          //   searchQuery.status !== '' ? kid.status === searchQuery.status : kid
          // )
          searchQueryArr = _.filter(res, (kid) => {
            if (
              kid?.memberDetails?.status === 'member' &&
              kid?.memberDetails?.expireDate &&
              checkKidExpire(
                notificationData?.action,
                kid?.memberDetails?.expireDate?.toDate()
              )
            ) {
              return kid
            }
          })
        } else {
          const res = await getAllKids(searchQuery.branch)
          searchQueryArr = res.filter((kid) =>
            searchQuery.status !== '' ? kid.status === searchQuery.status : kid
          )
        }
      } else {
        if (searchQuery.status === 'nearlyexpired') {
          searchQueryArr = _.filter(allKid, (kid) => {
            if (
              kid?.memberDetails?.status === 'member' &&
              kid?.memberDetails?.expireDate &&
              checkKidExpire(
                notificationData?.action,
                kid?.memberDetails?.expireDate?.toDate()
              )
            ) {
              return kid
            }
          })
        } else {
          searchQueryArr = allKid.filter((kid) => {
            return searchQuery.status !== ''
              ? kid.status === searchQuery.status
              : kid
          })
        }
      }

      if (searchQueryArr.length > 0) {
        const searchKeys = ['nickname', 'code']

        const results = search(searchQueryArr, searchKeys, textSearch)

        const searchResults = textSearch === '' ? searchQueryArr : results

        if (managerBranchId) {
          setKidQuery(
            searchResults.filter((kid) => kid.branchId === managerBranchId)
          )
        } else {
          setKidQuery(searchResults)
        }
      } else {
        setKidQuery([])
      }
    } finally {
      setLoading(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Kid Name',
        accessor: 'nickname',
        key: 'nickname',
        width: '20%',
        extra: (data, row) => (
          <Flex
            align='center'
            as={Link}
            to={`/kids/${row.id}`}
            state={{
              kidData: {
                ...row,
                birthday:
                  row.birthday && row.birthday !== ''
                    ? moment(row.birthday.toDate()).format('YYYY-MM-DD')
                    : undefined,
                telephone: row?.telephone
                  ? row?.telephone.replace('-', '')
                  : '',
                parentTel1: row?.parentTel1
                  ? row?.parentTel1.replace('-', '')
                  : '',
                parentTel2: row?.parentTel2
                  ? row?.parentTel2.replace('-', '')
                  : '',
                parentTelExtra: row?.parentTelExtra
                  ? row?.parentTelExtra.replace('-', '')
                  : '',
              },
            }}
          >
            <Avatar
              src={row?.picture}
              boxSize='60px'
              me='20px'
              name={row.nickname}
              borderRadius='full'
              objectFit={'cover'}
            />
            <Text fontSize='md' fontWeight='500'>
              {data}
            </Text>
          </Flex>
        ),
      },

      {
        Header: 'Full Name',
        accessor: 'name',
        key: 'name',
        width: '20%',
        extra: (data) => data || '-',
      },
      {
        Header: 'Age',
        accessor: 'birthday',
        key: 'birthday',
        width: '10%',
        extra: (data) => {
          if (!data || data === '-') {
            return '-'
          }
          const birthday = moment(data.toDate())
          const a = moment()
          const b = moment(birthday, 'YYYY-MM-DD')
          const diffAge = moment.duration(a.diff(b))
          const years = diffAge.years()
          const months = diffAge.months()
          if (!isNaN(years) && !isNaN(months)) {
            return `${years.toFixed()} ปี ${months} เดือน`
          } else {
            return '-'
          }
        },
      },
      {
        Header: 'Telephone Number',
        accessor: 'telephone',
        key: 'telephone',
        width: '10%',
        extra: (data) => data || '-',
      },
      {
        Header: ' Branch',
        accessor: 'branchId',
        key: 'branchId',
        width: '15%',
        extra: (branchId) => {
          const kidBranch = branches.find((branch) => branch.id === branchId)
          return kidBranch?.name || '-'
        },
      },
      {
        Header: 'Code',
        accessor: 'code',
        key: 'code',
        width: '15%',
        extra: (data, row) => <Text>{data ? data : '-'}</Text>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        key: 'id',
        width: '30%',
        extra: (data, row) => (
          <HStack>
            <Box>
              {kidStatus(
                data,
                row?.memberDetails?.expireDate,
                notificationData
              )}
            </Box>
            {data === 'evaluation' && (
              <Popconfirm
                title={`Sure to ${
                  !isArchiveList ? 'archive' : 'return'
                } this Kid?`}
                onConfirm={() => {
                  handleArchive(row, searchQuery.branch)
                }}
              >
                <IconButton
                  aria-label='archive'
                  variant='solid'
                  icon={
                    !isArchiveList ? (
                      <BiBox color='orange' />
                    ) : (
                      <TiArrowBackOutline color='orange' />
                    )
                  }
                />
              </Popconfirm>
            )}
          </HStack>
        ),
      },
    ],
    [notificationData, searchQuery.branch, isArchiveList]
  )

  const totalKid = useMemo(() => {
    return kidQuery?.length || 0
  }, [kidQuery])

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <Text color='brand'>Search</Text>
      <form onSubmit={handleSearch}>
        <Box
          borderWidth='1px'
          borderColor='brand'
          borderRadius='10px'
          mt={1}
          mb={10}
          p={5}
        >
          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing={{ base: 0, md: 10 }}
          >
            <FormControl>
              <FormLabel>Search</FormLabel>
              <Input
                id='search'
                type='search'
                onChange={(e) => {
                  setSearch({ ...searchQuery, text: e.target.value })
                  clearParams()
                }}
                value={searchQuery.text}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                id='status'
                placeholder='Status'
                onChange={(e) => {
                  setSearch({ ...searchQuery, status: e.target.value })
                  clearParams()
                }}
                value={searchQuery.status}
              >
                <option value='member'>MEMBER</option>
                <option value='evaluation'>EVALUATION</option>
                <option value='nearlyexpired'>NEARLY EXPIRED</option>
                <option value='off'>OFF</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Branch</FormLabel>
              <Select
                id='branch'
                onChange={(e) => {
                  setSearch({ ...searchQuery, branch: e.target.value })
                  clearParams()
                }}
                value={searchQuery.branch}
                isDisabled={managerBranchId}
              >
                {branches
                  .sort(
                    (a, b) =>
                      b.accountType.localeCompare(a.accountType) ||
                      new Date(b.createdDate) - new Date(a.createdDate)
                  )
                  .map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </SimpleGrid>
          <Flex
            justifyContent={{ base: 'center', lg: 'flex-end' }}
            w='full'
            mt={4}
          >
            <HStack mt={3}>
              <Button
                variant='outline'
                onClick={() => {
                  setSearch({ text: '', status: '' })
                  setKidQuery(null)
                  clearParams()
                }}
              >
                Clear
              </Button>
              <Button variant='solid' colorScheme='twitter' type='submit'>
                Search
              </Button>
            </HStack>
          </Flex>
        </Box>
      </form>

      <Flex justifyContent='space-between' w='full' mb={'15px'}>
        <Heading as='h1' size='lg'>
          {`Total ${totalKid.toLocaleString()} kids`}
        </Heading>
        <Box>
          <Button
            variant='solid'
            colorScheme='orange'
            bg='orange'
            mr={5}
            onClick={() => {
              setisArchiveList(!isArchiveList)
            }}
          >
            {!isArchiveList ? 'Show Archive List' : 'Hide Archive List'}
          </Button>
          {role !== 'Staff' && (
            <Link to='/kids/create'>
              <Button variant='solid' colorScheme='lime' bg='brand'>
                Create Kid
              </Button>
            </Link>
          )}
        </Box>
      </Flex>
      {!isArchiveList ? (
        <Table
          columnsData={columns}
          tableData={
            kidQuery?.filter(({ isArchive }) => !isArchive) ||
            allKid.filter(({ isArchive }) => !isArchive)
          }
          loading={isLoading}
          noSearch
        />
      ) : (
        <Table
          columnsData={columns}
          tableData={
            kidQuery?.filter(({ isArchive }) => isArchive) ||
            allKid.filter(({ isArchive }) => isArchive)
          }
          loading={isLoading}
          noSearch
        />
      )}
    </Box>
  )
}
