/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { db, functions } from 'config/firebase'
import { httpsCallable } from 'firebase/functions'
import {
  query,
  collection,
  getDocs,
  where,
  getDoc,
  doc,
  orderBy,
  getCountFromServer,
} from 'firebase/firestore'
import moment from 'moment'
import useCourse from 'hooks/useCourse'

export default function useReport(month, branchId, type) {
  const getActiveCaseReport = httpsCallable(functions, 'getActiveCaseReport')
  const [report, setReport] = useState([])
  const [reportCamp, setReportCamp] = useState([])
  const [reportEvaluation, setReportEvaluation] = useState([])
  const [reportRevenue, setReportRevenue] = useState([])
  const [coursesRevenue, setCoursesRevenue] = useState([])
  const [reportUsage, setReportUsage] = useState([])
  const { courses } = useCourse()

  const [isLoading, setLoading] = useState(true)
  const [isLoadingCamp, setLoadingCamp] = useState(true)

  useEffect(() => {
    let fetch = true
    if (fetch && branchId && branchId !== '' && courses && courses.length > 0) {
      fetch = false
      if (type === 'performance') {
        getPerformance()
      } else if (type === 'camp') {
        getPerformanceCamp()
      } else if (type === 'evaluation') {
        getEvaluationReport()
      } else if (type === 'revenue') {
        getRevenuesReport()
      } else if (type === 'revCourses') {
        getRevenuesCourseReport()
      } else if (type === 'usage') {
        getUsage()
      }
    }

    return () => {
      fetch = false
    }
  }, [month, branchId, courses, type])

  const reduceStaff = (data) => {
    return data
      .reduce((items, item) => {
        const {
          staff,
          programId,
          program,
          isTaked,
          date,
          kid,
          timeStart,
          timeEnd,
        } = item
        const itemIndex = items.findIndex((s) => {
          return s?.staffId === staff?.id
        })
        if (itemIndex === -1) {
          items.push({
            staff,
            staffId: staff?.id,
            programs: [
              {
                id: programId,
                ...program,
                isTaked,
                date,
                kid,
                timeStart,
                timeEnd,
              },
            ],
          })
        } else {
          items[itemIndex].programs.push({
            id: programId,
            ...program,
            isTaked,
            date,
            kid,
            timeStart,
            timeEnd,
          })
        }

        return items
      }, [])
      .map((item) => {
        return { ...item, reportByDate: reduceDate(item.programs) }
      })
  }

  const reduceDate = (programs) => {
    return programs
      .reduce((acc, item) => {
        const { date } = item
        const itemIndex = acc.findIndex((a) => {
          return a.date === item.date
        })
        if (itemIndex === -1) {
          acc.push({
            date,
            programs: [item],
          })
        } else {
          acc[itemIndex].programs.push(item)
        }
        return acc
      }, [])
      .map((item) => {
        return {
          ...item,
          ...calculateCamp(item.programs, courses),
          ...calculateReport(item.programs, courses),
        }
      })
  }

  const calculateReport = (data, list) => {
    const sum = data
      .filter((program) => program.id)
      .reduce(
        (total, program) => {
          const programdata = list.find((course) => {
            return course.id === program.courseId
          })
          const exp =
            total.exp + Number(programdata?.score ? programdata?.score : 0)

          const evaluationScore =
            program.type === 'Evaluation' && program.isTaked
              ? total.evaluationScore + Number(programdata?.score || 0)
              : total.evaluationScore
          const evaluation =
            program.type === 'Evaluation' && program.isTaked
              ? total.evaluation + 1
              : total.evaluation

          const actNotEva =
            program.type !== 'Evaluation' && program.isTaked
              ? total.actNotEva + 1
              : total.actNotEva

          return { exp, evaluationScore, evaluation, actNotEva }
        },
        { evaluation: 0, evaluationScore: 0, exp: 0, actNotEva: 0 }
      )

    return {
      ...sum,
      act: sum.actNotEva + (sum.evaluationScore - sum.evaluation),
      percent: ((sum.actNotEva + sum.evaluationScore) / sum.exp) * 100,
      total: sum.actNotEva + sum.evaluationScore,
    }
  }

  const calculateCamp = (data, list) => {
    const sum = data
      .filter((program) => program.id)
      .reduce(
        (total, program) => {
          const programdata = list.find((course) => {
            return course.id === program.courseId
          })

          const camp =
            program.type === 'Camp' && program.isTaked
              ? total.camp +
                Number(programdata?.score || 0) *
                  Number(programdata?.hours || 0)
              : total.camp

          return { camp }
        },
        { camp: 0 }
      )

    return {
      ...sum,
      act: sum.actNotEva + (sum.evaluationScore - sum.evaluation),
      percent: ((sum.actNotEva + sum.evaluationScore) / sum.exp) * 100,
      total: sum.actNotEva + sum.evaluationScore,
    }
  }

  const getPerformance = async () => {
    setLoading(true)
    const queryReport = query(
      collection(db, 'Schedules'),
      where('staff.branchId', '==', branchId),
      where('date', '>=', month),
      where('date', '<=', month + '~')
    )
    const reportSnap = await getDocs(queryReport)
    const data = reportSnap.docs.map((doc) => {
      return { ...doc.data(), id: doc.id }
    })

    const staffsPerformace = await reduceStaff(
      data.filter((staff) => staff?.program?.type !== 'Camp')
    )

    const staffWithCalculated = await staffsPerformace.map((staff) => {
      return {
        ...staff,
        report: calculateReport(staff?.programs, courses),
      }
    })

    if (branchId) {
      setReport(
        staffWithCalculated.filter((r) => {
          return r.staff?.branchId === branchId
        })
      )
    } else {
      setReport(staffWithCalculated)
    }
    setLoading(false)
  }

  const getPerformanceCamp = async () => {
    setLoadingCamp(true)
    const queryReport = query(
      collection(db, 'Schedules'),
      where('date', '>=', month),
      where('date', '<=', month + '~')
    )
    const reportSnap = await getDocs(queryReport)
    let data = reportSnap.docs
      .map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      .filter((data) => {
        const dateArray = data.date.split('-')
        const thisMonth = `${dateArray[0]}-${dateArray[1]}`
        return thisMonth === month
      })
    const staffsCamp = reduceStaff(
      data.filter((staff) => staff?.program?.type === 'Camp')
    )

    const staffCampCalculated = staffsCamp.map((staff) => {
      return {
        ...staff,
        report: calculateCamp(staff?.programs, courses),
      }
    })

    if (branchId) {
      setReportCamp(
        staffCampCalculated.filter((r) => {
          return r.staff?.branchId === branchId
        })
      )
    } else {
      setReportCamp(staffCampCalculated)
    }
    setLoadingCamp(false)
  }

  const getEvaluationReport = async () => {
    setLoading(true)
    const queryReport = query(
      collection(db, 'Schedules'),
      where('program.type', '==', 'Evaluation'),
      where('isTaked', '==', true),
      where('staff.branchId', '==', branchId),
      where('date', '>=', month),
      where('date', '<=', month + '~'),
      orderBy('date', 'desc')
    )
    const reportSnap = await getDocs(queryReport)
    let data = await Promise.all(
      reportSnap.docs.map(async (document) => {
        const kidSnap = await getDoc(doc(db, 'Kids', document.data().kidId))

        const enrollSnap = await (
          await getDocs(
            query(
              collection(db, `Kids/${document.data().kidId}/Programs`),
              where('type', '!=', 'Evaluation')
            )
          )
        ).docs.map((snap) => ({
          id: snap.id,
          ...snap.data(),
        }))

        const statusSnap = await (
          await getDocs(collection(db, `Kids/${document.data().kidId}/Status`))
        ).docs
          .map((snap) => ({
            id: snap.id,
            ...snap.data(),
          }))
          .filter((status) => status.status === 'member')
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

        return {
          ...document.data(),
          id: document.id,
          kid: kidSnap.data(),
          status: statusSnap,
          enroll: enrollSnap,
        }
      })
    )
    setReportEvaluation(data)
    setLoading(false)
  }

  const getRevenuesReport = async () => {
    setLoading(true)

    let queryReport

    if (typeof month === 'object') {
      queryReport = query(
        collection(db, 'Payments'),
        where('branch.id', '==', branchId),
        where('status', 'in', ['remaining', 'paid', 'clear', 'refund']),
        where('createdAt', '>', month[0].startOf('day').toDate()),
        where('createdAt', '<', month[1].endOf('day').toDate())
      )
    } else {
      queryReport = query(
        collection(db, 'Payments'),
        where('branch.id', '==', branchId),
        where('status', 'in', ['remaining', 'paid', 'clear', 'refund']),
        where('createdAt', '>', moment(month).startOf('day').toDate()),
        where('createdAt', '<', moment(month).endOf('day').toDate())
      )
    }

    const queryPaymentype = query(
      collection(db, 'PaymentTypes'),
      where('branchId', '==', branchId)
    )

    const reportSnap = await getDocs(queryReport)
    const paymentypeSnap = await (
      await getDocs(queryPaymentype)
    ).docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    let data = await Promise.all(
      reportSnap.docs.map(async (document) => {
        const [kidSnap, statusSnap, programsSnap] = await Promise.all([
          await getDoc(doc(db, 'Kids', document.data().kidId)),
          await getDocs(
            query(
              collection(db, `Kids/${document.data().kidId}/Status`),
              orderBy('createdAt', 'desc')
            )
          ),
          await getDocs(
            query(
              collection(db, `Kids/${document.data().kidId}/Programs`),
              orderBy('createdAt', 'desc')
            )
          ),
        ])

        const programData = [
          ...statusSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
          ...programsSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
        ]

        const completed = await Promise.all(
          document.data().programs.map(async (p) => {
            const completedQuery = query(
              collection(db, 'Schedules'),
              where('programId', '==', p?.id),
              where('isTaked', '==', true)
            )
            const count = await getDocs(completedQuery)
            const program = programData.find((program) => {
              return program?.id === p?.id
            })
            const totalPaid = program?.payment?.transactions?.reduce(
              (acc, item) => {
                return item.action === 'paid' ? acc + Number(item.paid) : acc
              },
              0
            )
            return {
              ...p,
              id: p.id,
              completed: count.size,
              amount: program?.amount || 1,
              totalPaid,
              courseId: program?.courseId,
              isWaive: program?.isWaive || false,
              choiceId: program?.choiceId || null,
            }
          })
        )
        const paymentType = paymentypeSnap.find(
          (p) => p.id === document.data().paymentId
        )

        return {
          ...document.data(),
          id: document.id,
          kid: kidSnap.data(),
          paymentType: paymentType,
          completed: completed,
          programs: document.data()?.programs.map((p) => {
            const program = programData?.find((program) => {
              return program?.id === p?.id
            })
            return {
              ...p,
              docId: document.id,
              kidId: document.data().kidId,
              createdBy: document.data().createdBy,
              choiceId: program?.choiceId || null,
              transactions: program?.payment?.transactions ?? [],
            }
          }),
        }
      })
    )

    const userCreated = data.filter(({ createdBy }) => createdBy !== 'System')

    const systemCreated = data
      .filter(({ createdBy }) => createdBy === 'System')
      .flatMap((d) => d.programs)

    const reduceSystemCreated = systemCreated
      .sort((a, b) => (a?.ref ? -1 : 0))
      .reduce((acc, item) => {
        const { id } = item
        const sameId = userCreated
          .flatMap((d) => d.programs)
          .find((u) => u.id === id)
        if (sameId) {
          return acc
        }
        return acc.concat(item)
      }, [])
      .map((program) => data.find((d) => d.id === program.docId))

    const report = userCreated
      .concat(reduceSystemCreated)
      .sort(
        (a, b) =>
          new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
      )

    const reportWithRef = await Promise.all(
      report.map(async (data) => {
        const programs = await Promise.all(
          data?.programs?.map(async (d) => {
            const docRefData = d?.docsRef
              ? d?.docsRef?.map((r) => {
                  const refData = d.transactions.find(
                    (t) => t?.paymentNo === r?.paymentId
                  )

                  return {
                    trRef: refData?.trRef,
                    paid: refData?.paid,
                    paymentDate: refData?.paymentDate,
                    ref: r?.paymentRef,
                    status: refData?.tag,
                  }
                })
              : []
            const isPaidAfterRemaining = docRefData[docRefData?.length - 1]

            const totalPaidBefore = docRefData.reduce(
              (total, refData) =>
                refData?.status === 'paid' ? total + refData?.paid || 0 : total,
              0
            )

            if (isPaidAfterRemaining && isPaidAfterRemaining?.ref) {
              return {
                ...d,
                tag: d.tag,
                docsRef: docRefData,
                isPaidAfterRemaining: {
                  ref: isPaidAfterRemaining?.ref,
                  paymentDate: isPaidAfterRemaining?.paymentDate,
                },
                totalPaidBefore,
              }
            } else if (
              isPaidAfterRemaining &&
              isPaidAfterRemaining?.ref === null
            ) {
              return {
                ...d,
                tag: d.tag,
                docsRef: docRefData,
                isPaidAfterSystemCreatedPayment: {
                  ref: null,
                  paymentDate: isPaidAfterRemaining?.paymentDate,
                },
                totalPaidBefore,
              }
            } else {
              return {
                ...d,
                docsRef: docRefData,
                tag: d.tag,
                totalPaidBefore,
              }
            }
          })
        )

        return { ...data, programs }
      })
    )

    setReportRevenue(reportWithRef)
    setLoading(false)
  }

  const getRevenuesCourseReport = async () => {
    setLoading(true)

    let queryReport

    if (typeof month === 'object') {
      queryReport = query(
        collection(db, 'Payments'),
        where('branch.id', '==', branchId),
        where('status', '==', 'paid'),
        where('createdAt', '>', month[0].startOf('day').toDate()),
        where('createdAt', '<', month[1].endOf('day').toDate())
      )
    } else {
      queryReport = query(
        collection(db, 'Payments'),
        where('branch.id', '==', branchId),
        where('status', '==', 'paid'),
        where('createdAt', '>', moment(month).startOf('day').toDate()),
        where('createdAt', '<', moment(month).endOf('day').toDate())
      )
    }
    const queryPaymentype = query(
      collection(db, 'PaymentTypes'),
      where('branchId', '==', branchId)
    )
    const paymentypeSnap = await (
      await getDocs(queryPaymentype)
    ).docs.map((doc) => ({ id: doc.id, ...doc.data() }))

    const reportSnap = await getDocs(queryReport)
    let data = await Promise.all(
      reportSnap.docs.map(async (document) => {
        const kidSnap = await getDoc(doc(db, 'Kids', document.data().kidId))
        const programsData = []
        const paymentType = paymentypeSnap.find(
          (p) => p.id === document.data().paymentId
        )

        const statusSnap = await getDocs(
          query(
            collection(db, `Kids/${document.data().kidId}/Status`),
            orderBy('createdAt', 'desc')
          )
        )
        const programsSnap = await getDocs(
          query(
            collection(db, `Kids/${document.data().kidId}/Programs`),
            orderBy('createdAt', 'desc')
          )
        )

        statusSnap.forEach((doc) => {
          programsData.push({
            id: doc.id,
            ...doc.data(),
            collection: 'Status',
            paymentType,
          })
        })

        programsSnap.forEach((doc) => {
          programsData.push({
            id: doc.id,
            ...doc.data(),
            collection: 'Programs',
            paymentType,
          })
        })
        const completed = await Promise.all(
          programsSnap.docs.map(async (p) => {
            const completedQuery = query(
              collection(db, 'Schedules'),
              where('programId', '==', p.id),
              where('isTaked', '==', true)
            )
            const count = await getCountFromServer(completedQuery)

            return {
              id: p.id,
              ...p.data(),
              completed: count.data().count,
            }
          })
        )

        return {
          ...document.data(),
          id: document.id,
          kid: kidSnap.data(),
          programsData: programsData,
          completed: completed,
        }
      })
    )

    if (branchId && branchId !== 'all') {
      data = data.filter((data) => data.kid?.branchId === branchId)
    }

    const reduceCourseData = reduceCourse(data)
    setCoursesRevenue(
      reduceCourseData.sort((a, b) => a.courseName.localeCompare(b.courseName))
    )

    setLoading(false)
  }

  const reduceCourse = (data) => {
    const flatProgramData = data.flatMap((p) => p.programsData)
    const flatProgram = data.flatMap((p) => p.programs)
    return flatProgram
      .reduce((acc, item) => {
        const { id } = item
        const programdata =
          flatProgramData.find((d) => d?.id === id) ||
          flatProgram.find((d) => d?.id === id)
        const itemIndex = acc.findIndex((a) => {
          return (
            a.courseId === programdata?.courseId &&
            a.choice === programdata?.choice
          )
        })

        if (itemIndex === -1) {
          acc.push({
            ...programdata,
            courseName: programdata?.courseName || 'Member',
            payment: [
              {
                ...programdata?.payment,
                paymentType: programdata.paymentType,
                coursePrice: programdata?.price,
                payPrice: item?.price,
              },
            ],
          })
        } else {
          acc[itemIndex].payment = [
            ...acc[itemIndex]?.payment,
            {
              ...programdata?.payment,
              paymentType: programdata.paymentType,
              courseName: programdata?.courseName || 'Member',
              coursePrice: programdata?.price,
              payPrice: item?.price,
            },
          ]
        }
        return acc
      }, [])
      .map((item) => {
        return {
          ...item,
        }
      })
  }

  const getUsage = async () => {
    setLoading(true)
    const queryReport = query(
      collection(db, 'Kids'),
      where('branchId', '==', branchId),
      orderBy('createdAt', 'desc')
    )
    const reportSnap = await getDocs(queryReport)

    const kidWithProgram = await Promise.all(
      reportSnap.docs.map(async (doc) => {
        const programSnap = await getDocs(
          query(
            collection(db, `Kids/${doc.id}/Programs`),
            orderBy('createdAt', 'desc')
          )
        )
        if (programSnap.empty) return
        const programUsage = await Promise.all(
          programSnap.docs.map(async (p) => {
            const getIsNotTaked = await getDocs(
              query(
                collection(db, `Schedules`),
                where('programId', '==', p.id),
                where('isTaked', '==', false)
              )
            )
            if (getIsNotTaked.empty) return
            const countIsTaked = getIsNotTaked.docs.filter(
              (doc) => !doc.data().cancelLog
            )
            return {
              courseName: p.data().courseName,
              courseId: p.data().courseId,
              choice: p.data().choice,
              amount: p.data().amount,
              price: p.data().price,
              isTaked: p.data().amount - countIsTaked.length,
            }
          })
        )
        return {
          reducUsageByPrograms: programUsage
            .filter((k) => k)
            .filter((p) => p.isTaked !== p.amount),
          kid: {
            id: doc.id,
            code: doc.data().code,
            status: doc.data().status,
            name: doc.data().name,
            nickname: doc.data().nickname,
          },
        }
      })
    )
    const filterKid = kidWithProgram
      .filter((k) => k)
      .filter((k) => k.reducUsageByPrograms.length > 0)
      .sort(
        (a, b) =>
          b?.reducUsageByPrograms?.length - a?.reducUsageByPrograms?.length
      )
    setReportUsage(filterKid)
    setLoading(false)
  }

  return {
    coursesRevenue,
    reportRevenue,
    reportEvaluation,
    report,
    reportUsage,
    reportCamp,
    isLoading,
    isLoadingCamp,
    setLoading,
    getActiveCaseReport,
  }
}
